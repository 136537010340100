import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translations_de from "./locales/de";
import translations_en from "./locales/en";

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources: {
        de: { translations: translations_de },
        en: { translations: translations_en },
    },
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },
    react: {
      wait: true
    }
});

export default i18n;