import { Action } from "redux";

export interface ClientError {
    error: Error;
    message: string;
}

export interface ErrorAction extends Action, ClientError {
}

export const DISMISS_ERROR = "app/DISMISS_ERROR";

export interface DismissErrorAction extends Action {
    type: typeof DISMISS_ERROR;
}

export function dismissError(): DismissErrorAction {
    return { type: DISMISS_ERROR };
}

export default ErrorAction;