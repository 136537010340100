export default {
    //generic translations
    "welcome": "Willkommen",
    "available": "Verfügbar",
    "overallAvailable": "Gesamt verfügbar",
    "login": "Login",
    "refresh": "Aktualisieren",
    "percent": "Prozent",
    "allDay": "Ganzer Tag",
    "noData": "Keine Daten",
    "currentMeeting": "Aktuelles Meeting",
    "moreDetails": "Mehr Informationen",
    "filterName": "Filter mittels Namen",
    "minimal": "Minimal",
    "average": "Durchschnittlich",
    "maximal": "Maximal",
    "charts": "Charts",
    "granularity": "Granularität",
    "person": "Person",
    "persons": "Personen",
    "utilization": "Auslastung",
    "dateRange": "Zeitspanne",
    "dateRangeStart": "Startdatum",
    "dateRangeEnd": "Enddatum",
    "days": "Tage",
    "weeks": "Wochen",
    "weekStartday": "Erster Tag der Woche",
    "month": "Monat",
    "months": "Monate",
    "shortMonths": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    "date": "Datum",
    "cw": "KW",

    // generic app specific
    "AppName": "Smart Office Web App",
    "rooms": "Räume",
    "areas": "Bereiche",
    "meetings": "Meetings",
    "locations": "Standorte",
    "meetingroom": "Meetingraum",
    "meetingrooms": "Meetingräume",
    "projectSpace": "Projektplatz",
    "projectSpaces": "Projektplätze",
    "flexdesk": "Flexdesk", //TODO:
    "flexdesks": "Flexdesks", //TODO:
    "reservations": "Reservierungen",
    "projector": "Beamer/Display",
    "floors": "Etagen",
    "floormap": "Etagenkarte",
    "roominformation": "Rauminformation",
    "statistics": "Statistiken",
    "zone": "Zone",
    "zoneDetails": "Zonen Details",
    "admin": "Admin",
    "home": "Startseite",

    // loadings
    "loading": "Laden...",
    "loadingX": "Lade {{x}}...",
		"loadingAreas": "Lade Bereiche...",
		"loadingFloormap": "Lade Etagenkarte...",
    "loadingStatistics": "Lade Statistiken für {{item}}...",
    // page specific
    "peopleCount": "Personenanzahl",
    "pCount": "PCount", // is this "people count"
    "tabStatusOverview": "Status & Übersicht",
    "tabRatingFeedback": "Bewertung & Feedback",
    // "does it work": "Bitte entferne mich wieder aus der Root.tsx",
    "headlineCompanyHasOffices": "Deine Firma hat {{count}} Standorte, wähle einen für weitere Details.",
    "headlineAdmin": "Administration",
    "contentAdmin": 'Tab mit "Rooms"\n\nTiles mit "Add Room"\n\nSensor devices können im Raum hinzugefügt werden\nSignage wird im Raum konfiguriert',
    "detailsMeetingOverviewToday": "Meetingübersicht für heute",
    "detailsUpcommingMeetingsToday": "Heute anstehende Meetings",
    "headlineFeedbackHistory": "Gesamter Feedbackverlauf",
    "feedbackNone": "Bisher kein Feedback vorhanden",
    "statisticsBookedDurationMinutes": "Buchungsdauer (minuten)",
    "statisticsTotalDuration": "Gesamtdauer (minuten)",
    "statisticsChartMinMaxPersProUnit": "Minimale und maximale anzahl {{linebreak}}von Personen pro {{unit}}",
    "statisticsChartAvgBookedDuration": "Durchschnittsquote von tatsächlich {{linebreak}}gebuchten Meetings",
    "statisticsChartNumberMeetingsAttendees": "Anzahl an Meetings pro Anzahl an {{linebreak}}Anwesende aufgeteilt auf Raumzonen",
    "statisticsMeetingsOn": "Statistik über Meetings",
    "statisticsNumberBookings": "Anzahl an Buchungen",
    "statisticsBookedDuration": "Buchungsdauer",
    "statisticsTimeUtilization": "Auslastungszeit",
    "statisticsNumberMeeting": "Anzahl an Meetings",
    "statisticsTotalNumberMeeting": "Gesamt Anzahl an Meetings",
    "statisticsAvgNumberPersonsMeeting": "Durchschnittliche Anzahl an Personen pro Meeting",
    "statisticsChartAvgPersonsMeeting": "Durchschnittliche Personen / Meeting",
    "statisticsChartPersonsMeeting": "Personenspektrum / Meeting", // People-Range
    "statisticsChartNumberMeeting": "Anzahl an Meetings",
    "statisticsChartNumberAttendees": "Anzahl an Anwesenden",
    "statisticsChartMotionRange": "Bewegungszeitraum", // motions per day ?
    "statisticsChartMotionRangeDay": "Bewegungszeitraum pro Tag",
    "floormapClickDetails": "Klicke auf einen Raum um die Details zu sehen",
    "roomcommonAvailable": "Dieser Raum ist aktuell verfügbar",
    "roomcommonAvailableUntil": "Dieser Raum ist frei bis",
    "roomcommonInUseBy": "Dieser Raum wird gerade von {{whom}} genutzt",
    "commonUpcomingMeetingsToday": "Heute anstehende Meetings",
    "commonNextMeeting": "Nächstes Meeting",
    "commonNoMoreMeetingsToday": "Heute finden keine Meetings mehr statt",
    "floorStatsMinUtil": "Minuten in Verwendung",
    "floorStatsAboutUtil": "Statistiken über Auslastung",
    "floorStatsAvgUtilAreas": "Durchschnittliche Auslastung der Bereiche",
    "floorStatsAvgAreaUtil": "Durchschnittliche Bereichsauslastung",
    "breadcrumbMaxSize":"Breadcrumb zeigt maximal 4 Einträge an",
    "todayMeetingLineTooltip":"Heutigen Meetings zwischen 6 und 22Uhr.",
    "todayMeetingLineText":"Verfügbarkeiten von 6 bis 22 Uhr",
};
