
import * as microsoftTeams from "@microsoft/teams-js"
import * as msal from "@azure/msal-browser";
import { CONFIG } from "../../config/settings";
import { Moment } from "moment";
import moment from "moment";
import { loginRedirect } from "../components/navigation/actions";

let _token: string;

export const getToken = (): string => _token;
export const setToken = (token: string) => {
	_token = token;
}

const getCurrentRootURI = (): string => {
	const currentUri = window.location.href;
	const reg = /(.*)#(.*)?/gm;
	const matches = reg.exec(currentUri);
	console.log(`redirectUri: ${matches[1]}`)
	console.log(matches)
	return matches[1];
}

const msalConfig: msal.Configuration = {
	auth: {
		clientId: CONFIG.authConfig.clientId,
		authority: `https://login.microsoftonline.com/${CONFIG.authConfig.tenant}`,
		redirectUri: getCurrentRootURI(),
		navigateToLoginRequestUrl: false,

	},
	cache: {
		cacheLocation: "localStorage",
	},
}

let loginHint: string = undefined;

export const setLoginHint = (hint: string): void => {
	msalAccessRequest.loginHint = hint;
	console.log(msalAccessRequest);
}

let msalAccessRequest: msal.SsoSilentRequest = {
	scopes: [`${CONFIG.authConfig.clientId}/.default`],
	//loginHint: loginHint,
	//forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

let authRequest: msal.RedirectRequest & msal.PopupRequest = {
	scopes: [`${CONFIG.authConfig.clientId}/.default`],
	loginHint: loginHint,
	redirectUri: getCurrentRootURI(),
	authority: `https://login.microsoftonline.com/${CONFIG.authConfig.tenant}`,
	//responseMode: ResponseMode.QUERY,
	
	//redirectUri: CONFIG.apiConfig.baseURL + "/#/loginredirect",
	//forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

const createSilentRequest = (acc: msal.AccountInfo): msal.SilentRequest => {
	return {
		...authRequest,
		account: acc,
		forceRefresh: false
	}
}

const msalAuth = new msal.PublicClientApplication(msalConfig);


export const handleRedirect = async (hash?: string): Promise<TokenResponse> => {
	// console.log("Handling Redirect!")
	// console.log(hash)
	const result = await msalAuth.handleRedirectPromise();
	// console.log(result);
	if (result) {
		if (result.tokenType === "idToken") {
			var msaltoknres = await getMsalToken(result.account);
			setToken(msaltoknres.token);
			return msaltoknres 
		}
		else {
			setToken(result.accessToken);
			return {
				expiry: moment(result.expiresOn),
				token: result.accessToken,
				type: MsalResponseType.SUCESSS
			}
		}
	}

	return {
		expiry: undefined,
		token: undefined,
		type: MsalResponseType.ERROR
	}
}

export const signInAsync = async (method: string): Promise<msal.AccountInfo[]> => {
	const signInType = method;
	const accounts = msalAuth.getAllAccounts();

	console.log(`The existing msalAccount belongs to: ${accounts?.[0]?.username}. Found Accounts: ${accounts}`);
	if (accounts?.length > 0) {
		try{
			console.log("Trying SSO-Silent")
			const auth = await msalAuth.ssoSilent(createSilentRequest(accounts[0]))
			console.log("SilentResult:", auth)
			return [auth.account];
		}
		catch(error) {
			if (error.errorCode != "interaction_required" && error.errorCode != "login_required") {
				throw error;
			}
		}
	}

	if (signInType === "loginPopup") {
		const acc: msal.AuthenticationResult = await msalAuth.loginPopup(authRequest);
		return msalAuth.getAllAccounts();
	} else if (signInType === "loginRedirect") {
		console.log("Trigger redirect.");		
		msalAuth.loginRedirect(authRequest);
		return undefined;
	}
}

export const checkTeamsContext = async (): Promise<boolean> => {
	// console.log("checking if teams");
	microsoftTeams.initialize();

	const timeoutSpan = 2000;
	const contextPromise = new Promise<boolean>((resolve, reject) => {
		const timeout = setTimeout(() => {
			resolve(false);
		}, timeoutSpan);

		try {
			microsoftTeams.getContext((context: microsoftTeams.Context) => {
				// console.log("checking if teams. YES: " + context);
				clearTimeout(timeout);
				resolve(true);
			});

		}
		catch (error) {
			// console.log("checking if teams. NO: " + error);
			clearTimeout(timeout)
			resolve(false)
		}
	});

	return contextPromise;
}

export const getAuthTokenFromTeams = async (): Promise<TokenResponse> => {
	const tokenPromise = new Promise<string>((resolve, reject) => {
		const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
			successCallback: (result: string) => {
				resolve(result);
			},
			failureCallback: (reason: string) => {
				reject(reason);
			},
			resources: []
		}

		microsoftTeams.authentication.getAuthToken(authTokenRequest);
	});

	try {
		const token = await tokenPromise;
		return {
			expiry: undefined,
			token: token,
			type: MsalResponseType.SUCESSS
		}
	} catch (error) {
		return {
			expiry: undefined,
			token: undefined,
			message: error,
			type: MsalResponseType.ERROR
		}
	}
}

export const getMsalToken = async (userAcc?: msal.AccountInfo): Promise<TokenResponse> => {
	const acc = userAcc || msalAuth.getAllAccounts()?.[0];
	try {
		// console.log("Acquiring msal Access-Token");
		// console.log(acc);
		const authToken = await getTokenRedirect(createSilentRequest(acc));

		if (authToken) {
			// console.log("Success for silent acquisition!")
			return {
				expiry: moment(authToken.expiresOn),
				token: authToken.accessToken,
				type: MsalResponseType.SUCESSS
			}
		}
		else return {
			expiry: undefined,
			token: undefined,
			type: MsalResponseType.REQUIRE_REDIRECT
		}
	} catch (error) {
		return {
			expiry: undefined,
			token: undefined,
			message: error,
			type: MsalResponseType.ERROR
		}
	}
}

export const msalSignIn = async (): Promise<TokenResponse> => {
	try {
		const accounts = await signInAsync("loginRedirect");
		if (accounts) {
			return {
				type: MsalResponseType.SUCESSS,
				expiry: undefined,
				token: undefined,
				message: undefined
			}
		}

	} catch (error) {
		console.log("Error in Loginredirect: ");
		console.log(loginRedirect);
		return {
			type: MsalResponseType.ERROR,
			expiry: undefined,
			token: undefined,
			message: error
		}
	}

	return {
		type: MsalResponseType.REQUIRE_REDIRECT,
		expiry: undefined,
		token: undefined,
		message: undefined
	}
	
}

export const AuthenticateAsync = async () => {
	// console.log("Called AuthenticateAsync")
	let accounts = msalAuth.getAllAccounts();
	if (!msalAccessRequest.loginHint) {
		if (!(msalAuth.getAllAccounts()?.length > 0)) {
			// accounts = await signInAsync("loginRedirect");
		}
	}

	const token = await getTokenRedirect(createSilentRequest(accounts?.[0]));
	if (token) {
		return token.accessToken;
	}
}

export const getTokenRedirect = async (userRequest: msal.SilentRequest): Promise<msal.AuthenticationResult> => {
	console.log("Silent access-token acquisition");
	try {
		const response = await msalAuth.acquireTokenSilent(userRequest);
		return response;
	}
	catch (silentError) {
		console.log("Silent Failed --> Redirect!")
		msalAuth.acquireTokenRedirect(userRequest);
		return undefined;
	}
}

export const getTokenPopup = (userRequest: msal.SilentRequest) => {
	return msalAuth.acquireTokenSilent(userRequest).catch(error => {
		console.log(msalAccessRequest);

		return msalAuth.acquireTokenPopup(userRequest)
			.then(response => {
				return response;
			})
			.catch(error => {
				console.log(`Error acquiring token using popup: ${error}`);
			});
	});
}

const signOut = () => {
	msalAuth.logout();
}

export enum AuthType {
	MSTEAMS,
	WEB
}

export enum MsalResponseType {
	REQUIRE_REDIRECT,
	SUCESSS,
	ERROR
}

export interface TokenResponse {
	type: MsalResponseType,
	token: string,
	expiry: Moment;
	message?: string;
}