import { idText, updateNamedExports } from "typescript";
import { ListItem } from "../listItem/model";
import { Meeting } from "../meeting/models";

export interface AreaIdentifier {
	id?: number;
	uri?: string;
	upn?: string;
	//primaryId :() =>string = ()=> id?.toString() || uri || updateNamedExports;
}

export interface Area extends ListItem {
    capacity?: number;
    hasBeamer?: number;
    hasProjector?: number; 
    connectableWithAreaId?: number;
    connectableWithArea?: Area;
    layoutPosition?: string;
    layoutPositionStr?: string;
    floorId: number;
	//state?: number; //0: Free, 1:no Motion in the last 5 and 30 minutes at Desk, 2: occupied/booked, 3: no-data available
}

export enum AreaAvailabilityStatus {
	Available = 0,
    Pending = 1,
    Occupied = 2,
    NoDataAvailable = 3,
}

export interface AreaState extends Area {
	parentIds: number[];
    locationId: number;
	stateId?: number; // ID of the state-delivering device dataset
    deviceId?: string;
    lastMotion?: Date;
    motionDuration?: number;
    peopleCount?: number;
    eventEnqueuedTime?: Date;
    eventProcessedTime?: Date;
    partitionId?: number;
    meetingStatus?: JSX.Element;
    start?: Date;
    end?: Date;
    canceled?: Date;
    meetingTitle: string;
    organizerEmail: string;
    //todayMeetings?: Meeting[];
	availability: AreaAvailabilityStatus;
    loadingAreaStats?: boolean;
    areaStats?: AreaStatisticData[];
    loadingZoneStats?: boolean;
    zoneStats?: ZoneCapacityData[];

    loadingFeedbackStats?: boolean;
    overallFeedback?: OverallFeedback[];
}

export interface AreaStatisticData {
    areaId: number;
	areaUri: string;
    date: Date;
    sumOfMeetings?: number;
    sumOfBotActivations?: number;
    sumOfCanceledMeetings?: number;
    sumOfManuallyCanceledMeetings?: number;
    avgPCount?: number;
    minMaxPCount?: number;
    maxMaxPCount?: number;
    minPcount?: number;
    maxPcount?: number;
    sumMotionDuration?: number;
    sumMeetingDuration?: number;
    dailyUtilization?: number;
    minLastMotion?: Date;
    maxLastMotion?: Date;
    avgUtilValue?: number;
}

export interface ZoneCapacityData {
    deviceId?: string;
    eventEnqueuedTime?: Date;
    areaId: string;
    zoneAreaId: string;
    avgpcount: number;
    percentValue?: number;
    // zone1PCount: number;
    // zone2PCount: number;
    // zone3PCount: number;
    // zone4PCount: number;
    // zone5PCount: number;
    // zone6PCount: number;
    // zone7PCount: number;
    // zone8PCount: number;
    // zone9PCount: number;
    // zone10PCount: number;
    totalPcount?: number;
    meetingNumber?: number;
}

export interface OverallFeedback {
    areaUri: string;
    year: number;
    month: number;
    rating: number;
}

export enum AreaFilter {
	onlyAvailable = "onlyAvailable",
	noProjectSpaces = "noProjectSpaces",
	noMeetingrooms = "noMeetingrooms",
	hasProjector = "hasProjector"
}

export let SENSOR_DATA_INTERVAL_HOURLY: string = 'HOURLY';