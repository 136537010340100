import * as React from "react";
import { Meeting } from "../../../data/meeting/models";
import { Chart, Bars, Transform } from "rumble-charts";
import { TooltipHost } from "office-ui-fabric-react";
import * as css from "../../App.scss";
import i18n from "../../../i18n";

interface TodayMeetingsLineProps {
    meetings?: Meeting[];
}

interface TodayMeetingsLineState {
    width: number;
}

export class TodayMeetingsLine extends React.Component<TodayMeetingsLineProps, TodayMeetingsLineState> {

    constructor(props) {
        super(props);

        this.state = {
            width: 260
        }
    }

    private calculateBarChart(meetings: Meeting[]) {
        var sorted = meetings.sort((a: Meeting, b: Meeting) => {
            return (new Date(a.start)).getTime() - (new Date(b.start)).getTime();
        });

        var todayStart = new Date();
        todayStart.setHours(6, 0, 0, 0);
        var todayEnd = new Date();
        //todayEnd.setDate(todayStart.getDate() + 1);
        todayEnd.setHours(22, 0, 0, 0);

        var returnData = [];
        var lastEnd = new Date();
        lastEnd = todayStart;
        // lastEnd.setHours(0, 0, 0, 0);

        var nowHour = Math.abs((new Date()).getTime() - todayStart.getTime()) / (60 * 60 * 1000);
        returnData.push({ data: [0, { y: nowHour, color: '#005f7d' }] });

        sorted.forEach((meeting, index, all) => {
                var hours;
                var start = new Date(meeting.start);
                var end = new Date(meeting.end);
                var canceled;
                !!meeting.canceled? canceled = new Date(meeting.canceled) : canceled = null;
                if (end > todayStart && start < todayEnd){
                    if (start > lastEnd) {
                        hours = Math.abs(start.getTime() - lastEnd.getTime()) / (60 * 60 * 1000);
                        returnData.push({ data: [{ y: hours, color: '#78af05' }] });
                    }
                    if(!!canceled && canceled < end) {
                        end = canceled;
                    }
                    hours = Math.abs((end > todayEnd ? todayEnd.getTime() : end.getTime()) - (start < todayStart ? todayStart.getTime() : start.getTime())) / (60 * 60 * 1000);
    
                    returnData.push({ name: meeting.title, data: [{ y: hours, color: '#a0002d' }] });
    
                    if (index == all.length - 1) {
    
                    }
                    lastEnd = end;
                }     
        });
        if (lastEnd < todayEnd) {
            var lastDiff = Math.abs(todayEnd.getTime() - lastEnd.getTime()) / (60 * 60 * 1000);//+ ((new Date()).getTimezoneOffset() / 60);
            returnData.push({ data: [{ y: lastDiff, color: '#78af05' }] });
        }

        return returnData;
    }

    render() {
        var series = [];
        if (!!this.props.meetings)
            series = this.calculateBarChart(this.props.meetings);

        return (
            <TooltipHost className={css.overviewComponentOverall} content={i18n.t("todayMeetingLineTooltip")} closeDelay={100} >
                <span style={{fontSize: '8pt'}}>{i18n.t("todayMeetingLineText")}: </span>
                <Chart width={this.state.width} height={10} minY={0} maxY={24}>
                    <Transform method={['stack', 'rotate']} series={series} >
                        <Bars barStyle={{ opacity: 1 }} combined={true} innerPadding='-1%' />
                    </Transform>
                </Chart>
            </TooltipHost>
        )
    }
}