import { Floor, FloorStatistic } from "./models";
import { Meeting } from "../meeting/models";
import { AreaIdentifier, AreaStatisticData } from "../areas/models";
import { ApiClient, AreaTypeEnum, FindAreaDTO } from "../../services/ApiClient";
import { FloorMapper, FloorStatisticMapper } from "./floorMapper";
import { AreaStatisticMapper, MeetingMapper } from "../areas/areaMapper";
import { listItem } from "../../components/list/listField.module.scss";
import { ListItem } from "../listItem/model";

const client = new ApiClient();

const getArrayOrUndefined = <T, D, V extends string[]>(dtoArr: T[], callback: (dto: T, ...args: V) => D, ...args: V): D[] | undefined => {
	if (dtoArr && Array.isArray(dtoArr)) {
		return dtoArr.map(dto => callback(dto, ...args));
	}

	return undefined;
}

export function getFloors(locationUri?: string): Promise<Floor[]> {
	if (!!locationUri) {
		return client.areas_ByType(AreaTypeEnum.Location, locationUri)
			.then(res => getArrayOrUndefined(res, FloorMapper.toDomain));
	}
	else {
		const filter = { areaType: "Floor" } as FindAreaDTO;
		return client.areas_FindArea(filter, 10)
			.then(res => getArrayOrUndefined(res, FloorMapper.toDomain));
	}
}

export function getHierarchialFloors(locationUri: string): Promise<{ floors: Floor[], parent: ListItem }> {
	return client.areas_GetNestedStatus(locationUri, undefined, undefined)
		.then(res => {
			if (res && Array.isArray(res) && res.length > 0) {
				return {
					floors: res[0]?.children?.map(child => FloorMapper.hierarchyToDomain(child, locationUri)),
					parent: FloorMapper.hierarchyToDomain(res[0], locationUri)
				}
			}
		})
}

export function getFloor(id: number): Promise<Floor> {
	return client.areas_GetStatus(id.toString())
		.then(res => FloorMapper.toDomain(res));
}

export function getFloormap(id: AreaIdentifier): Promise<string> {
	const actualId = id.id?.toString() || id.uri || id.uri;
	return client.areas_GetFloormap(actualId)
		.then(res => res);
}

export function getFloorStatistic(uri: string): Promise<AreaStatisticData[]> {
	return client.areas_GetStatistics(uri, null, null)
		.then(res => AreaStatisticMapper.toDomain(res, uri));
}

export function getAreaStatistic(uri: AreaIdentifier): Promise<AreaStatisticData[]> {
	const actualId = uri.id?.toString() || uri.uri || uri.upn;
	return client.areas_GetStatistics(actualId, null, null)
		.then(res => AreaStatisticMapper.toDomain(res, actualId));
}

export function getMeetings(id: AreaIdentifier): Promise<Meeting[]> {
	const actualId = id.id?.toString() || id.uri || id.upn;

	return client.areas_GetNestedMeetings(actualId)
		.then(res => getArrayOrUndefined(res, MeetingMapper.toDomain));
}
