import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
// import { 
//     FETCH_LOCATIONS, fetchLocationsSuccess, fetchLocationsError, 
//     FETCH_LOCATION, fetchLocationSuccess, fetchLocationError, 
//     FetchLocationAction, fetchLocation, FetchParentLocationAction, FETCH_PARENT_LOCATION 
// } from "../actions/locationActions";
import { getLocations, getLocation, getParentLocation } from "../../data/locations/locations.api";
import { getNestedAreas } from "../../data/areas/areas.api";
import { navigateToFloors } from "../../components/navigation/actions";
import { ensureAuthenticationSaga } from "./authenticationSaga";
import { AreaState } from "../../data/areas/models";

// function* handleFetchLocations() {
//     try {
// 		yield ensureAuthenticationSaga();
//         //let locations = yield call(getLocations);
//         let locations:AreaState[] = yield call(getNestedAreas, undefined);

//         if (locations.length == 1)
//             yield put(navigateToFloors(locations[0].uri));
//         else {
//             yield put(fetchLocationsSuccess(locations));
//         }
//     } catch (e) {
//         yield put(fetchLocationsError(e));
//     }
// }

// function* handleFetchParentLocation({childId}: FetchParentLocationAction) {
// 	try {
// 		yield ensureAuthenticationSaga();
// 		const location = yield call(getParentLocation, childId);

// 		yield put(fetchLocationSuccess(location));
// 	} catch (e) {
// 		yield put(fetchLocationsError(e))
// 	}
// }

// function* handleFetchLocation({ locationId }: FetchLocationAction) {
//     try {
// 		yield ensureAuthenticationSaga();
//         const location = yield call(getLocation, locationId);

//         yield put(fetchLocationSuccess(location));
//     } catch (e) {
//         yield put(fetchLocationError(e));
//     }
// }

export default function* locationsSaga(): IterableIterator<Effect> {
    yield all([
        // takeLatest(FETCH_LOCATIONS, handleFetchLocations),
		// takeLatest(FETCH_LOCATION, handleFetchLocation),
		// takeLatest(FETCH_PARENT_LOCATION, handleFetchParentLocation)
    ]);
}
