
import { combineReducers } from "redux";
import { LocationState } from "redux-first-router";
import routes from "./routes";

export type NavigationState = Readonly<{
    location: LocationState;
}>;

export type StateWithNavigation = Readonly<{
    navigation: NavigationState;
}>;

const navigationReducers = {
    location: routes.reducer
};

export default combineReducers<NavigationState>(navigationReducers);
