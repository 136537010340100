import axios, { AxiosInstance, AxiosResponse } from "axios";
import { CONFIG } from "../../config/settings";
import { getToken } from "./ApiClientAuth"
/**
 * Configuration class needed in base class.
 * The config is provided to the API client at initialization time.
 * API clients inherit from #AuthorizedApiBase and provide the config.
 */
export class ApiClientOptions {
	/**
	 * Returns a valid value for the Authorization header.
	 * Used to dynamically inject the current auth header.
	 */
	getAuthorization = () => getToken();
}

export class ApiClientBase {
	protected instance: AxiosInstance;
	private readonly config: ApiClientOptions;

	protected constructor(config: ApiClientOptions) {
		this.config = config || new ApiClientOptions();
		this.instance = axios.create();

		this.instance.interceptors.request.use((axiosConfig) => {
			// Check and acquire a token before the request is sent
			// console.log("Setting promise interceptor for authentication");
			axiosConfig.responseType = "json"
			return new Promise(async (resolve, reject) => {
				let token = await this.config.getAuthorization(); //authContext.getCachedToken(adalConfig.clientId);
				// console.log("Retrieved cached token by MSAL: " + token);

				if (!!token) {
					axiosConfig.headers.Authorization = `Bearer ${token}`;
					axiosConfig.headers.Accept = "application/json";
					axiosConfig.transformResponse = (resp => resp);
					resolve(axiosConfig)
				}

				reject(axiosConfig);
			})
		}, function (error) {
			// Do something with error of the request
			return Promise.reject(error)
		});
	}

	/**
	 * This has to be used when using BaseClient
	 */
	protected transformResult(url: string, response: AxiosResponse, processor: (response: AxiosResponse) => any): any {		
		// INFO: nswag is having a object in data instead of string, then tries to parse the object "from string".
		//response.data = JSON.stringify(response.data);
		return processor(response);
	}

	protected getBaseUrl = (defaultUri?: string): string => {
		return CONFIG.apiConfig.baseURL ?? defaultUri;
	}
}