import * as React from "react";
import { Area, AreaState } from "../../../data/areas/models";
import * as css from "../areas.module.scss";
import { Rating, RatingSize } from 'office-ui-fabric-react/lib/Rating';
import classNames from "classnames";
import i18n from "../../../i18n";

interface OverallFeedbackProps {
    roomState: AreaState;
}

export class OverallFeedback extends React.Component<OverallFeedbackProps> {
    constructor(props) {
        super(props);
    }

    private months: string[] = i18n.t("shortMonths") || ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    render() {
        const { overallFeedback } = this.props.roomState;

        return !!overallFeedback ?
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <h2>{i18n.t("headlineFeedbackHistory")}</h2>
                    </div>
                </div>

                {overallFeedback.length > 0 ? overallFeedback.map((element, i) =>
                    <div className={"ms-Grid-row " + css.ratingStars} key={"Feedback" + i}>
                        <div className={classNames("ms-Grid-col ms-sm2", css.overviewComponent)}>
                            {this.months[element.month - 1]} {element.year}
                        </div>
                        <div className="ms-Grid-col ms-sm10">
                            <div title={element.rating.toString() + " Stars"}>
                                <Rating
                                    id={'feedbackStars_' + { i }}
                                    min={1}
                                    max={5}
                                    readOnly={true}
                                    rating={element.rating}
                                    allowZeroStars={true}
                                    size={RatingSize.Small}
                                />
                            </div>
                        </div>
                    </div>
                )
                : <span>{i18n.t("feedbackNone")}</span>
                }
            </div>
            : null
    }
}