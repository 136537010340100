import classNames from "classnames";
import * as cssGlobal from '../App.scss';
import { Toggle, Checkbox } from "office-ui-fabric-react";
import { useDispatch, useSelector } from "react-redux";
import * as React from "react"
import { addFilter, deleteFilter } from "../../store/actions/areaActions/areaActions";
import { AreaFilter } from "../../data/areas/models";
import { filterHasProjectorSelector, filterNoMeetingroomsSelector, filterNoProjectSpacesSelector, filterOnlyAvailableSelector } from "../../store/selectors";
import i18n from "../../i18n";


export const AreaFilterControl: React.FC = (props) => {
	const onlyAvailable = useSelector(filterOnlyAvailableSelector);
	const hasProjector = useSelector(filterHasProjectorSelector);
	const meetingRooms = useSelector(filterNoMeetingroomsSelector);
	const projectSpaces = useSelector(filterNoProjectSpacesSelector);
	
	const dispatch = useDispatch();

    const _onChangeAvailable = React.useCallback((ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
		if(checked) {
			dispatch(addFilter(AreaFilter.onlyAvailable))
		}
		else {
			dispatch(deleteFilter(AreaFilter.onlyAvailable))
		}
    }, [dispatch]);

    const _onChangeProjector = React.useCallback((ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        if(checked) {
			dispatch(addFilter(AreaFilter.hasProjector))
		}
		else {
			dispatch(deleteFilter(AreaFilter.hasProjector))
		}
    }, [dispatch]);

    const _onChangeRooms = React.useCallback((ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        if(checked) {
			dispatch(addFilter(AreaFilter.noMeetingrooms))
		}
		else {
			dispatch(deleteFilter(AreaFilter.noMeetingrooms))
		}
    }, [dispatch]);

    const _onChangeProjectSpaces = React.useCallback((ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        if(checked) {
			dispatch(addFilter(AreaFilter.noProjectSpaces))
		}
		else {
			dispatch(deleteFilter(AreaFilter.noProjectSpaces))
		}
    }, [dispatch]);

	return (
		<>                       
		<div>
			<div className={classNames("ms-Grid", cssGlobal.filterMediumDevices)}>
				<Toggle

					label={i18n.t("available")}
					inlineLabel={true}
					checked={onlyAvailable}
					onChange={_onChangeAvailable}
					// onText="yes"
					// offText="all"
					styles={controlStylesSmall}
					className={`ms-Grid-col ms-sm4 ${cssGlobal.filterToggle}`}

				/>
				<Toggle
					label={i18n.t("projector")}
					inlineLabel={true}
					checked={hasProjector}
					onChange={_onChangeProjector}
					// onText="yes"
					// offText="all"
					styles={controlStylesSmall}
					className={`ms-Grid-col ms-sm4 ${cssGlobal.filterToggle}`}
				/>
				<div className={`ms-Grid-col ms-sm4 ${cssGlobal.noPaddingLeft}`}>
					{/* <Label htmlFor="checkBoxes">{i18n.t("areas")}</Label> */}
					<div id="checkBoxes" style={{ paddingTop: "5px" }} >
						<Checkbox label={i18n.t("meetingrooms")} styles={checkboxStylesSmall} onChange={_onChangeRooms} checked={!meetingRooms} className={`ms-Grid-row ${cssGlobal.noPaddingLeft}`} />
						<Checkbox label={i18n.t("projectSpaces")} styles={checkboxStylesSmall} onChange={_onChangeProjectSpaces} checked={!projectSpaces} className={`ms-Grid-row ${cssGlobal.noPaddingLeft}`} />
					</div>
				</div>

				{/* <TextField label={i18n.t("filterName")+":"} onChange={this._onChangeText} styles={controlStyles} /> */}
			</div>
			<div className={classNames("ms-Grid", cssGlobal.overviewComponentOverall)}>
				<Toggle
					label={i18n.t("available")}
					inlineLabel={true}
					checked={onlyAvailable}
					onChange={_onChangeAvailable}
					// onText="yes"
					// offText="all"
					styles={controlStyles}
					className={`ms-Grid-col ${cssGlobal.filterToggle}`}

				/>
				<Toggle
					label={i18n.t("projector")}
					inlineLabel={true}
					checked={hasProjector}
					onChange={_onChangeProjector}
					// onText="yes"
					// offText="all"
					styles={controlStyles}
					className={`ms-Grid-col ${cssGlobal.filterToggle}`}
				/>
				<div className={`ms-Grid-col ${cssGlobal.noPaddingLeft}`}>
					{/* <Label htmlFor="checkBoxes">{i18n.t("areas")}</Label> */}
					<div id="checkBoxes" style={{ paddingTop: "5px" }} >
						<Checkbox label={i18n.t("meetingrooms")} onChange={_onChangeRooms} checked={meetingRooms} className={`ms-Grid-col ${cssGlobal.noPaddingLeft}`} />
						<Checkbox label={i18n.t("projectSpaces")} onChange={_onChangeProjectSpaces} checked={projectSpaces} className={`ms-Grid-col ${cssGlobal.noPaddingLeft}`} />
					</div>
				</div>
				{/* <TextField label={i18n.t("filterName")+":"} onChange={this._onChangeText} styles={controlStyles} /> */}
			</div>
		</div>
	</>)
}

const controlStyles = {
	root: {
		margin: '0 30px 20px 0',
		maxWidth: '300px'
	}
};
const controlStylesSmall = {
	root: {
		margin: '0 30px 20px 0',
		maxWidth: '300px'
	},
	pill: {
		fontSize: '14px'
	},
	label: {
		fontSize: '11px',
		marginLeft: '5px'
	}
};
const checkboxStylesSmall = {
	checkbox: {
		width: '14px',
		height: '14px'
	},
	text: {
		fontSize: '11px',
		marginLeft: '5px'
	}
};