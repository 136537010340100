import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Breadcrumb, IBreadcrumbItem } from 'office-ui-fabric-react/lib/Breadcrumb';
import { Label, ILabelStyles } from 'office-ui-fabric-react/lib/Label';
import { StateWithData } from '../app/reducers';
import { Floor } from '../../data/floors/models';
import { LocationModel } from '../../data/locations/models';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import * as css from '../App.scss';
import { AreaState } from '../../data/areas/models';
import { navigateToLocations, navigateToFloors, navigateToFloorDetails, navigateToRoomDetails } from "../navigation/actions";
import { ChevronRightIcon } from '../Icons';
import i18n from '../../i18n';
import { locationSelector } from '../navigation/selectors';
import { currentFloorSelector, currentLocationSelector, currentRoomSelector } from '../../store/selectors';


class BreadcrumbFabric extends React.Component<BreadcrumbFabricComponentProps> {

    constructor(props) {
        super(props);
    }

    onBreadcrumbItemClicked = (ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void => {
        if (item.key == "f1") {
            this.props.navigateToLocations();
        } else if (item.key == "f2") {
            this.props.navigateToFloors(this.props.currentLocation.identifier);
        } else if (item.key == "f3") {
            this.props.navigateToFloorDetails(this.props.currentFloor.identifier);
        } else if (item.key == "f4") {
            this.props.navigateToRoomDetails(this.props.currentRoom.identifier);
        }
    }

    getCustomDivider = (): JSX.Element => {
        return (
            <ChevronRightIcon className={css.paddingLeftRight} />
        );
    }

    private _onRenderItem = (props: IBreadcrumbItem, defaultRenderer?: any): JSX.Element => {
        return (
            <>
                {props.key == "f1"
                    ?
                    <ActionButton
                        href="#/locations"
                        className={css.breadcrumbActionbutton}
                    >
                        <Icon iconName="POI" className={`ms-Button-icon ${css.locationIcon} ${css.colorBlue}`}></Icon>
                        {!this.props.currentRoom && !this.props.currentFloor && !this.props.currentLocation
                            ? <span className={css.breadcrumbCurrentItemText}>{props.text}</span>
                            : <span>{props.text}</span>
                        }
                    </ActionButton>
                    : props.key == "f2"
                        ?
                        <ActionButton
                            href={"#/locations/" + this.props.currentLocation.identifier.uri}
                            className={css.breadcrumbActionbutton}
                        >
                            <Icon iconName="CityNext2" className={`ms-Button-icon ${css.locationIcon} ${css.colorBlue}`}></Icon>
                            {props.isCurrentItem
                                ? <span className={css.breadcrumbCurrentItemText}>{props.text}</span>
                                : <span >{props.text}</span>
                            }
                        </ActionButton>
                        : props.key == "f3"
                            ?
                            <ActionButton
                                href={"#/floors/" + this.props.currentFloor.identifier.uri}
                                className={css.breadcrumbActionbutton}
                            >
                                <Icon iconName="MapLayers" className={`ms-Button-icon ${css.locationIcon} ${css.colorBlue}`}></Icon>
                                {props.isCurrentItem
                                    ? <span className={css.breadcrumbCurrentItemText}>{props.text}</span>
                                    : <span >{props.text}</span>
                                }
                            </ActionButton>
                            : props.key == "f4"
                                ?
                                <ActionButton
                                    href={"#/rooms/" + (this.props.currentRoom.identifier.uri || this.props.currentRoom.identifier.id)} // seems like rooms dont have a id, instead they use areaID, this is the fallback
                                    className={css.breadcrumbActionbutton}
                                >
                                    <Icon iconName="Room" className={`ms-Button-icon ${css.locationIcon} ${css.colorBlue}`}></Icon>
                                    {props.isCurrentItem
                                        ? <span className={css.breadcrumbCurrentItemText}>{props.text}</span>
                                        : <span >{props.text}</span>
                                    }
                                </ActionButton>
                                : null
                }
            </>
        );
    }

    render() {
        const { currentLocation, currentFloor, currentRoom } = this.props;
        const items: IBreadcrumbItem[] = [{ text: i18n.t("locations"), key: 'f1', onClick: this.onBreadcrumbItemClicked, isCurrentItem: false }];
        if (currentLocation) {
            items.push({ text: currentLocation.title, key: 'f2', onClick: this.onBreadcrumbItemClicked, isCurrentItem: !currentFloor });

            if (currentFloor) {
                items.push({ text: currentFloor.title, onClick: this.onBreadcrumbItemClicked, key: 'f3', isCurrentItem: !currentRoom });

                if (currentRoom) {
                    items.push({ text: currentRoom.title, onClick: this.onBreadcrumbItemClicked, key: 'f4', isCurrentItem: true });
                }
            }
        }

        const BreadcrumbCollapsingExample: React.FunctionComponent = () => {
            return (
                <div>
                    <Breadcrumb
                        items={items}
                        maxDisplayedItems={4}
                        ariaLabel={i18n.t("breadcrumbMaxSize")}
                        overflowAriaLabel="More links"
                        onRenderItem={this._onRenderItem}
                        className={css.noMarginTop}
                        dividerAs={this.getCustomDivider}
                    />
                </div>
            );
        };
        return <BreadcrumbCollapsingExample />;
    }
}



type StateProps = Readonly<{
    currentLocation: AreaState;
    currentFloor: AreaState;
    currentRoom: AreaState;
}>;

type DispatchProps = Readonly<{
    navigateToLocations: typeof navigateToLocations;
    navigateToFloors: typeof navigateToFloors;
    navigateToFloorDetails: typeof navigateToFloorDetails;
    navigateToRoomDetails: typeof navigateToRoomDetails;
}>;

export type BreadcrumbFabricComponentProps = StateProps & DispatchProps;

function mapStateToProps(state: StateWithData): StateProps {
    return {
        currentLocation: currentLocationSelector(state),
        currentFloor: currentFloorSelector(state),
        currentRoom: currentRoomSelector(state)
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
    return bindActionCreators({
        navigateToLocations,
        navigateToFloors,
        navigateToFloorDetails,
        navigateToRoomDetails
    }, dispatch);
}

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(BreadcrumbFabric);
