import { all, Effect, put, takeLatest } from "redux-saga/effects";
import { getMeetings } from "../../data/floors/floors.api";
import { FetchMeetingsAction, fetchMeetingsError, fetchMeetingsSuccess, FETCH_MEETINGS } from "../actions/meetingActions";
import { ensureAuthenticationSaga } from "./authenticationSaga";

export function* handleFetchMeetings(action: FetchMeetingsAction) {
	try {
		yield ensureAuthenticationSaga();
		const meetings = yield getMeetings(action.areaId);
		yield put(fetchMeetingsSuccess(meetings));
	}

	catch(e) {
		yield put(fetchMeetingsError(e));
	}
}

export default function* meetingSaga(): IterableIterator<Effect> {
    yield all([
        takeLatest(FETCH_MEETINGS, handleFetchMeetings),
    ]);
}