import * as React from "react";
import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";
import { ListItem } from "../../data/listItem/model";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { AreaState } from "../../data/areas/models";
//import { Chart } from "@progress/kendo-charts-react-wrapper";
// import '@progress/kendo-ui';
import { TodayMeetingsLine } from "../areas/room/todayMeetingsLine";
//import { BarChart } from "../charts/barchart";
import classNames from "classnames";
import * as css from "../App.scss";
import * as itemCss from "./listField.module.scss";
import * as common from "../../utils/commonFunctions";
import { CONFIG } from "../../../config/settings"
import i18n from "../../i18n";
import { Meeting } from "../../data/meeting/models";
const meetingroomIcon = CONFIG.icons.meetingroomIcon;
const flexDeskIcon = CONFIG.icons.flexDeskIcon;
const projectSpaceIcon = CONFIG.icons.projectSpaceIcon;
const serviceAccountName: string = CONFIG.adHocConfig.serviceAccountName;
const adHocMeetingTitle = CONFIG.adHocConfig.adHocMeetingTitle;


export type ListFieldItemProps = {
    item: ListItem | AreaState;
	meetings: Meeting[];
    index?: number;
    onOpenItem(item: ListItem): void;
};

function renderInfo(icon: string, text: string, total: number, available?: number, tooltip?: string) {
    return (
        <div className="ms-Grid-col ms-sm12 ms-md6 ms-xxl4 ms-xxxl3" style={{ marginBottom: 2 }}>
            <TooltipHost className={css.overviewComponentOverall} content={tooltip}>
                <div style={{ whiteSpace: "nowrap" }}>
                    <img src={icon} width="30" style={{ verticalAlign: "middle", marginRight: 5 }} />
                    <span style={{ verticalAlign: "middle", color: "green" }}>{(available != undefined) && Math.round(available) + " / "}{Math.round(total)} {text}</span>
                </div>
            </TooltipHost>
        </div>
    )
}

function renderImage(item: ListItem) {
    if (!!item.image) {
        return <Image className="ms-Grid-col ms-md2 ms-hiddenMdDown" src={item.image} width={150} height={100} imageFit={ImageFit.cover} />
    }
    var srcUrl = "../img/listItemDefault_" + item.type.toString() + ".jpg";
    // switch (item.type) {
    //     case 'Room':
    //         srcUrl = "img/listItem_Room.jpg";
    //         break;
    //     case 'ProjectSpace':
    //     srcUrl = "img/listItem_ProjectSpace.jpg";
    //         break;
    //     case 'Floor':
    //     srcUrl = "img/listItem_Floor.jpg";
    //         break;
    //     case 'Location':
    //     srcUrl = "img/listItem_ProjectSpace.jpg";
    //         break;
    //     default:
    //         srcUrl = ""
    // }

    return <Image className="ms-Grid-col ms-md2 ms-hiddenMdDown" src={srcUrl} width={150} height={100} imageFit={ImageFit.cover} />
}

export function renderRoomInfo(area: AreaState, meetings: Meeting[]) {

    var todayStart = new Date();
    todayStart.setHours(6, 0, 0, 0);
    var todayEnd = new Date();
    //todayEnd.setDate(todayStart.getDate() + 1);
    todayEnd.setHours(22, 0, 0, 0);
    let areaStartTomorrow = area.start ? new Date(area.start) : null;
    area.start ? areaStartTomorrow.setDate(areaStartTomorrow.getDate() + 1) : null;

    return (
        <div>
            <div className="ms-Grid-row" >
                <div className="ms-Grid-col ms-md12 ms-hiddenMdDown" >
                    <TodayMeetingsLine meetings={meetings} />
                </div>
            </div>
            <div className={classNames(css.overviewComponent)}>
                {!!area.start ?
                    <div className="ms-fontWeight-semibold">
                        <div>
                            <div>{i18n.t("currentMeeting")}:</div>
                            <div>
                                {areaStartTomorrow.toLocaleTimeString() == new Date(area.end).toLocaleTimeString() || (new Date(area.start) < todayStart && new Date(area.end) > todayEnd) ?
                                    <div className={css.colorRed} >{i18n.t("allDay")}</div>
                                    :
                                    <div className={css.colorRed} >{new Date(area.start).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - {new Date(area.end).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</div>
                                }
                                {area.meetingTitle == serviceAccountName ?
                                    <div className={css.colorRed} >{adHocMeetingTitle}</div>
                                    :
                                    <div className={`$(css.colorRed) $(css.textEllipsis)`} >{area.meetingTitle}</div>
                                }
                            </div>
                            {/* {areaStartTomorrow.toLocaleTimeString() == new Date(area.end).toLocaleTimeString() || (new Date(area.start) < todayStart && new Date(area.end) > todayEnd) ?
                                <div className={classNames("ms-Grid-col ms-md4 ms-lg4 ms-xxl2 ms-hiddenSm", css.colorThemePrimary)} >{i18n.t("allDay")}</div>
                                :
                                <div className={classNames("ms-Grid-col ms-md4 ms-lg4 ms-xxl2 ms-hiddenSm", css.colorThemePrimary)} >{new Date(area.start).toLocaleTimeString()} > {new Date(area.end).toLocaleTimeString()}</div>
                            }
                            {area.meetingTitle == serviceAccountName?
                                <div className={classNames("ms-Grid-col ms-md4 ms-lg4 ms-xxl8 ms-hiddenSm", css.colorThemePrimary)} >{adHocMeetingTitle}</div>
                                :
                                <div className={classNames("ms-Grid-col ms-md4 ms-lg4 ms-xxl8 ms-hiddenSm", css.colorThemePrimary)} >{area.meetingTitle}</div>
                            } */}
                        </div>
                    </div>
                    :
                    <div>
                        {common.renderMeetingTile(area, meetings, classNames("ms-Grid-col ms-md12", css.listComponentText))}
                    </div>
                }
            </div>
            {/* <div className="ms-hiddenMdUp">
                {common.renderValuesSM(area, "", false)}
            </div>
            <div className={`ms-hiddenSm ${css.floorTileIconContainer}`}>
                {common.renderValues(area, "", false)}
            </div> */}
        </div >
    );
}

const ListFieldItem: React.FC<ListFieldItemProps> = ({ item, meetings, index, onOpenItem }: ListFieldItemProps) => (
    <div className={
        classNames("ms-Grid-row", "ms-bgColor-neutralLighter", "ms-bgColor-themeLighter--hover", itemCss.listItem, css.slideIn)}
        style={{ marginTop: 4, borderBottom: "solid 1px grey", minHeight: 60 }}
        data-is-focusable={true} onClick={() => onOpenItem(item)} >
        {/* {renderImage(item)} */}
        <div className={"ms-Grid-col ms-sm12 ms-lg8"} style={{ paddingTop: 4 }}>
            <div className="ms-Grid">
                <div className="ms-Grid-row"><h3 className={classNames("ms-Grid-col ms-sm12", css.colorThemeSecondary)} >{item.title}</h3></div>
                <div className="ms-Grid-row"><div className="ms-Grid-col ms-sm12">{item.description}</div></div>
                {!!meetings ?
                    renderRoomInfo(item as AreaState, meetings)
                    :
                    <div className="ms-Grid-row" style={{ display: "inline-flex", flexWrap: "wrap", width: "100%" }} >

                        {!!item.totalMeetingRooms &&
                            renderInfo(String(meetingroomIcon), "Meeting rooms", item.totalMeetingRooms, item.availableMeetingRooms, "Available meeting rooms")
                        }
                        {!!item.totalProjectSpaces &&
                            renderInfo(String(projectSpaceIcon), "Project spaces", item.totalProjectSpaces, item.availableProjectSpaces, "Available project spaces")
                        }
                        {!!item.totalFlexdesks &&
                            renderInfo(String(flexDeskIcon), "Flexdesks", item.totalFlexdesks, item.availableFlexdesks, "Available flexdesks")
                        }
                        {/* {item.weatherData &&
                        <div className="ms-Grid-col ms-sm0 ms-xxxl3 ms-hiddenXxlDown">
                            <img src={String(weatherIcon)} width="40" style={{ verticalAlign: "middle", marginRight: 5 }}/>
                            <span style={{ verticalAlign: "middle" }}>{item.weatherData}</span>
                        </div>
                    } */}
                    </div>
                }
            </div>
        </div>
    </div >
)

export default ListFieldItem;