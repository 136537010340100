import { assert} from "console";
import { all, call, Effect, put, takeLatest, takeLeading, takeEvery, select, take, race, spawn } from "redux-saga/effects";
import {authenticationError, authenticationSuccess, authorizationError, authorizationRequestRedirect, authorizationSuccess, initLogon, initLogonSuccess, logonError, logonSuccess, LOGON_AUTHENTICATE_SUCCESS, LOGON_AUTHORIZE_SUCCESS, LOGON_ERROR, LOGON_INIT, LOGON_INIT_SUCCESS, LOGON_REQUEST, LOGON_SUCCESS, requestAuthentication, requestAuthenticationRedirect, requestLogon} from "../../components/app/actions"
import { LoginState } from "../../components/app/reducers";
import { LOGIN_ROUTE, navigate, navigateToLocations } from "../../components/navigation/actions";
import { setToken, AuthType, checkTeamsContext, getAuthTokenFromTeams, getMsalToken, handleRedirect, msalSignIn, MsalResponseType, TokenResponse } from "../../services/ApiClientAuth";
import { logonSelector } from "../selectors";


/**
 * Blocks until successfully authorized
 */
export function* ensureAuthenticationSaga() {
	let login: LoginState = yield select(logonSelector);
	if (!login.initialized) {
		yield put(initLogon());
		yield take(LOGON_INIT_SUCCESS);
	}

	login = yield select(logonSelector);
	if (!login.authenticated) {
		yield spawn(handleAuthentication);
		yield take(LOGON_AUTHENTICATE_SUCCESS)
	}

	login = yield select(logonSelector);
	if (!login.authorized) {
		yield spawn(handleAuthorization);
		yield take(LOGON_AUTHORIZE_SUCCESS);
	}

}

function* initLogonSaga() {
	const inTeams: boolean = yield checkTeamsContext();
	if (inTeams) {
		yield put(initLogonSuccess(AuthType.MSTEAMS))
	}
	else {
		yield put(initLogonSuccess(AuthType.WEB));
	}
}

function* handleMsalLogin() {
	yield handleMsalAuthentication();
	take(LOGON_AUTHENTICATE_SUCCESS);
	yield handleMsalAuthorization();
	take(LOGON_AUTHORIZE_SUCCESS);
	return true;
}

function* handleAuthentication() {
	const login: LoginState = yield select(logonSelector);
	//let tokenPromise: Promise<string> = undefined;
	if (login.authType === AuthType.MSTEAMS) {
		console.log("Handling Teams Authentication")
		yield handleTeamsAuthentication();
	}
	else {
		yield handleMsalAuthentication();
	}
}

function* handleMsalAuthentication() {
	// if cahcehd und expoiredon still valid
	const response: TokenResponse = yield msalSignIn();
	console.log("Msal yielded: ", response);
	switch (response.type) {
		case MsalResponseType.SUCESSS: {
			yield put(authenticationSuccess());
			break;
		}

		case MsalResponseType.REQUIRE_REDIRECT: {
			yield put(requestAuthenticationRedirect());
			break;
		}

		default: {
			yield put(authenticationError());
			break;
		}
	}

}

function* handleTeamsAuthentication() {
	yield put(authenticationSuccess());
}

function* handleMsalAuthorization() {
	const response: TokenResponse = yield getMsalToken();
	yield handleAuthorizationResponse(response);
}

function* handleTeamsAuthorization() {
	console.log("Getting Teams token")
	const response = yield getAuthTokenFromTeams();
	yield handleAuthorizationResponse(response);
}

function* handleAuthorizationResponse(response: TokenResponse) {
	switch (response.type) {
		case MsalResponseType.SUCESSS: {
			setToken(response.token);
			yield put(authorizationSuccess(response.token, response.expiry));
			break;
		}
		case MsalResponseType.REQUIRE_REDIRECT: {
			yield put(authorizationRequestRedirect());
			break;
		}
		default: {
			yield put(authorizationError());
			break;
		}
	}
}

function* handleAuthRedirect() {
	const response: TokenResponse = yield handleRedirect();
	if (response.type === MsalResponseType.SUCESSS) {
		yield put(authenticationSuccess())
	}
	yield handleAuthorizationResponse(response)
	yield take(LOGON_AUTHORIZE_SUCCESS);
	yield put(navigate(navigateToLocations()))
}

function* handleAuthorization() {
	const login: LoginState = yield select(logonSelector);
	//let tokenPromise: Promise<string> = undefined;
	if (login.authType === AuthType.MSTEAMS) {
		yield handleTeamsAuthorization();
	}
	else {
		yield handleMsalAuthorization();
	}
}

export function* authenticationSaga() {
	yield all([
		takeLeading(LOGON_INIT, initLogonSaga),
		takeLeading(LOGON_REQUEST, handleAuthorization),
		takeEvery(LOGIN_ROUTE, handleAuthRedirect)
	])
}