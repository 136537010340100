import { Action } from "redux";
import { AreaIdentifier, AreaStatisticData, ZoneCapacityData } from "../../../data/areas/models";
import ErrorAction from "../../../utils/ErrorAction";

/// Statistics
export const FETCH_ROOM_STATISTIC = "room/FETCH_ROOM_STATISTIC";

// export interface FetchRoomStatisticAction extends Action {
//     type: typeof FETCH_ROOM_STATISTIC;
//     roomId: AreaIdentifier;
// }

export interface FetchRoomStatisticRangeAction extends Action {
    type: typeof FETCH_ROOM_STATISTIC_RANGE;
    roomId: string;
    date1: Date;
    date2: Date;
}

// export function fetchRoomStatistic(id: AreaIdentifier): FetchRoomStatisticAction {
//     return { type: FETCH_ROOM_STATISTIC, roomId: id };
// }

 export const FETCH_ROOM_STATISTIC_SUCCESS = `${FETCH_ROOM_STATISTIC}_SUCCESS`;

export interface FetchRoomStatisticSuccessAction extends Action {
    type: typeof FETCH_ROOM_STATISTIC_SUCCESS;
    stats: AreaStatisticData[],
    roomId: string;
    date1?: Date;
    date2?: Date;
}

// export function fetchRoomStatisticSuccess(stats: AreaStatisticData[], roomId: string, date1?: Date, date2?: Date): FetchRoomStatisticSuccessAction {
//     if (date1 && date2) {
//         return { type: FETCH_ROOM_STATISTIC_SUCCESS, stats, roomId, date1, date2 };
//     }
//     else {
//         return { type: FETCH_ROOM_STATISTIC_SUCCESS, stats, roomId };
//     }
// }

// export const FETCH_ROOM_STATISTIC_ERROR = `${FETCH_ROOM_STATISTIC}_ERROR`;

// export interface FetchRoomStatisticErrorAction extends ErrorAction {
//     type: typeof FETCH_ROOM_STATISTIC_ERROR;
// }

// export function fetchRoomStatisticError(error: Error): FetchRoomStatisticErrorAction {
//     return { type: FETCH_ROOM_STATISTIC_ERROR, error, message: error.message };
// }

/// Statistics date range
export const FETCH_ROOM_STATISTIC_RANGE = "room/FETCH_ROOM_STATISTIC_RANGE";

export function fetchRoomStatisticRange(id: string, date1: Date, date2: Date): FetchRoomStatisticRangeAction {
    return { type: FETCH_ROOM_STATISTIC_RANGE, roomId: id, date1: date1, date2: date2 };
}

export function fetchRoomStatisticRangeSuccess(stats: AreaStatisticData[], roomId: string, date1: Date, date2: Date): FetchRoomStatisticSuccessAction {
    return { type: FETCH_ROOM_STATISTIC_SUCCESS, stats, roomId, date1, date2 };
}

/// Zone
export const FETCH_ROOM_STATISTIC_ZONE = `${FETCH_ROOM_STATISTIC}_ZONE`;

export interface FetchRoomZonesStatisticAction extends Action {
    type: typeof FETCH_ROOM_STATISTIC_ZONE;
    roomId: AreaIdentifier;
}

export function fetchRoomZoneStatistic(id: AreaIdentifier): FetchRoomZonesStatisticAction {
    return { type: FETCH_ROOM_STATISTIC_ZONE, roomId: id };
}

export const FETCH_ROOM_STATISTIC_ZONE_SUCCESS = `${FETCH_ROOM_STATISTIC_ZONE}_SUCCESS`;

export interface FetchRoomZonesStatisticSuccessAction extends Action {
    type: typeof FETCH_ROOM_STATISTIC_ZONE_SUCCESS;
    zoneStats: ZoneCapacityData[],
    roomId: string;
}

export function fetchRoomZonesStatisticSuccess(zoneStats: ZoneCapacityData[], roomId: string): FetchRoomZonesStatisticSuccessAction {
    return { type: FETCH_ROOM_STATISTIC_ZONE_SUCCESS, zoneStats, roomId };
}

export const FETCH_ROOM_STATISTIC_ZONE_ERROR = `${FETCH_ROOM_STATISTIC_ZONE}_ERROR`;

export interface FetchRoomZonesStatisticErrorAction extends ErrorAction {
    type: typeof FETCH_ROOM_STATISTIC_ZONE_ERROR;
}

export function fetchRoomZonesStatisticError(error: Error): FetchRoomZonesStatisticErrorAction {
    return { type: FETCH_ROOM_STATISTIC_ZONE_ERROR, error, message: error.message };
}


// /// Capacity
// export const FETCH_ROOM_STATISTIC_CAPACITY = `${FETCH_ROOM_STATISTIC}_CAPACITY`;

// export interface FetchRoomCapacityStatisticAction extends Action {
//     type: typeof FETCH_ROOM_STATISTIC_CAPACITY;
//     roomId: string;
// }

// export function fetchRoomCapacityStatistic(id: string): FetchRoomCapacityStatisticAction {
//     return { type: FETCH_ROOM_STATISTIC_CAPACITY, roomId: id };
// }

// export const FETCH_ROOM_STATISTIC_CAPACITY_SUCCESS = `${FETCH_ROOM_STATISTIC_CAPACITY}_SUCCESS`;

// export interface FetchRoomCapacityStatisticSuccessAction extends Action {
//     type: typeof FETCH_ROOM_STATISTIC_CAPACITY_SUCCESS;
//     capaStats: AreaCapacityData,
//     roomId: string;
// }

// export function fetchRoomCapacityStatisticSuccess(capaStats: AreaCapacityData, roomId: string): FetchRoomCapacityStatisticSuccessAction {
//     return { type: FETCH_ROOM_STATISTIC_CAPACITY_SUCCESS, capaStats, roomId };
// }

// export const FETCH_ROOM_STATISTIC_CAPACITY_ERROR = `${FETCH_ROOM_STATISTIC_CAPACITY}_ERROR`;

// export interface FetchRoomCapacityStatisticErrorAction extends ErrorAction {
//     type: typeof FETCH_ROOM_STATISTIC_CAPACITY_ERROR;
// }

// export function fetchRoomCapacityStatisticError(error: Error): FetchRoomCapacityStatisticErrorAction {
//     return { type: FETCH_ROOM_STATISTIC_CAPACITY_ERROR, error, message: error.message };
// }