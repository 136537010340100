import { Action } from "redux";
import { Floor, FloorStatistic } from "../../data/floors/models";
import ErrorAction from "../../utils/ErrorAction";
import { AreaIdentifier, AreaStatisticData } from "../../data/areas/models";

export const FETCH_FLOORS = "floor/FETCH_FLOORS";

// export interface FetchFloorsAction extends Action {
//     type: typeof FETCH_FLOORS;
//     locationUri: string;
// }

// export function fetchFloors(locationUri: string): FetchFloorsAction {
//     return { type: FETCH_FLOORS, locationUri: locationUri };
// }

// export const FETCH_FLOORS_SUCCESS = `${FETCH_FLOORS}_SUCCESS`;

// export interface FetchFloorsSuccessAction extends Action {
//     type: typeof FETCH_FLOORS_SUCCESS;
//     floors: Floor[];
// }

// export function fetchFloorsSuccess(floors: Floor[]): FetchFloorsSuccessAction {
//     return { type: FETCH_FLOORS_SUCCESS, floors };
// }

// export const FETCH_FLOORS_ERROR = `${FETCH_FLOORS}_ERROR`;

// export interface FetchFloorsErrorAction extends ErrorAction {
//     type: typeof FETCH_FLOORS_ERROR;
// }

// export function fetchFloorsError(error: Error): FetchFloorsErrorAction {
//     return { type: FETCH_FLOORS_ERROR, error, message: error.message };
// }

// export const FETCH_FLOOR = "floor/FETCH_FLOOR";

// export interface FetchFloorAction extends Action {
//     type: typeof FETCH_FLOOR;
//     floorId: number;
//     withoutAreas?: boolean;
// }

// export function fetchFloor(id: number, withoutAreas?: boolean): FetchFloorAction {
//     return { type: FETCH_FLOOR, floorId: id, withoutAreas};
// }

// export const FETCH_FLOOR_SUCCESS = `${FETCH_FLOOR}_SUCCESS`;

// export interface FetchFloorSuccessAction extends Action {
//     type: typeof FETCH_FLOOR_SUCCESS;
//     floor: Floor;
// }

// export function fetchFloorSuccess(floor: Floor): FetchFloorSuccessAction {
//     return { type: FETCH_FLOOR_SUCCESS, floor };
// }

// export const FETCH_FLOOR_ERROR = `${FETCH_FLOOR}_ERROR`;

// export interface FetchFloorErrorAction extends ErrorAction {
//     type: typeof FETCH_FLOOR_ERROR;
// }

// export function fetchFloorError(error: Error): FetchFloorErrorAction {
//     return { type: FETCH_FLOOR_ERROR, error, message: error.message };
// }

export const FETCH_FLOORMAP = "floor/FETCH_FLOORMAP";

export interface FetchFloormapAction extends Action {
    type: typeof FETCH_FLOORMAP;
    floorId: AreaIdentifier;
}

export function fetchFloormap(id: AreaIdentifier): FetchFloormapAction {
    return { type: FETCH_FLOORMAP, floorId: id};
}

export const FETCH_FLOORMAP_SUCCESS = `${FETCH_FLOORMAP}_SUCCESS`;

export interface FetchFloormapSuccessAction extends Action {
    type: typeof FETCH_FLOORMAP_SUCCESS;
    areaId: AreaIdentifier;
    floormap: string;
}

export function fetchFloormapSuccess(id: AreaIdentifier, floormap: string): FetchFloormapSuccessAction {
    return { type: FETCH_FLOORMAP_SUCCESS, areaId: id, floormap };
}

export const FETCH_FLOORMAP_ERROR = `${FETCH_FLOORMAP}_ERROR`;

export interface FetchFloormapErrorAction extends ErrorAction {
    type: typeof FETCH_FLOORMAP_ERROR;
	areaId: AreaIdentifier;
}

export function fetchFloormapError(error: Error, id: AreaIdentifier): FetchFloormapErrorAction {
    return { type: FETCH_FLOORMAP_ERROR, error, message: error.message, areaId: id };
}

export const FETCH_FLOOR_STATISTIC = "floor/FETCH_FLOOR_STATISTIC";

// export interface FetchFloorStatisticAction extends Action {
//     type: typeof FETCH_FLOOR_STATISTIC;
//     floorId: string;
// }

// export function fetchFloorStatistic(id: string): FetchFloorStatisticAction {
//     return { type: FETCH_FLOOR_STATISTIC, floorId: id};
// }

// export const FETCH_FLOOR_STATISTIC_SUCCESS = `${FETCH_FLOOR_STATISTIC}_SUCCESS`;

// export interface FetchFloorStatisticSuccessAction extends Action {
//     type: typeof FETCH_FLOOR_STATISTIC_SUCCESS;
//     floorId: AreaIdentifier;
//     floorStatistic: FloorStatistic[];
// }

// export function fetchFloorStatisticSuccess(id: AreaIdentifier, floorStatistic: FloorStatistic[]): FetchFloorStatisticSuccessAction {
//     return { type: FETCH_FLOOR_STATISTIC_SUCCESS, floorId: id, floorStatistic };
// }

// export const FETCH_FLOOR_STATISTIC_ERROR = `${FETCH_FLOOR_STATISTIC}_ERROR`;

// export interface FetchFloorStatisticErrorAction extends ErrorAction {
//     type: typeof FETCH_FLOOR_STATISTIC_ERROR;
// }

// export function fetchFloorStatisticError(error: Error): FetchFloorStatisticErrorAction {
//     return { type: FETCH_FLOOR_STATISTIC_ERROR, error, message: error.message };
// }

export const FETCH_AREA_STATISTIC = "floor/FETCH_AREA_STATISTIC";

export interface FetchAreaStatisticAction extends Action {
    type: typeof FETCH_AREA_STATISTIC;
    floorId: AreaIdentifier;
}

export function fetchAreaStatistic(id: AreaIdentifier): FetchAreaStatisticAction {
    return { type: FETCH_AREA_STATISTIC, floorId: id};
}

export const FETCH_AREA_STATISTIC_SUCCESS = `${FETCH_AREA_STATISTIC}_SUCCESS`;

export interface FetchAreaStatisticSuccessAction extends Action {
    type: typeof FETCH_AREA_STATISTIC_SUCCESS;
    floorId: AreaIdentifier;
    areaStatistic: AreaStatisticData[];
}

export function fetchAreaStatisticSuccess(areaStatistic: AreaStatisticData[], id: AreaIdentifier): FetchAreaStatisticSuccessAction {
    return { type: FETCH_AREA_STATISTIC_SUCCESS, floorId: id, areaStatistic };
}

export const FETCH_AREA_STATISTIC_ERROR = `${FETCH_AREA_STATISTIC}_ERROR`;

export interface FetchAreaStatisticErrorAction extends ErrorAction {
    type: typeof FETCH_AREA_STATISTIC_ERROR;
}

export function fetchAreaStatisticError(error: Error): FetchAreaStatisticErrorAction {
    return { type: FETCH_AREA_STATISTIC_ERROR, error, message: error.message };
}

export type AnyFloorAction =
    // FetchFloorsAction | FetchFloorsSuccessAction | FetchFloorsErrorAction
    // | FetchFloorAction | FetchFloorSuccessAction | FetchFloorErrorAction |
    FetchFloormapAction | FetchFloormapSuccessAction | FetchFloormapErrorAction
    //| FetchFloorStatisticAction | FetchFloorStatisticSuccessAction | FetchFloorStatisticErrorAction
    | FetchAreaStatisticAction | FetchAreaStatisticSuccessAction | FetchAreaStatisticErrorAction;

