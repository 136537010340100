import { applyMiddleware, compose, createStore as reduxCreateStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import routes from "../navigation/routes";
import rootReducer, { AppState } from "./reducers";
import appSaga from "./sagas";

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const sagaMonitor = window.__SAGA_MONITOR_EXTENSION__;

export function createStore(initialState?: AppState): Store<AppState> {
    const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
    const middlewares = applyMiddleware(routes.middleware, sagaMiddleware);
    const store = reduxCreateStore(
        rootReducer,
        initialState,
        composeEnhancers(routes.enhancer, middlewares)
    );
    sagaMiddleware.run(appSaga);
    // routes.initialDispatch();
    return store;
}