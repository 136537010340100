import * as React from "react";
import * as d3 from "d3";
import { AreaAvailabilityStatus, AreaState } from "../../data/areas/models";
import * as style from "./floormap.less"
import { Meeting } from "../../data/meeting/models";
/**
 * Main Floorplan Component
 *
 */
const Floorplan2: React.FC<FloorplanProps> = ({children, floorPlan, flexDesks, rooms, view, currentAreaId}) => {
	const rotate3d = React.useMemo<boolean>(() => view.toLocaleUpperCase() === "3D", [view])
	const [pins, setPins] = React.useState<pin[]>([]);
	const [mapElement, setMapElement] = React.useState<RenderedFloorPlan>();
	const [layout, setLayout] = React.useState<React.CSSProperties>({})
	const [stateStyle, setStateStyle] = React.useState(style);

	//Used since the UWP Iframe changes the window-size after rendering in some instances.
	//Updates the layout to the maximum possible size
	React.useLayoutEffect(() => {
		window.onresize = () => {
			console.log("resetting Layout");
			setLayout(calculateInnerLayout(mapElement))
		}
	}, [setLayout, mapElement])


	//Called when the SVG-String is changed or a new View is expected.
	React.useLayoutEffect(() => {
		const newElement = drawMap(floorPlan, rotate3d);
		setLayout(calculateInnerLayout(newElement))
		setMapElement(newElement);

	}, [floorPlan, setMapElement, rotate3d, setLayout])

	//Called when the Mapelement has been Updated. Renders areas.
	React.useLayoutEffect(() => {
		if (mapElement) {
			const pins = updateRooms(mapElement.selection, rooms, rotate3d, mapElement.viewBox, mapElement.innerSelection, currentAreaId);
			updateFlexdesks(mapElement.selection, flexDesks)
			setPins(pins);
			notifyLoading();
		}
	}, [mapElement, rooms, rotate3d, setPins, flexDesks, currentAreaId])

	
	return(
		<div id={style.floorMap}>
			<div id={style.rotationWrapper} style={layout} className={rotate3d ? style.rotated : ""}>
				<div id={style.currentFloor}>
					<div id={style.overlays}>
						{mapElement !== undefined ?
							<MapOverlay pins={pins} viewBox={mapElement.viewBox} icons={mapElement.icons}/>
							: ""
						}
					</div>
				</div>
			</div>
		</div>
	)
}

/**
 * Enables Hot Reloading
 */
export default Floorplan2;

/**
 * Notifies UWP-System that the map has rendered.
 */
const notifyLoading = () => {
	if (window.statusLoaded) {
		window.statusLoaded.notify("");
	}
}


/**
 * Creates the maximum layout for the Map while keeping Proportions the same.
 * @param floorPlan The Floorplan-Object for which the Layout needs to be calculated
 */
const calculateInnerLayout = (floorPlan: RenderedFloorPlan | undefined): React.CSSProperties => {
	const outerNode = d3.select<HTMLDivElement, any>(`#${style.floorMap}`).node();
	console.log("This is the outer node:")
	console.log(outerNode)
	
	if (outerNode && floorPlan) {

		const ratio = floorPlan.viewBox.width / floorPlan.viewBox.height;
		const floorPlanBox = outerNode.getBoundingClientRect();
		const outerRatio = floorPlanBox.width / floorPlanBox.height;
		console.log(`This is the outer ratio ${outerRatio}`)
		console.log(`This is the box`);
		console.log(floorPlanBox)

		if (ratio > outerRatio) {
			return {
				width: `95%`, // 3D need 95%, because 100% is too much
				height: `${100*outerRatio/ratio}%`,
			}
		}
	
		return {
			height: `95%`, // 3D need 95%, because 100% is too much
			width: `${100*ratio/outerRatio}%`,
		}
	}
	return {};

}

/**
 * Colors the Flexdesks according to status
 * @param mapSelection The d3-Selection of the Map
 * @param flexdesks Flexdesks to color
 */
const updateFlexdesks = (mapSelection: SVGSelection, flexdesks: FlexDesk[]) => {
	flexdesks.forEach(fd => {
		const id = `#${escapeCSSIdentifiersFromId(fd.areaId)}`;
		//console.log(`Flexdesk-ID: ${id}`);
		const selection = mapSelection.selectAll(id)
			.classed(style.occupied, fd.state === 2)
			.classed(style.free, fd.state === 0)
			.classed(style.pending, fd.state === 1 || fd.state === 3);
		//console.log(`Selection is: ${selection.empty()} with ${fd.state}`);
	});
}

/**
 * Updates the Color of all rendered room and returns an array of pins which can be rendered.
 * @param mapSelection The d3-selection  of the map
 * @param areas The areas to update
 * @param rotate3d Should the map be rendered in 3D?
 * @param viewBox The Viewbox of the SVG-Map
 * @param nestedSelection The Selection of the innermost Floormap-SVG-Element
 * @param currentRoomId The Room at which the location should be displayed
 */
const updateRooms = (mapSelection: SVGSelection, areas: areawrapper[], rotate3d: boolean, viewBox: ClientRect, nestedSelection: SVGSelection, currentRoomId?: string): pin[] => {
	const now = new Date();
	const pins: pin[] = [];
	const innerNode = nestedSelection.node();

	if(!currentRoomId) {
		//mapSelection.selectAll(`.locationIndicatorIcon`).remove();
	}

	//Iterate through each area
	areas.forEach((aw: areawrapper) => {
		const occupied = isAreaOccupied(aw, now);
		const id = `#${escapeCSSIdentifiersFromId(aw.identifier.uri)}`;
		console.log(`The escaped id is ${id}`)
		const room = mapSelection.selectAll<SVGRectElement | SVGPathElement, unknown>(id);
		
		if (rotate3d && nestedSelection.node()) {
			if (innerNode) {
				const pin = mapPin(aw, room, viewBox, innerNode, occupied); 
				if (pin) {
					pins.push(pin);
				}
			}
		}

		else {
			console.log("Inside else!")
			colorRoomArea(room, occupied);
			setPeopleIndicator(aw);
			setLocationIndicator(aw, currentRoomId);
			room.on("click", () => notifyClick(aw.identifier.uri));
		}

	});

	return pins;
}

/**
 * Display Indicators depending on people
 * @param area the area
 */
const setPeopleIndicator = (area: areawrapper):void => {
	const queryStr = `.peopleIndicatorIcon.${escapeCSSIdentifiersFromId(area.identifier.uri)}`;
	//console.log(`Looking for ${queryStr} with ${area.pCount} people Inside!`)
	const selection = d3.select(queryStr);
	//console.log(`Found ${selection.nodes().length} elements!`);
	selection.style("display", area.peopleCount > 0 ? "inline" : "none");
}



/**
 * Displays the location-indicator if needed
 * @param roomSelection The d3-selection of the Room
 * @param area the area
 */
const setLocationIndicator = (area: areawrapper, currentId: string | undefined):void => {
	
	if (currentId && currentId.toLocaleLowerCase() === area.identifier.uri?.toLocaleLowerCase()) {
		d3.select(`.locationIndicatorIcon.${escapeCSSIdentifiersFromId(area.identifier.uri)}`)?.style("display", "inline");
	}
	else {
		d3.select(`.locationIndicatorIcon.${escapeCSSIdentifiersFromId(area.identifier.uri)}`)?.style("display", "none");
	}	
}

/**
 * Colors a specified room according to occupation
 * @param roomSelection The d3-selection of the Room
 * @param occupied Is the room occupied?
 */
const colorRoomArea = (roomSelection: d3.Selection<SVGRectElement | SVGPathElement, any,any,any>, occupied:boolean):void => {
	roomSelection
		.classed(style.occupied, occupied)
		.classed(style.free, !occupied)
		.classed(style.room, true)
 }

 /**
  * Maps an area to a pin
  * @param area The Area to map
  * @param roomRect The Rectangle of the area
  * @param viewBox The viewbox of the floormaü-SVG
  * @param relativeSVG The SVG-Node in which the roomArea ist contained
  * @param occupied Ist the room currently occupied?
  */
const mapPin = (area: areawrapper, roomSelection: d3.Selection<SVGRectElement | SVGPathElement, any, any, any>, viewBox: ClientRect, relativeSVG: SVGGraphicsElement, occupied: boolean): pin | undefined => {
	let point: DOMPoint | undefined = undefined; 
	const roomNode = roomSelection.node();
	//console.log(`Mapping: ${area.identifier.uri}`);

	if (roomNode) {
		if (roomNode instanceof SVGRectElement) {
			point = extractRelativeRectCenter(roomNode, viewBox as DOMRect, relativeSVG)
		}
	
		else if (roomNode instanceof SVGPathElement){
			point = extractRelativePathCenter(roomNode as SVGPathElement, viewBox, relativeSVG);
		}

		roomSelection.style("fill", "transparent");

	}

	if(point) {
		const pin: pin = {
			x: point.x,
			y: point.y,
			pCount: area.peopleCount,
			state: occupied ? 2: 0,
		}
		
		return pin;
	}

	return undefined;
	
}

/**
 * Notifies the UWP-App that an area was clicked
 * @param areaId The ID of the clicked area
 */
const notifyClick = (areaId: string):void =>{
	if (window.openRoomPanel) {
	try {
			window.openRoomPanel.notify(areaId);
			
		}
		catch {
			console.log("Error while notifying " + areaId);
		}
	} 
	else {
		console.log(`Couldn't find Notification Method!`)	
	} 
}

/**
 * Is the area currently free?
 * @param area The Area
 * @param now The currrent Time
 */
const isAreaOccupied = (area: areawrapper, now:Date): boolean => {
	return area.meetings.some((meeting) =>  new Date(meeting.end) > now && new Date(meeting.start) <= now);
}

/**
 * Draws the SVG-Contents of the Floormap in the Browser
 * @param svgContent The string contents of the svg-floormap
 * @param rotate3D Should the map be rendered in 3D?
 */
const drawMap = (svgContent: string, rotate3D: boolean): RenderedFloorPlan => {
	console.log(`Drawing map in ${style.currentFloor}`);
	//Delete old Map, if any
	d3.select(`#${style.currentFloor} > svg`).remove();
	
	//Select the container Element
	const mapElement = d3.select<SVGSVGElement, any>(`#${style.currentFloor}`).append("svg").html(escape(svgContent));
	const mapRoot = mapElement.node();
	console.log("this is the set mapRoot:")
	console.log(mapRoot);

	const documentSelection = d3.select(document.documentElement);

	//Display all <use> Tags and color their Background according to the stylesheet.
	mapElement.selectAll<SVGUseElement, any>("svg #peopleIndicator").style("display", "inline").select<SVGCircleElement>("circle").style("fill", documentSelection.style("--CSRed"));
	mapElement.selectAll<SVGUseElement, any>("svg #locationIndicator").style("display", "inline").select<SVGCircleElement>("circle").style("fill", documentSelection.style("--CSBlue"));
	//const uses = mapElement.selectAll<SVGUseElement, any>("use").style("display", "inline");
	//uses.nodes().forEach(node => node.style.display = "inline")

	//Select the nested SVG-Element and expand width and height.
	const innerSVG = mapElement.selectAll<SVGSVGElement, any>("svg");
	innerSVG.attr("width", "100%")
			.attr("height", "100%");
	const innerRoot = innerSVG.node();

	//console.log(mapRoot)
	//console.log(innerRoot)
	
	if (mapRoot && innerRoot) {
		//Remove inline styles of desk-circles
		//innerSVG.selectAll<SVGCircleElement,any>("circle:not(.)").attr("style", null);
			
		//Remove font-force for Labels
		//innerSVG.selectAll<SVGTextElement,any>("text").style("font-family", null)
		//innerSVG.selectAll<SVGTSpanElement,any>("tspan").style("font-family", null)
		
		//Extract the viewBox from the inner SVG
		const viewBox = innerRoot.viewBox.animVal;
	
		if (rotate3D) {
			const icons = extractRelativeIcons(mapElement, innerRoot, viewBox);
			
			//Remove the extracted from old Location
			mapElement.selectAll(".icon").remove();
			
			//Remove Texts from Map
			mapElement.selectAll("text").remove();

			mapElement.selectAll(".locationIndicatorIcon,.peopleIndicatorIcon").remove();

			return {
				innerSelection: innerSVG,
				selection: mapElement,
				viewBox: viewBox,
				icons: icons
			}
		}
		return {
			innerSelection: innerSVG,
			selection: mapElement,
			viewBox: viewBox,
			icons: []
		} 
	}
	else {
		return {
			innerSelection: innerSVG,
			selection: mapElement,
			viewBox: createClientRect(),
			icons: [],
		}
	}
}

/**
 * Extracts all marked icons from the map
 * @param mapElement The Selection of the floormap
 * @param innerRoot The InnerMost-SVG-Element containing the map 
 * @param viewBox  The viewBox of the map
 */
const extractRelativeIcons = (mapElement: SVGSelection, innerRoot: SVGSVGElement, viewBox: ClientRect): GroupedSVGElement[] => {
		//Select all Icons in SVG
		const svgIcons = mapElement.selectAll<SVGGElement, any>("g.icon");
		const rootMatrix = innerRoot.getCTM();
	
		//Calculate
		const iconGroups = svgIcons.nodes().map((node: SVGGElement): GroupedSVGElement => {
			const iconElements: TransformedSVGElement[] = []

			const rootBox = innerRoot.getBoundingClientRect();
			const innerNodeBox = node.getBoundingClientRect();
			//const nodeOrigin = new DOMPoint(innerNodeBox.x/viewBox.width*100, innerNodeBox.y/viewBox.height*100, 0, 1);

			if (rootMatrix) {
				const paths: TransformedSVGElement[] = extractAllPaths(node, []).map<TransformedSVGElement>(x => {
					const trMatrix = rootMatrix.inverse().multiply(x.getCTM() as DOMMatrixInit);

					return {
						element: x, 
						transformMatrix: trMatrix,
						//oldRectangle: x.getBoundingClientRect()
					}
				});
				const rects = extractRectElements(node).map<TransformedSVGElement>(x => {
					const trMatrix = rootMatrix.inverse().multiply(x.getCTM() as DOMMatrixInit);
					return {
						element: x, 
						transformMatrix: trMatrix,
						//oldRectangle: x.getBoundingClientRect()
					}
				});
	
				iconElements.push(...rects, ...paths);
			}
			
			//const maxRect = calculateMaxRect(iconElements);
			const newOrigin = new DOMPoint((innerNodeBox.left)/rootBox.width*100, (innerNodeBox.top)/rootBox.height*100, 0 ,1)

			return {
				location: newOrigin,
				elements: iconElements,
				viewBox: innerNodeBox,
				outerViewBox: viewBox,
			}

		});
	
		return iconGroups;
}

/**
 * Returns the attribute or a specified default value from an Element
 * @param selectedElement The Element
 * @param attributeName The name of the attribute
 * @param defaultValue  The default value
 */
function getAttributeOrDefault<T extends string | undefined>(selectedElement: SVGElement, attributeName: string, defaultValue: T): string | T {
	const attribute = selectedElement.getAttribute(attributeName);
	return attribute || defaultValue;
}

/**
 * The component containing all overlays for the SVG-File
 */
const MapOverlay:React.FC<{pins:pin[], viewBox: ClientRect, icons: GroupedSVGElement[]}> = ({children, pins, viewBox, icons}) => {

	return (
		<>
			{pins.map((p,x) => {
				return	<ModernPin pin={p} key={x}/>
			})}
			<Icons icons={icons}/>
		</>
	)
}

/**
 * The Component Wrapping all overlayed Icons
 * @param icons: The Icons to overlay
 */
const Icons:React.FC<{icons: GroupedSVGElement[]}> = ({children, icons}) => {

	return <>
		{icons.map((icon,index) => {
			return <Icon icon={icon} key={index}/>
		})}
	</>
	

}

/**
 * Component of a single overlayed Icon
 * @param icon The Icon to overlay
 */
const Icon:React.FC<{icon: GroupedSVGElement}> = ({children, icon}) => {
	const [transformOrigin, setTransformOrigin] = React.useState<ClientRect>(createClientRect(0, 0, 16, 16));
	const groupElement = React.useCallback((node: SVGGElement) => {
		if (node) {
			const box = node.getBBox();
			const outerBox = icon.outerViewBox as DOMRect;
			const pos = createClientRect(box.x + box.width - icon.viewBox.width, box.y + box.height - icon.viewBox.height, ((box.x-outerBox.x)/icon.outerViewBox.width)*100, ((box.y-outerBox.y)/icon.outerViewBox.height)*100);
			//console.log({outerBox: icon.outerViewBox, innerViewBox: icon.viewBox, box: box, pos: pos});
			setTransformOrigin(pos)
		}
	}, [setTransformOrigin, icon]);


	return (
		<div 
		className={`${style.iconWrapper} ${style.unRotated}`} 
		style={{ 
			top: `${transformOrigin.height}%`, 
			left: `${transformOrigin.width}%`, 
			width: `${icon.viewBox.width/icon.outerViewBox.width*100}%`, 
			height: `${icon.viewBox.height/icon.outerViewBox.height*100}%`
			}}
		>
			<svg 
			className={style.icon} 
			preserveAspectRatio="xMinYMid meet" 
			width={icon.viewBox.width} height={icon.viewBox.height} 
			viewBox={`${transformOrigin.left} ${transformOrigin.top} ${icon.viewBox.width} ${icon.viewBox.height}`}>
				{/*The IconGroup used to be here! */}
				<g ref={groupElement}>
					{icon.elements.map((iconPart, partIndex) => <IconPartWrapper icon={iconPart} key={partIndex}/>)}
				</g>
			</svg>
		</div>
	);
}

/**
 * Wraps the contents of an overlayed Icon
 */
const IconPartWrapper:React.FC<{icon: TransformedSVGElement}> = ({children, icon}) => {
	
	const transform = `matrix(${icon.transformMatrix.a}, ${icon.transformMatrix.b}, ${icon.transformMatrix.c}, ${icon.transformMatrix.d}, ${icon.transformMatrix.e}, ${icon.transformMatrix.f})`

	if (icon.element instanceof SVGPathElement) {
		return <PathIcon icon={icon} transformStr={transform}/>
	}

	if (icon.element instanceof SVGRectElement) {
		return <RectangleIcon icon={icon} transformStr={transform}/>
	}
	
	return <></>

}

/**
 * Converts a CSS-style to a React-Style-Object
 * @param domStyle The Style to convert
 */
function convertToReactStyle(domStyle: CSSStyleDeclaration) :React.CSSProperties {
	
	let loop: boolean = true;
	let propCount = 0;
	let props: React.CSSProperties = {};
	while(loop) {
		const propName: string|undefined = domStyle[propCount];
		if (propName) {
			
			const prop:string = domStyle[propName]
			props = {
				...props,
				[propName]: prop
			}

			propCount++;
		}

		else {
			loop = false;
		}
	}
	return props;
}

/**
 * On overlayed Path
 * @param icon: The content of the path
 * @param tranformStr: The Matrix-Transformation to apply
 */
const PathIcon:React.FC<{icon: TransformedSVGElement, transformStr: string}> = ({children, icon, transformStr}) => {
	const d = getAttributeOrDefault(icon.element, "d", undefined);
	const style = convertToReactStyle(icon.element.style);
	const fill = `${getAttributeOrDefault(icon.element, "fill", "transparent")}`;
	

	return <path d={d} style={style} fill={fill} transform={transformStr}/>
}

/**
 * On overlayed Rectangle
 * @param icon: The Data of the rectangle
 * @param tranformStr: The Matrix-Transformation to apply
 */
const RectangleIcon:React.FC<{icon: TransformedSVGElement, transformStr: string}> = ({children, icon, transformStr}) => {
	const x = `${icon.element.getAttribute("x")}`;
	const width = `${icon.element.getAttribute("width")}`;
	const y = `${icon.element.getAttribute("y")}`;
	const height = `${icon.element.getAttribute("height")}`;
	const rx = `${getAttributeOrDefault(icon.element, "rx", "0")}`;
	const ry = `${getAttributeOrDefault(icon.element, "ry", "0")}`;
	const style = convertToReactStyle(icon.element.style);
	const fill = `${getAttributeOrDefault(icon.element, "fill", "transparent")}`;

	return <rect x={x} y={y} width={width} height={height} rx={rx} ry={ry} fill={fill} style={style} transform={transformStr}/>

}

/**
 * The Component of an overlayed pin
 * @param pin: The pin to render
 */
const ModernPin:React.FC<{pin:pin}> = ({children, pin}) => {
	const [textSize, setTextSize] = React.useState<ClientRect>(createClientRect());
	const [iconSize, setIconSize] = React.useState<ClientRect>(createClientRect());
	const peopleIcon = '\uE77B';

	const getDigitClass = (count: number):string => count < 1 ? "" : count < 10 ? style.singleDigit : style.doubleDigit;

	return (
		<svg 
		style={{left:pin.x  + "%", top: pin.y + "%"}} 
		preserveAspectRatio="none" 
		viewBox="0 -3 498.923 498.923" 
		className={`${getClassName(pin)} ${style.pin}  ${style.unRotated}`}>
			<circle cx="250" cy="174" r="145" className={style.pinCircle}/>

			<text className={`${style.peopleLabel} ${getDigitClass(pin.pCount)}`} y="220" x="130">
				{pin.pCount < 1 ?
					<tspan className={`${style.peopleIcon} ${style.free}`}>
						{peopleIcon}
					</tspan>	
				:
					<>
						<tspan className={style.peopleCount}>
							{pin.pCount}
						</tspan>
						<tspan className={`${style.peopleIcon} ${style.occupied}`}>
							{peopleIcon}
						</tspan>	
					</>
			}


			</text>

			{/**<text className="peopleCount" y="220" ref={labelTextSizeChanged} x={252-(textSize.width+iconSize.width)/2}>{pin.pCount > 0 ? pin.pCount : ""}</text>
			<text className={`peopleIcon ${pin.pCount > 0 ? "" : "free"}`} y="223" ref={labelIconSizeChanged} x={252-(-textSize.width + iconSize.width)/2}>{peopleIcon}</text>**/}
					
			<path
				className={style.pin}
				d="M249.462,0C151.018,0,70.951,80.106,70.951,178.511c0,92.436,133.617,192.453,172.248,315.948
					c0.83,2.667,3.322,4.484,6.116,4.465c2.804-0.039,5.256-1.876,6.048-4.563c37.478-126.533,172.6-223.307,172.609-315.869
					C427.963,80.106,347.886,0,249.462,0z M249.462,313.925c-77.184,0-139.987-62.812-139.987-139.987
					c0-77.184,62.803-139.987,139.987-139.987c77.165,0,139.977,62.803,139.977,139.987
					C389.439,251.113,326.626,313.925,249.462,313.925z"
			/>
		</svg>
	)
}

/**
 * Recursively extracts all Path-Element inside of a group
 * @param groupNode The containing group
 * @param paths The List of paths to append new Paths to
 */
function extractAllPaths(groupNode: SVGGElement, paths: SVGPathElement[]): SVGPathElement[] {
	const newPaths: SVGPathElement[] = [];
	groupNode.childNodes.forEach(childNode => {
		if (childNode instanceof SVGPathElement) {
			newPaths.push(childNode);
		}

		if (childNode instanceof SVGGElement) {
			newPaths.push(...extractAllPaths(childNode, paths));
		}

	})

	return newPaths;
}

/**
 * Extracts all Rectangles from an Element
 * @param parentNode The Parent-Element
 */
function extractRectElements(parentNode: SVGElement): SVGRectElement[] {
	return extractAllElements<SVGRectElement>(parentNode, "rect")
}

/**
 * Extracts a certain type of Element from a parent
 * @param groupNode The Parent
 * @param typeName The type of element to exptract
 */
function extractAllElements<T extends SVGElement>(groupNode: SVGElement, typeName: string): T[] {
	const newElements: T[] = [];
	const elements = d3.select(groupNode).selectAll<T, any>(typeName).nodes();

	return elements;
}

const extractRelativePathCenter = (roomPath: SVGPathElement, viewBox: ClientRect | ClientRect, relativeNode: SVGGraphicsElement): DOMPoint => {
	const box = roomPath.getBBox();

	if (box) {
		const transformMatrix = calculateRelativeTransformation(roomPath, relativeNode);
	
		const topLeft = applyTransformationToPoint(new DOMPoint(box.x, box.y), transformMatrix);
		//const topRight = applyTransformationToPoint(new DOMPoint(box.x + box.width, box.y, 0 , 1), transformMatrix);
		//const bottomLeft = applyTransformationToPoint(new DOMPoint(box.x, box.height + size.y, 0, 1), transformMatrix);
		const bottomRight = applyTransformationToPoint(new DOMPoint(box.x + box.width, box.y + box.height, 0 , 1), transformMatrix);

		const center: DOMPoint = new DOMPointReadOnly(
			(topLeft.x + bottomRight.x) /2,
			(topLeft.y + bottomRight.y) /2,
			0,
			1
		);
	
		const relativeCenter = new DOMPointReadOnly(
			100*center.x / viewBox.width,
			100*center.y / viewBox.height,
			0,
			1
		);
	
		return relativeCenter;

	}

	throw Error(`Couldn't calculate Bounding Box of ${roomPath.id}`)


}

/**
 * Calculated the center of a rectangle relative to its SVGElement-Parent
 * @param roomRect The Rectangle
 * @param viewBox The ViewBox of the Parent
 * @param relativeNode The Node of the Parent
 */
const extractRelativeRectCenter = (roomRect: SVGRectElement, viewBox: DOMRect, relativeNode: SVGGraphicsElement): DOMPoint => {
	const absoluteTopLeft: DOMPoint = new DOMPoint(roomRect.x.animVal.value - viewBox.x, roomRect.y.animVal.value - viewBox.y);
	const size: DOMPoint = new DOMPoint(roomRect.width.animVal.value, roomRect.height.animVal.value);
	const transformMatrix = calculateRelativeTransformation(roomRect, relativeNode);

	const topLeft = applyTransformationToPoint(absoluteTopLeft, transformMatrix);
	//const topRight = applyTransformationToPoint(new DOMPoint(absoluteTopLeft.x + size.x - viewBox.left, absoluteTopLeft.y - viewBox.top, 0 , 1), transformMatrix);
	//const bottomLeft = applyTransformationToPoint(new DOMPoint(absoluteTopLeft.x - viewBox.left, absoluteTopLeft.y + size.y - viewBox.top, 0, 1), transformMatrix);
	const bottomRight = applyTransformationToPoint(new DOMPoint(absoluteTopLeft.x + size.x, absoluteTopLeft.y + size.y, 0 , 1), transformMatrix);

	const center: DOMPoint = new DOMPointReadOnly(
		(topLeft.x + bottomRight.x) /2,
		(topLeft.y + bottomRight.y) /2,
		0,
		1
	);

	const relativeCenter = new DOMPointReadOnly(
		100*(center.x) / viewBox.width,
		100*(center.y) / viewBox.height,
		0,
		1
	);

	return relativeCenter;

}

/**
 * Calculated all Transformations applied between too Elements
 * @param innerElement The Inner Element
 * @param outerElement The Outer Element
 */
const calculateRelativeTransformation = (innerElement: SVGGraphicsElement, outerElement: SVGGraphicsElement ): DOMMatrix => {
	const innerMatrix = innerElement.getCTM();
	const outerMatrix = outerElement.getCTM();

	if (innerMatrix && outerMatrix) {
		return outerMatrix.inverse().multiply(innerMatrix);
	}

	throw new Error("Coudn't calculate global transformation!");
}

/**
 * Applies a Matrix-Transformation to a DOM-Pount
 * @param point The Point to transform
 * @param transformMatrix The transformation-matrix
 */
const applyTransformationToPoint = (point: DOMPoint, transformMatrix: DOMMatrix): DOMPointReadOnly => {
	const newPoint = new DOMPointReadOnly (
		point.x * transformMatrix.a + point.y * transformMatrix.b + point.w * transformMatrix.e,
		point.x * transformMatrix.c + point.y * transformMatrix.d + point.w * transformMatrix.f,
		0,
		point.w
	);

	return newPoint;
}

const forbiddenClassChars: RegExp = /(^[^a-zA-Z]+|\.|\*|\^|\,|\#|\[|\]|\(|\)|\$|\&|\%|\:|\<|\>|\~|\@|\\|\/|\{|\}|\+|\;|\')+/gm;

/**
 * Escapes the classes and Ids of an SVG-String of certrain DOM-Keys.
 * @param input The String to escape
 */
const escape = (input: string): string => {
	
	return input
		//.replace(/@/g, "")
		//.replace(/\.com/g, "")
		//.replace(/\.Ha/g, "Ha")
		//.replace(/(id|class)=\s*".*"/gm, (className:string) => className.replace(forbiddenClassChars, ""));
		.replace(/(id|class)=\s*"([^"]+)"/gm, (total:string, attributeTypeName:string, attributeContent:string) => `${attributeTypeName}="${escapeWithoutWhiteSpace(attributeContent)}"`);
}

const escapeWithoutWhiteSpace = (input: string): string => {
	return input.replace(/\S+/gm, (val) => CSS.escape(val));
}




/**
 * Escapes an SVG-String of certain DOM-Keys
 * @param input The string to escape.
 */
const escapeCSSIdentifiersFromId = (input: string): string => {
	return CSS.escape(CSS.escape(input));
	//return input
		//.replace(/\.com/g, "")
		//.replace(/\.Ha/g, "Ha")
		//.replace(forbiddenClassChars, "")

}

const createClientRect = (x: number = 0, y: number = 0, width:number = 0, height:number = 0):ClientRect => {
	return {
		left: x,
		top: y,
		width: width,
		height: height,
		right: x + width,
		bottom: y + height
	}
}

interface pin extends MapOverlayObject {
	state:number;
	pCount: number;
}

type SVGSelection = d3.Selection<SVGSVGElement, any, any, any>;

interface RenderedFloorPlan {
	selection: SVGSelection;
	innerSelection: SVGSelection;	
	viewBox: ClientRect;
	icons: GroupedSVGElement[];
}

interface MapOverlayObject {
	x: number;
	y: number;

}

export interface FloorplanProps {
    floorPlan: string;
    flexDesks: FlexDesk[];
    rooms: areawrapper[];
	view: string;
	currentAreaId?: string;
}

export type areawrapper = AreaState & WithMeetings

export type WithMeetings = {
	meetings: Meeting[];
}

export type FlexDesk = Readonly<{
    areaId: string;
    state: number
}>

interface GroupedSVGElement {
	elements: TransformedSVGElement[];
	location: DOMPoint;
	viewBox: ClientRect;
	outerViewBox: ClientRect;
}

interface TransformedSVGElement {
	element: SVGElement;
	transformMatrix: DOMMatrix;
}

interface SVGRenderedRect {
	renderedX: number;
	renderedY: number;
	renderedWidth: number;
	renderedHeight: number;
} 

const getClassName = (pin:pin): string => {
	switch (pin.state) {
		case 0: return style.free;
		case 1: return style.pending;
		case 2: return style.occupied;
		default: return "";
	}
}

interface Window {
	openRoomPanel: WebCommunicator;
	statusLoaded: WebCommunicator;
}

interface WebCommunicator {
	notify: (message: string) => void;
}