import * as React from "react";
import { ListItem } from "../../data/listItem/model";
import { CONFIG } from "../../../config/settings"
import * as css from '../App.scss';
import { MeetingIcon, ProjectSpaceIcon, FlexIcon, HelpIcon } from "../Icons";
import i18n from "../../i18n";
const meetingroomIcon = CONFIG.icons.meetingroomIcon;
const flexDeskIcon = CONFIG.icons.flexDeskIcon;
const projectSpaceIcon = CONFIG.icons.projectSpaceIcon;
const weatherIcon = CONFIG.icons.weatherIcon;
const meetingroomIconOccupied = CONFIG.icons.meetingroomIconOccupied;
const flexDeskIconOccupied = CONFIG.icons.flexDeskIconOccupied;
const projectSpaceIconOccupied = CONFIG.icons.projectSpaceIconOccupied;


export interface HeaderProps {
    item?: ListItem;
    title?: string;
}

export class OverviewHeader extends React.Component<HeaderProps> {
    constructor(props: HeaderProps) {
        super(props);
    }

    renderInfo(icon: string, text: string, total: number, available?: number, tooltip?: string) {
        var iconElement:JSX.Element;
        switch (icon) {
            case "MeetingIcon": iconElement = <MeetingIcon className={css.overviewIcon} />; break;
            case "ProjectSpaceIcon": iconElement = <ProjectSpaceIcon className={css.overviewIcon} />; break;
            case "FlexIcon": iconElement = <FlexIcon className={css.overviewIcon} />; break;
            default: iconElement = <HelpIcon className={css.overviewIcon} />; break;
        }
        return (
            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl3" style={{ marginBottom: 2, paddingLeft: 0 }}>
                {iconElement}
                {/* <img src={icon} width="40" className={css.overviewComponentImg} style={{ verticalAlign: "middle", marginRight: 5 }} /> */}
                <div className={css.overviewIconTextContainer}>
                    <span style={{ verticalAlign: "middle" }}>
                        <span className={css.numavailable}>{(available != undefined) && Math.round(available)}</span> | {Math.round(total)}
                        <span className={css.overviewComponentText}>&nbsp;{text}</span>
                    </span>
                </div>
            </div>
        )
    }

    render() {
        const { item, title } = this.props;

        return (
            <div>
                {!!title &&
                    <div className={css.marginTopBottom}>
                        <span className={`${css.textBold} ${css.textSizeHigher}`}>{title}</span>
                    </div>
                }
                {item &&
                    <div className={`ms-fontSize-l ms-fontWeight-regular ${css.overviewComponent}`} >
                        {!!item.totalMeetingRooms &&
                            <div>
                                {
                                    //TODO: item.availableMeetingRooms < 1 ? +"Occupied"
                                    this.renderInfo("MeetingIcon", i18n.t("meetingrooms"), item.totalMeetingRooms, item.availableMeetingRooms)
                                }
                            </div>
                        }
                        {!!item.totalProjectSpaces &&
                            <div>
                                {
                                    //TODO: item.availableProjectSpaces < 1 ? +"Occupied"
                                    this.renderInfo("ProjectSpaceIcon", i18n.t("projectSpaces"), item.totalProjectSpaces, item.availableProjectSpaces)
                                }
                            </div>
                        }
                        {!!item.totalFlexdesks &&
                            <div>
                                {
                                    //TODO: item.availableFlexdesks < 1 ? +"Occupied"
                                    this.renderInfo("FlexIcon", i18n.t("flexdesks"), item.totalFlexdesks, item.availableFlexdesks)
                                }
                            </div>
                        }
                        {!!item.weatherData &&
                            <div className="ms-Grid-col ms-hiddenLgDown ms-md3">
                                <img src={String(weatherIcon)} width="40" style={{ verticalAlign: "middle", marginRight: 5 }} />
                                <span style={{ verticalAlign: "middle" }}>{item.weatherData}</span>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}