import * as React from 'react';
import { StateWithData } from './app/reducers';
import { connect } from 'react-redux';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import { bindActionCreators, Dispatch} from 'redux';
import ErrorAction, { dismissError } from '../utils/ErrorAction';
import { Layer } from 'office-ui-fabric-react/lib/Layer';
import * as css from "../components/App.scss";


class ErrorBar extends React.Component<StateProps & DispatchProps>{

    constructor(props) {
        super(props);
    }

    public render() {
        const { error, onErrorDismiss } = this.props;

        return (
            !!error &&
            <Layer>
                <div className={css.errorBar}>
                    <ActionButton
                        data-automation-id="test"
                        iconProps={{ iconName: 'ChromeClose' }}
                        onClick={() => { onErrorDismiss() }}
                    />
                    <em>{error.message}</em> ({error.type})
                </div>
            </Layer>
        )
    }
}

type StateProps = Readonly<{
    error?: ErrorAction;
}>;

type DispatchProps = Readonly<{
    onErrorDismiss: typeof dismissError;
}>;

function mapStateToProps({ data: { error } }: StateWithData): StateProps {
    return {
        error
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
    return bindActionCreators({
        onErrorDismiss: dismissError,
    }, dispatch);
}

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(ErrorBar);
