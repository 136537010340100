import * as React from "react";
import { StateWithData } from "../app/reducers";
import { navigate, navigateToFloorDetails } from "../navigation/actions";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { autobind } from "@uifabric/utilities";
import { ListItem } from "../../data/listItem/model";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import * as css from "./floors.module.scss";
import { OverviewHeader } from "../overviewComponents/header";
import { ListGrid } from "../basicList/list";
import * as appCSS from "../App.scss";
import i18n from "../../i18n";
import { currentLocationSelector, floorsSelector, loadingSelector, loadingItemsSelector } from "../../store/selectors";
import { AreaIdentifier, AreaState } from "../../data/areas/models";

class FloorOverview extends React.Component<StateProps & DispatchProps> {

    constructor(props) {
        super(props);
    }

    @autobind
    _onHandleClick(item: AreaIdentifier) {
        this.props.onOpenItem(navigateToFloorDetails(item))
    }

    render() {
        return (
                <div>
                    <div className={`ms-Grid ${css.marginTopBottom}`}>
                        <OverviewHeader item={this.props.location} />
                    </div>
                    <ListGrid loading={this.props.loadingItems} onOpenItem={this._onHandleClick} items={this.props.floors} />
                </div>
        )
    }
}

type StateProps = Readonly<{
    floors: AreaState[];
    location: AreaState;
    loading: boolean;
    loadingItems: boolean;
}>;

type DispatchProps = Readonly<{
    onOpenItem: typeof navigate;
}>;

function mapStateToProps(state: StateWithData): StateProps {
    return {
        floors: floorsSelector(state),
        location: currentLocationSelector(state),
        loading: loadingSelector(state),
        loadingItems: loadingItemsSelector(state),
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
    return bindActionCreators({
        onOpenItem: navigate
    }, dispatch);
}

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(FloorOverview);
