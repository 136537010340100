import * as style from "./floormap.less"
import * as React from "react";
import { AreaAvailabilityStatus, AreaIdentifier, AreaState } from "../../data/areas/models";
import { StateWithData } from "../app/reducers";
import Floorplan2, {FloorplanProps, FlexDesk, areawrapper} from "./floormap"
import { connect } from "react-redux";
import { CONFIG } from "../../../config/settings";
import { currentFloorIdSelector, filteredRoomSelector, flexdeskSelector, floorMapSelector, floorMapsSelector, meetingsSelector, roomsSelector } from "../../store/selectors";
import { Meeting } from "../../data/meeting/models";

interface FloormapWrapperProps {
	floorPlan: string,
	rooms: AreaState[],
	flexDesks: AreaState[],
	currentAreaId: AreaIdentifier;
	view: string;
	state?;
	ownProps?;
	meetings: Meeting[];
}

const mapStateToProps = (state: StateWithData):FloormapWrapperProps => {
	return {
		flexDesks: flexdeskSelector(state),
		rooms: filteredRoomSelector(state),
		currentAreaId: currentFloorIdSelector(state),
		floorPlan: floorMapSelector(state).map,
		view: CONFIG.floorMapRendering.view,
		meetings: meetingsSelector(state)
	}

}

const FloormapWrapper:React.FC<FloormapWrapperProps> = ({children, floorPlan, flexDesks, rooms, view, currentAreaId, meetings}) => {
	const mappedRooms = React.useMemo<areawrapper[]>(() => rooms.map<areawrapper>((room) => {
		return {
			...room,
			meetings: meetings.filter(m => m.areaId == room.identifier.id)
		}
	}), [rooms, meetings])
	
	const simpleFlexdesks = React.useMemo<FlexDesk[]>(() => flexDesks.map<FlexDesk>((desk) :FlexDesk => {
		return {
			areaId: desk.identifier.uri,
			state: availabilityToNum(desk.availability)
		}
	}), [flexDesks])

	//Takes colors from config and applies them as CSS-Variables. TODO: Seperate theme and content-config!
	React.useEffect(() => {
		document.documentElement.style.setProperty(`--occupied-color`, CONFIG.floorMapRendering.deskOccupiedColor);
		document.documentElement.style.setProperty(`--available-color`, CONFIG.floorMapRendering.deskFreeColor);
		document.documentElement.style.setProperty(`--cs-blue`, CONFIG.palette.themePrimary);
		document.documentElement.style.setProperty(`--cs-orange`, CONFIG.floorMapRendering.deskMaybeOccupiedColor);

	}, [])

	return (
		<div id={style.floormapWrapper}>
			<Floorplan2 flexDesks={simpleFlexdesks} floorPlan={floorPlan} rooms={mappedRooms} view={view} currentAreaId={currentAreaId.uri}/>
		</div>
	);
}

const availabilityToNum = (availability: AreaAvailabilityStatus): number => {
	let num: number;
	switch(availability) {
		case AreaAvailabilityStatus.Available:
			num = 0
			break;
		case AreaAvailabilityStatus.Occupied:
			num = 2;
			break;
		case AreaAvailabilityStatus.Pending:
			num = 1;
			break;
		case AreaAvailabilityStatus.NoDataAvailable:
			num = 3;
			break;
	}
	return num;
}

const mapDispatchToProps = () => {
	return {}
}

export default connect<FloormapWrapperProps, void>(mapStateToProps, mapDispatchToProps)(FloormapWrapper);