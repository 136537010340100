import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { FETCH_FLOORS, fetchFloormapSuccess, FETCH_FLOORMAP, FetchFloormapAction, fetchFloormapError, fetchFloormap, FETCH_FLOOR_STATISTIC, FETCH_AREA_STATISTIC, FetchAreaStatisticAction, fetchAreaStatistic, fetchAreaStatisticSuccess, fetchAreaStatisticError } from "../actions/floorActions";
import { Floor } from "../../data/floors/models";
// import { fetchLocation, fetchLocationSuccess, fetchParentLocation } from "../actions/locationActions";
import { getFloors, getFloor, getFloormap, getFloorStatistic, getAreaStatistic, getHierarchialFloors } from "../../data/floors/floors.api";
import { fetchAreas, fetchFlexDesks } from "../actions/areaActions/areaActions";
import { ensureAuthenticationSaga } from "./authenticationSaga";

// function* handleFetchFloors({ locationUri: locationId }: FetchFloorsAction) {
//     try {
// 		yield ensureAuthenticationSaga();

// 		const {floors, parent } = yield call(getHierarchialFloors, locationId);

// 		yield put(fet(parent));
//         yield put(fetchFloorsSuccess(floors));
//     } catch (e) {
//         yield put(fetchFloorsError(e));
//     }
// }

// function* handleFetchFloor({ floorId, withoutAreas }: FetchFloorAction) {
//     try {
// 		yield ensureAuthenticationSaga();
// 		let effects: Effect[] = [call(getFloor, floorId), put(fetchParentLocation(floorId))]
// 		if (!withoutAreas) effects.push(put(fetchAreas(floorId)))
		
// 		const [floor, put1, put2] = yield(all(effects));

//         yield put(fetchFloorSuccess(floor));
//     } catch (e) {
//         yield put(fetchFloorError(e));
//     }
// }

function* handleFetchFloormap({ floorId }: FetchFloormapAction) {
    try {
		yield ensureAuthenticationSaga();
        var floorMap = yield call(getFloormap, floorId);

        //yield put(fetchFlexDesks(floorId));

        yield put(fetchFloormapSuccess(floorId, floorMap));
    } catch (e) {        
        const tempFLoormap = '<svg height="30" width="200" id="Floormap_error" viewBox="0 0 200 30"><text x="0" y="15" fill="white">Floormap could not be loaded.</text></svg>';
        yield put(fetchFloormapSuccess(floorId, tempFLoormap));
        // yield put(fetchFloormapError(e, floorId)); // TODO: replace with correct error
    }
}

// function* handleFetchFloorStatistic({ floorId }: FetchFloorStatisticAction) {
//     try {
// 		yield ensureAuthenticationSaga();
//         const floorStatistic = yield call(getFloorStatistic, floorId);

//         yield put(fetchAreaStatistic(floorId));

//         yield put(fetchFloorStatisticSuccess(floorId, floorStatistic));
//     } catch (e) {
//         yield put(fetchFloorStatisticError(e));
//     }
// }

function* handleFetchAreaStatistic({ floorId }: FetchAreaStatisticAction) {
    try {
		yield ensureAuthenticationSaga();
        const areaStatistic = yield call(getAreaStatistic, floorId);

        yield put(fetchAreaStatisticSuccess(areaStatistic, floorId));
    } catch (e) {
        yield put(fetchAreaStatisticError(e));
    }
}


export default function* floorsSaga(): IterableIterator<Effect> {
    yield all([
        // takeLatest(FETCH_FLOORS, handleFetchFloors),
        // takeLatest(FETCH_FLOOR, handleFetchFloor),
        takeLatest(FETCH_FLOORMAP, handleFetchFloormap),
        // takeLatest(FETCH_FLOOR_STATISTIC, handleFetchFloorStatistic),
        takeLatest(FETCH_AREA_STATISTIC, handleFetchAreaStatistic)
    ]);
}
