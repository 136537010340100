import { AreaIdentifier } from "../areas/models";

export interface ListItem {
	identifier: AreaIdentifier;
	//id: number;
	//uri: string;
    title?: string;
    image?: string;
    description?: string;
    charts?: { Title: string, ChartType: string, Data: any[] }[];
    type: AreaTypeEnum;
    temperature?: number;   // degree celsius
    humidity?: number;      // percentage
    brightness?: number;    // percentage
    pressure?: number;      // hPa
    meetingAttendance?: number;
    
    totalMeetingRooms?: number;
    availableMeetingRooms?: number;
    
    totalFlexdesks?: number;
    availableFlexdesks?: number;
    
    totalProjectSpaces?: number;
    availableProjectSpaces?: number;

    isCurrentlyOccupied?: boolean;
    isCurrentlyBooked?: boolean;

    totalReservatedCapacity?: number;
    availableReservatedCapacity?: number;

    weatherData?: string;
}

export interface ParentItem {
	type: AreaTypeEnum;
    id: number;
    upn?: string;
    uri?: string;
    title?: string;
}

export enum AreaTypeEnum
{
    MeetingRoom = 1,        // Bookable meeting room
    Desk = 2,               // not a room, just a desk in a room
    ProjectSpace = 3,       // a room that is bookable as project space
    ServerRoom = 4,         // a server Room
    Space = 5,              // Open Space or just rooms with desks which are not bookable
	RoomZone = 6,            // Generic Zone within a room, e.g. for tracking whiteboard / beamer usage
	Location = 7,
	Building = 8,
	Level = 9,
	DeskCluster = 10,
	Other = 99,
}
