import { Area, AreaFilter } from "../data/areas/models";
import { createSelector } from "reselect";
import { StateWithData, StateWithLogon } from "../components/app/reducers";
import { AreaTypeEnum } from "../data/listItem/model";
import { areadIdEquals } from "../utils/commonFunctions";

export function getAreaFromState(areas, areaId: string): Area {
    if (areas) {
        return areas.find(a => a.areaId == areaId);
    }
    return undefined;
}


export const smartOfficeStateSelector = (state: StateWithData) => state.data;

export const logonSelector = (state: StateWithLogon) => state.loginState;

// export const roomsSelector = createSelector(
//     smartOfficeStateSelector,
//     ({ rooms }) => rooms
// )

export const currentAreaIdSelector = createSelector(
	smartOfficeStateSelector,
	state => state.currentArea
)



export const areasSelector = createSelector(
	smartOfficeStateSelector,
	state => state.areas,
)

export const currentAreaSelector = createSelector(
	[currentAreaIdSelector, areasSelector],
    (current, areas) => areas.find(a => areadIdEquals(current, a.identifier))
)

export const locationsSelector = createSelector(
	areasSelector,
	areas => areas.filter(a => a.type == AreaTypeEnum.Location)
)

export const currentLocationIdSelector = createSelector(
	[currentAreaSelector, areasSelector],
	(currentArea, areas) => {
		let area = areas.find(a => a.locationId === currentArea?.identifier?.id && currentArea && a.type === AreaTypeEnum.Location)?.identifier
		if (!area && currentArea && currentArea.locationId) {
			area = {
				id: currentArea.locationId
			}
		}
		return area;
	}
)

export const currentLocationSelector = createSelector(
	[currentLocationIdSelector, areasSelector],
	(id, areas) => areas.find(a => areadIdEquals(a.identifier, id))
)

export const floorsSelector = createSelector(
	[areasSelector, currentLocationIdSelector],
	(areas, location) => areas.filter(a => a.type === AreaTypeEnum.Level && a.locationId == location?.id)
)

export const currentFloorIdSelector = createSelector(
	[currentAreaSelector, areasSelector],
	(currentArea, areas) => {
		let area = areas.find(a => a.floorId === currentArea?.identifier?.id && currentArea && a.type === AreaTypeEnum.Level)?.identifier
		if (!area && currentArea && currentArea.floorId) {
			area = {
				id: currentArea.floorId
			}
		}
		return area;
	}
)
export const currentFloorSelector = createSelector(
	[currentFloorIdSelector, areasSelector],
	(id, areas) => areas.find(a => areadIdEquals(a.identifier, id))
)

export const areaStatisticSelector = createSelector(
	[smartOfficeStateSelector], state => state.areaStats
)

export const floorStatisticSelector = createSelector(
	[currentFloorIdSelector, areaStatisticSelector],
	(id, stats) => stats.find(stat => id.id == stat.areaId)
)

export const floorMapsSelector = createSelector(
	smartOfficeStateSelector, state => state.floorMaps
)

export const floorMapSelector = createSelector(
	[currentFloorIdSelector, floorMapsSelector], (id, maps) => maps.find(map => areadIdEquals(map.areaId, id))
)

export const roomsSelector = createSelector(
	[areasSelector, currentFloorSelector],
	(areas, floor) => areas.filter(a => (a.type === AreaTypeEnum.MeetingRoom || a.type === AreaTypeEnum.ProjectSpace) && a.parentIds.some(id => floor?.identifier?.id == id))
)

export const currentRoomIdSelector = createSelector(
	[currentAreaSelector],
	(currentArea) => {
		if (currentArea?.type === AreaTypeEnum.MeetingRoom || currentArea?.type === AreaTypeEnum.ProjectSpace) {
			return currentArea?.identifier
		}
		else return undefined;
	}
)

export const currentRoomSelector = createSelector(
	[currentRoomIdSelector, areasSelector],
	(id, rooms) => rooms.find(r => areadIdEquals(id, r.identifier))
)

export const roomStatisticSelector = createSelector(
	[currentRoomIdSelector, areaStatisticSelector],
	(id, stats) => stats.find(stat => id.id == stat.areaId)
)

export const flexdeskSelector = createSelector(
	[areasSelector, currentFloorSelector],
	(areas, floor) => areas.filter(a => (a.type === AreaTypeEnum.Desk) && a.parentIds.some(id => floor?.identifier?.id == id))
)

export const meetingsSelector = createSelector(
	smartOfficeStateSelector, state => state.meetings
)

export const loadingMeetingsSelector = createSelector(
	smartOfficeStateSelector, state => state.loadingMeetings
)

export const loadingSelector = createSelector(
	smartOfficeStateSelector, state => state.loadingItems
)

export const meetingSelector = createSelector(
	[currentAreaIdSelector, meetingsSelector], 
	(area, meetings) => meetings.filter(m => {
		if(area?.id === m.areaId) {
			// console.log(`meeting "${m.title}" in area ${area.id} will be displayed as it equals to meetingArea ${m.areaId}`)
			return true;
		}
		// console.log(`meeting "${m.title}" in area ${area.id}: False! ${m.areaId}`)
		return false
	} )
)

export const areaIdSelectionWrapper = (_, id: Number) => id;

export const selectMeetingByAreaId = createSelector(
	[meetingsSelector, areaIdSelectionWrapper], (meetings, id) => meetings.filter(m => m.areaId == id)
)

export const loadingItemsSelector = createSelector(
	smartOfficeStateSelector, state => state.loadingItems
)

export const filterSelector = createSelector(
    smartOfficeStateSelector,
    state => state.filter
)

export const filterNoMeetingroomsSelector = createSelector(
	filterSelector,
	filter => filter.some(f => f === AreaFilter.noMeetingrooms)
)

export const filterHasProjectorSelector = createSelector(
	filterSelector,
	filter => filter.some(f => f === AreaFilter.hasProjector)
)

export const filterNoProjectSpacesSelector = createSelector(
	filterSelector,
	filter => filter.some(f => f === AreaFilter.noProjectSpaces)
)

export const filterOnlyAvailableSelector = createSelector(
	filterSelector,
	filter => filter.some(f => f === AreaFilter.onlyAvailable)
)

export const filteredRoomSelector = createSelector(
    [roomsSelector, filterSelector],
    (rooms, filter) => {
		console.log("Filter called!")
        let areas = rooms;
        filter.forEach(element => {
			console.log("Applying Filter ", element)
            switch (element) {
                case AreaFilter.onlyAvailable:
                    areas = areas.filter(room => !room.isCurrentlyOccupied).filter(room => !room.isCurrentlyBooked)
                    break;
                case AreaFilter.noProjectSpaces:
                    areas = areas.filter(room => room.type != AreaTypeEnum.ProjectSpace)
                    break;
                case AreaFilter.noMeetingrooms:
                    areas = areas.filter(room => room.type != AreaTypeEnum.MeetingRoom)
                    break;
                case AreaFilter.hasProjector:
                    areas = areas.filter(room => room.hasBeamer || room.hasProjector)
                    break;
            }
        });
        return areas
    }
) 