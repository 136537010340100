import { AnyRoute, NOT_FOUND_ROUTE, HOME_ROUTE, DASHBOARD_ROUTE, LOCATIONS_ROUTE } from "../../navigation/actions";
import routesMap from "./routes-map";
import { routeTypeSelector } from "../../navigation/selectors";
import { createSelector } from "reselect";
import { smartOfficeStateSelector } from "../../../store/selectors";

export const routeComponentSelector =
    (route: AnyRoute) => routesMap[route];

export const currentRouteComponentSelector = createSelector(
    routeTypeSelector,
    routeComponentSelector
);

export const errorSelector = createSelector(
    smartOfficeStateSelector,
    ({ error }) => error
)
