import * as React from "react";
import { Spinner } from "office-ui-fabric-react/lib/Spinner";
import i18n from "../../i18n";


export class Home extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div>
                <h2>{i18n.t("AppName")}</h2>
                <Spinner />
            </div>
        )
    }
}