import * as React from "react";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { initializeIcons } from '@uifabric/icons';
import { loadTheme } from 'office-ui-fabric-react/lib/Styling';
import ErrorBar from "../../ErrorBar";
import { CONFIG } from "../../../../config/settings";
import * as css from '../../App.scss';
import * as overViewCSS from "../../floors/floors.module.scss"
import { Translation } from 'react-i18next';
import i18n from "../../../i18n";
import BreadcrumbFabric from "../../breadcrumb/BreadcrumbFabric";
import { LoginState } from "../reducers";
import {ProgressIndicator} from "office-ui-fabric-react/lib/ProgressIndicator";
import { AreaIdentifier } from "../../../data/areas/models";

loadTheme({
    palette: CONFIG.palette
});

initializeIcons();

export type AppState = Readonly<{
    children: any;
    authenticationSuccess: boolean;
    teamsContext: boolean;
}>;


export type AppProps = Readonly<{
    fetchLocations?(id: AreaIdentifier): void;
	dismissError?(): void;
	requestLogin(): void;
	logonState: LoginState;
    error?: any;
}>;
class App extends React.Component<AppProps, AppState>{

    constructor(props) {
        super(props);
        this.state = {
            children: this.props.children,
            authenticationSuccess: false,
            teamsContext: false,
		};
		
        //this.handleLogin = this.handleLogin.bind(this);
        this.handleFetchLocations = this.handleFetchLocations.bind(this);
    }

    componentDidMount() {
		console.log("Root Component rendered!")
		//this.props.requestLogin();
	}

    handleFetchLocations = () => {
        this.props.fetchLocations({});
    }

    handleDismissError = () => {
        this.props.dismissError();
    }

    render() {
        const {
            children,
            error,
        } = this.props;


        return (
            <Translation>{t =>
                <div className={i18n.t("rooms")} style={{ backgroundColor: 'white' }}>
                    <Fabric>
                        <div className="ms-Grid">
                            <div className={`ms-Grid-row`} style={{ display: 'flex' }}>
                                <div className={`ms-Grid-col ms-sm12 ${css.breadcrumbHeader}`}>
                                    {/* <Breadcrumb /> */}
                                    <BreadcrumbFabric />
                                    {/* <LayerContentExample /> */}
                                </div>
                            </div>
                            <div style={{ "position": "absolute", "right": "18px", "top": "18px" }}><a style={{fontWeight: i18n.language == "de" ? 800 : 400}} onClick={() => { i18n.changeLanguage("de"); location.reload(); }} >DE</a> | <a style={{fontWeight: i18n.language == "en" ? 800 : 400}} onClick={() => { i18n.changeLanguage("en"); location.reload(); }}>EN</a></div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12" style={{ width: "100%" }}>
									{!this.props.logonState.authorized || !this.props.logonState.authenticated || !this.props.logonState.initialized ?
										<div className={overViewCSS.loader}>
											<ProgressIndicator description={`${i18n.t("login")}...`} className="ms-textAlignCenter" />
										</div> 
										:
										children
									}
                                    
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12">
                                    <ErrorBar />
                                </div>
                            </div>
                        </div>
                    </Fabric>
                </div >}
            </Translation>
        );
    }
}

export default App;
