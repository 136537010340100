import * as ReactDOM from "react-dom";
import * as React from "react";
import "./i18n";
import App from "./components/AppRoute";
import { createStore } from "./components/app/store";
import { initialize } from "./components/app/actions";
import parseUrl from "url-parse";

const store = createStore();

storeDispatching();

ReactDOM.render(
  <App store={store} />,
  document.getElementById("smartOfficeApp")
);

type QueryParams = { path?: string, source?: string };

function getQueryParams(): QueryParams {
  const { query } = parseUrl(window.location.href.toLowerCase(), null, true);
  return query;
}

function storeDispatching() {
  return new Promise<void>(resolve => {
    const { path, source } = getQueryParams();
    store.dispatch(initialize(path, source, resolve));
  });
}
