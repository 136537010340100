import * as React from 'react';
import { Icon } from "office-ui-fabric-react";

export const MeetingIcon = props => <Icon iconName="Group" className={props.className}  />;

export const ProjectSpaceIcon = props => <Icon iconName="Rocket" className={props.className} />;

export const FlexIcon = props => <Icon iconName="System" className={props.className} />;

export const HelpIcon = props => <Icon iconName="Help" className={props.className} />;

export const ChevronRightIcon = props => <Icon iconName="ChevronRight" className={props.className} />

export const ReservationIcon = props => <Icon iconName="IDBadge" className={props.className} />
