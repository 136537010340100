import { Action } from "redux";
import { Area, AreaFilter, AreaIdentifier, AreaState, OverallFeedback } from "../../../data/areas/models";
import ErrorAction from "../../../utils/ErrorAction";

export const FETCH_AREAS = "area/FETCH_AREAS";

export interface FetchAreasAction extends Action {
    type: typeof FETCH_AREAS;
    anchorIdentifier?: AreaIdentifier;
}

export function fetchAreas(id: AreaIdentifier): FetchAreasAction {
    return { type: FETCH_AREAS, anchorIdentifier: id };
}

export const FETCH_AREAS_SUCCESS = `${FETCH_AREAS}_SUCCESS`;

export interface FetchAreasSuccessAction extends Action {
    type: typeof FETCH_AREAS_SUCCESS;
    areas: AreaState[];
}

export function fetchAreasSuccess(areas: AreaState[]): FetchAreasSuccessAction {
    return { type: FETCH_AREAS_SUCCESS, areas };
}

export const FETCH_AREAS_ERROR = `${FETCH_AREAS}_ERROR`;

export interface FetchAreasErrorAction extends ErrorAction {
    type: typeof FETCH_AREAS_ERROR;
}

export function fetchAreasError(error: Error): FetchAreasErrorAction {
    return { type: FETCH_AREAS_ERROR, error, message: error.message };
}
///
export const FETCH_FLEXDESKS = "area/FETCH_FLEXDESKS";

export interface FetchFlexDesksAction extends Action {
    type: typeof FETCH_FLEXDESKS;
    floorId: string;
}

export function fetchFlexDesks(id: string): FetchFlexDesksAction {
    return { type: FETCH_FLEXDESKS, floorId: id };
}

export const FETCH_FLEXDESKS_SUCCESS = `${FETCH_FLEXDESKS}_SUCCESS`;

export interface FetchFlexDesksSuccessAction extends Action {
    type: typeof FETCH_FLEXDESKS_SUCCESS;
    areas: AreaState[];
}

export function fetchFlexDesksSuccess(areas: AreaState[]): FetchFlexDesksSuccessAction {
    return { type: FETCH_FLEXDESKS_SUCCESS, areas };
}

export const FETCH_FLEXDESKS_ERROR = `${FETCH_FLEXDESKS}_ERROR`;

export interface FetchFlexDesksErrorAction extends ErrorAction {
    type: typeof FETCH_FLEXDESKS_ERROR;
}

export function fetchFlexDesksError(error: Error): FetchFlexDesksErrorAction {
    return { type: FETCH_FLEXDESKS_ERROR, error, message: error.message };
}
///
export const FETCH_ROOM = "room/FETCH_ROOM";

export interface FetchRoomAction extends Action {
    type: typeof FETCH_ROOM;
    roomId: AreaIdentifier;
    date1?: Date;
    date2?: Date;
}

export function fetchRoom(id: AreaIdentifier): FetchRoomAction {
    return { type: FETCH_ROOM, roomId: id };
}

export const FETCH_ROOM_SUCCESS = `${FETCH_ROOM}_SUCCESS`;

export interface FetchRoomSuccessAction extends Action {
    type: typeof FETCH_ROOM_SUCCESS;
    room: AreaState,
}

export function fetchRoomSuccess(room: AreaState): FetchRoomSuccessAction {
    return { type: FETCH_ROOM_SUCCESS, room };
}

export const FETCH_ROOM_ERROR = `${FETCH_ROOM}_ERROR`;

export interface FetchRoomErrorAction extends ErrorAction {
    type: typeof FETCH_ROOM_ERROR;
}

export function fetchRoomError(error: Error): FetchRoomErrorAction {
    return { type: FETCH_ROOM_ERROR, error, message: error.message };
}

/// Feedback
export const FETCH_ROOM_FEEDBACK = "room/FETCH_ROOM_FEEDBACK";

export interface FetchRoomFeedbackAction extends Action {
    type: typeof FETCH_ROOM_FEEDBACK;
    roomId: AreaIdentifier;
}

export function fetchRoomFeedback(id: AreaIdentifier): FetchRoomFeedbackAction {
    return { type: FETCH_ROOM_FEEDBACK, roomId: id };
}

export const FETCH_ROOM_FEEDBACK_SUCCESS = `${FETCH_ROOM_FEEDBACK}_SUCCESS`;

export interface FetchRoomFeedbackSuccessAction extends Action {
    type: typeof FETCH_ROOM_FEEDBACK_SUCCESS;
    overallFeedback: OverallFeedback[],
    roomId: string;
}

export function fetchRoomFeedbackSuccess(overallFeedback: OverallFeedback[], roomId: string): FetchRoomFeedbackSuccessAction {
    return { type: FETCH_ROOM_FEEDBACK_SUCCESS, overallFeedback, roomId };
}

export const FETCH_ROOM_FEEDBACK_ERROR = `${FETCH_ROOM_FEEDBACK}_ERROR`;

export interface FetchRoomFeedbackErrorAction extends ErrorAction {
    type: typeof FETCH_ROOM_FEEDBACK_ERROR;
}

export function fetchRoomFeedbackError(error: Error): FetchRoomFeedbackErrorAction {
    return { type: FETCH_ROOM_FEEDBACK_ERROR, error, message: error.message };
}


export const SET_FILTER = `SET_FILTER`;

export interface FilterAction extends Action {
    type: typeof SET_FILTER;
    filter: string[];
}

export function setFilter(filter: string[]): FilterAction {
    return { type: SET_FILTER, filter};
}

export const SET_FILTER_ADD = `${SET_FILTER}_ADD`;

export interface AddFilterAction extends Action {
    type: typeof SET_FILTER_ADD;
    filter: AreaFilter;
}

export function addFilter(filter: AreaFilter): AddFilterAction {
    return { type: SET_FILTER_ADD, filter};
}

export const SET_FILTER_DELETE = `${SET_FILTER}_DELETE`;

export interface DeleteFilterAction extends Action {
    type: typeof SET_FILTER_DELETE;
    filter: AreaFilter;
}

export function deleteFilter(filter: AreaFilter): DeleteFilterAction {
    return { type: SET_FILTER_DELETE, filter};
}