import {
    AnyRoute, HOME_ROUTE, DASHBOARD_ROUTE, LOCATIONS_ROUTE, FLOORS_ROUTE, FLOOR_DETAIL_ROUTE, ADMIN_ROUTE, ROOM_DETAIL_ROUTE, ROOT_ROUTE, FLOORMAP_ROUTE
} from "../../navigation/actions";
import { Home } from '../../home/home'
import { Admin } from '../../admin/admin_home'
import {AreaOverview} from '../../areas/overview'
import LocationOverview from '../../locations/overview'
import FloorOverview from "../../floors/overview";
import {RoomDetailView} from "../../areas/roomdetails";

export default {
    [ROOT_ROUTE]: LocationOverview,
    [HOME_ROUTE]: Home,
    // [DASHBOARD_ROUTE]: Dashboard,
    [LOCATIONS_ROUTE]: LocationOverview,
    [FLOORS_ROUTE]: FloorOverview,
    [FLOOR_DETAIL_ROUTE]: AreaOverview,
    [FLOORMAP_ROUTE]: AreaOverview,
    [ROOM_DETAIL_ROUTE]: RoomDetailView,
    [ADMIN_ROUTE]: Admin
} as { 
    [route in AnyRoute]: React.ComponentClass 
};
