import * as React from 'react';
import { FocusZone } from 'office-ui-fabric-react/lib/FocusZone';
import { List } from 'office-ui-fabric-react/lib/List';
import { IRectangle } from 'office-ui-fabric-react/lib/Utilities';
import { ListItem } from '../../data/listItem/model';
import { TileRow } from './tilesRow';
import { ProgressIndicator, Shimmer, ShimmerElementType } from 'office-ui-fabric-react';
import * as cssFloors from "../floors/floors.module.scss";
import * as css from "./basicList.module.scss";
import { AreaIdentifier, AreaState } from '../../data/areas/models';
import * as common from "../../utils/commonFunctions";
import classNames from "classnames";
import i18n from '../../i18n';
import { Meeting } from '../../data/meeting/models';
import { CONFIG } from '../../../config/settings';

export type ListFieldProps = {
  items?: AreaState[];
  loading:boolean;
  onOpenItem(item: AreaIdentifier): void;
};

export type LoadingStateWrapper = {
	loading: boolean;
	item: AreaState;
}

const ROWS_PER_PAGE = 3;
const MAX_ROW_HEIGHT = 250;

export const ListGrid:React.FC<ListFieldProps> = ({items, onOpenItem, loading}) => {
  const [paddedItems, setPaddedItems] = React.useState<LoadingStateWrapper[]>([]);


  const _columnCount = React.useRef(0);
  const _columnWidth = React.useRef(0);
  const _rowHeight =  React.useRef(0);

  const _getItemCountForPage = React.useCallback((itemIndex: number, surfaceRect: IRectangle): number => {
	if (itemIndex === 0) {
      _columnCount.current = Math.ceil(surfaceRect.width / MAX_ROW_HEIGHT);
      _columnWidth.current = Math.floor(surfaceRect.width / _columnCount.current);
      _rowHeight.current = _columnWidth.current;
    }

    return _columnCount.current * ROWS_PER_PAGE;
  	}, []);

	const _getPageHeight = React.useCallback((): number => {
		return _rowHeight.current * ROWS_PER_PAGE;
	}, []);

	React.useEffect(() => {
		let padCount = 0;
		if (loading) {
			if(items.length == 0) {
				padCount = 3
			}
			else {
				padCount = 1
			}
		}
		
		const mappedItems = items.map<LoadingStateWrapper>(i => {return {item: i, loading: false}})
		const padds: LoadingStateWrapper[] = []
		for(let i = 0; i<padCount; i++) {
			padds.push({item: undefined, loading: true})
		}

		setPaddedItems([...mappedItems, ...padds, ])

	}, [items, loading])

	const _onRenderCell = React.useCallback((item: LoadingStateWrapper, index: number | undefined): JSX.Element => {
		return (
		  <div
			className={css.listGridTile}
			data-is-focusable={true}
			style={{
			  width: 100 / _columnCount.current + '%'
			}}
		  >
			{!item.loading ?
				<div className={css.listGridSizer} onClick={() => onOpenItem(item.item.identifier)} >
				<div className={css.listGridPadder}>
					<div className={classNames(css.floorTileIconContainer, css.width92)}>
						{common.renderValues(item.item, "", false)}
					</div>
					<span className={css.listGridLabel}>{item.item.title}</span>
					<TileRow item={item.item} />
				</div>
				</div>
			:
				<div className={css.listGridSizer} >
				<div className={css.listGridPadder}>
					<div className={classNames(css.floorTileIconContainer, css.width92)}>
						{/* {common.renderValues(item.item, "", false)} */}
					</div>
					<span className={css.listGridLabel}>
						<Shimmer width="30%"
							shimmerColors = {{
								shimmer: CONFIG.palette.themeTertiary,
								shimmerWave: CONFIG.palette.themeSecondary,
								background: CONFIG.palette.themePrimary
							}}
							shimmerElements={[{ type: ShimmerElementType.line}]}
						/>
					</span>
					<ShimmerListItem count={3} />
				</div>
				</div>
			}
		  </div>
		);
	  }, []);

    return (
      <FocusZone>
		<List
			className={css.listGrid}
			items={paddedItems}
			getItemCountForPage={_getItemCountForPage}
			getPageHeight={_getPageHeight}
			renderedWindowsAhead={4}
			onRenderCell={_onRenderCell}
		/>
      </FocusZone>
    );
}

const ShimmerListItem: React.FC<{count: Number}> = ({count}) => {
	return (
		<div className={css.listGridRowContainer}>
		{[...Array(count)].map(el => 
			<div className={css.listGridRow}>
				<div className={css.shimmerEl}>
					<Shimmer width="90%" 
						shimmerColors = {{
							shimmer: CONFIG.palette.neutralQuaternary,
							shimmerWave: CONFIG.palette.neutralTertiary,
							background: CONFIG.palette.neutralLighter
						}}
						shimmerElements={[
							{ type: ShimmerElementType.circle },
							{ type: ShimmerElementType.gap, width: '7%' },
							{ type: ShimmerElementType.line }
						]}
					/>
				</div>
			</div>
		)}
		</div>
	)
}