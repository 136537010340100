import * as React from "react";
import { AreaState } from "../../../data/areas/models";
import * as css from './../../App.scss'
import { connect } from "react-redux";
import { StateWithData } from "../../app/reducers";
import classNames from "classnames"
import * as common from "../../../utils/commonFunctions";
import { CONFIG } from "../../../../config/settings"
import { Icon } from "office-ui-fabric-react";
import { TodayMeetingsLine } from "./todayMeetingsLine";
import i18n from "../../../i18n";
import { currentRoomSelector, meetingSelector, meetingsSelector } from "../../../store/selectors";
import { Meeting } from "../../../data/meeting/models";

const serviceAccountName: string = CONFIG.adHocConfig.serviceAccountName;
const adHocMeetingTitle = CONFIG.adHocConfig.adHocMeetingTitle;

interface StateProps {
    roomState: AreaState;
	meetings: Meeting[];
}

class RoomDetails extends React.Component<StateProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { roomState, meetings } = this.props;
        let roomStateTomorrow = roomState.start ? new Date(roomState.start) : null;
        roomState.start ? roomStateTomorrow.setDate(roomStateTomorrow.getDate() + 1) : null;

        if (!!roomState && !!roomState.meetingTitle && roomState.meetingTitle == serviceAccountName) {
            roomState.meetingTitle = adHocMeetingTitle;
        }

        return (
            !!roomState &&
            <>
                {!!roomState.start || meetings ?
                    <div className="ms-Grid">
                        <div className={classNames("ms-Grid-row")}>
                            <div className={"ms-Grid-col ms-sm12 ms-md7"}>

                                <div className={classNames("ms-Grid-col ms-sm12 ", css.bgColorNeutralLighter)}>
                                    <h3 style={{ marginBottom: "5px", marginTop: 5 }}>{i18n.t("detailsMeetingOverviewToday")}</h3>
                                    <div className={classNames("ms-Grid-col ms-sm12 ms-md12", css.marginTopBottom6)}>
                                        <TodayMeetingsLine meetings={meetings} />
                                    </div>
                                    {!!roomState.start && !!roomState.end ?
                                        <div className={classNames(css.bgColorNeutralLighter, css.marginTopBottom2)}>
                                            <div className={classNames("ms-Grid-col ms-sm12", css.bgColorNeutralLighter)}>
                                                <h3>{i18n.t("currentMeeting")}</h3>
                                            </div>
                                            {!!roomStateTomorrow && roomStateTomorrow.toLocaleTimeString() == new Date(roomState.end).toLocaleTimeString() ?
                                                <div>
                                                    <div className={classNames("ms-Grid-col ms-sm12", css.bgColorNeutralLighter)}>
                                                        <h3 className={css.colorGreen}>{i18n.t("allDay")}</h3>
                                                    </div>
                                                    <div className={classNames("ms-Grid-col ms-sm12", css.paddingTop)}>
                                                        <div className={classNames(css.displayInlineFlex)}>
                                                            <Icon className={classNames("ms-Icon", css.listComponentIcon)} iconName={"Contact"} />
                                                            <div className={css.floorTileIconText}>
                                                                <span className={classNames(css.listComponentText, css.paddingLeft)} >{roomState.meetingTitle}</span>
                                                            </div>
                                                        </div>
                                                        <div className={css.displayInlineFlex}>
                                                            <Icon className={classNames("ms-Icon", css.listComponentIcon)} iconName={"Mail"} />
                                                            <div className={classNames(css.floorTileIconText, css.textEllipsis)}>
                                                                <span className={classNames(css.listComponentText, css.paddingLeft)} >{roomState.organizerEmail}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div className={classNames("ms-Grid-col ms-sm12", css.bgColorNeutralLighter)}>
                                                        <h3 className={css.colorGreen}>{new Date(roomState.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(roomState.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h3>
                                                    </div>
                                                    <div className={classNames("ms-Grid-col ms-sm12", css.bgColorNeutralLighter, css.paddingTop)}>
                                                        <div className={classNames("ms-Grid-row ms-sm12", css.marginLeftRight0)}>
                                                            <div className={classNames(css.displayInlineFlex)}>
                                                                <Icon className={classNames("ms-Icon", css.listComponentIcon)} iconName={"Contact"} />
                                                                <div className={css.floorTileIconText}>
                                                                    <span className={classNames(css.listComponentText, css.paddingLeft)} >{roomState.meetingTitle}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={classNames("ms-Grid-row ms-sm12", css.marginLeftRight0)}>
                                                            <div className={classNames(css.displayInlineFlex)}>
                                                                <Icon className={classNames("ms-Icon", css.listComponentIcon)} iconName={"Mail"} />
                                                                <div className={css.floorTileIconText}>
                                                                    <span className={classNames(css.listComponentText, css.paddingLeft)} >{roomState.organizerEmail}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div className={css.paddingLeft9}>
                                            {common.renderMeetingTile(roomState, meetings, classNames("ms-Grid-row", css.listComponentText), 20)}
                                        </div>}
                                </div>
                                {common.renderUpcomingMeetings(meetings)}
                            </div>
                            <div className={classNames("ms-Grid-col ms-sm12 ms-md5", css.paddingRight16, css.paddingLeft16)}>
                                <div className={classNames("ms-Grid-row", css.bgColorNeutralLighter)}>
                                    <div className={classNames("ms-Grid-col ms-sm12", css.paddingBottom10, css.paddingTop3)}>
                                        <h3>{i18n.t("roominformation")}</h3>
                                    </div>
                                </div>
                                <div className={classNames("ms-Grid-row", css.bgColorNeutralLighter)}>
                                    <div className={classNames("ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl7", css.bgColorNeutralLighter)}>
                                        {common.renderValues(roomState, undefined, true)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }

            </>
        )
    }
}

function mapStateToProps(state: StateWithData): StateProps {
    return {
        roomState: currentRoomSelector(state),
		meetings: meetingSelector(state)
    };
}

export default connect<StateProps>(mapStateToProps)(RoomDetails);