import { Floor, FloorStatistic } from "./models";
import { Mapper } from "../../services/Mapper";
import { AreaStatus, AreaStatusDTO, StatisticsEntryArea, HierarchialAreaStatusDTO } from "../../services/ApiClient";
import { ListItemMapper } from "../areas/areaMapper";


export class FloorMapper extends Mapper<Floor> {
	public static toDomain(dto: AreaStatusDTO): Floor {
		console.log(`seting the parentLocation to ${dto.parentLocation?.uri}`)
		return {
			...ListItemMapper.toDomain(dto),
			locationUri: dto.parentLocation?.uri,
		}
	}

	public static hierarchyToDomain(dto: HierarchialAreaStatusDTO, locationUri: string): Floor {
		return {
			...ListItemMapper.hieriarchyToDomain(dto),
			locationUri: locationUri,
		}
	}
}

/**
 * This is just a wrapper. Needs completion upon implementation of floor-statistics
 */
export class FloorStatisticMapper extends Mapper<FloorStatistic> {
	public static toDomain(dto: StatisticsEntryArea, floorUri: string): FloorStatistic {
		return {} as FloorStatistic;
	}
}