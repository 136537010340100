export default {
    //generic translations
    "welcome": "Welcome",
    "available": "Available",
    "overallAvailable": "Overall available",
    "login": "Login",
    "refresh": "Refresh",
    "percent": "Percent",
    "allDay": "All day",
    "noData": "no data",
    "currentMeeting": "Current Meeting",
    "moreDetails": "More details",
    "filterName": "Filter by name",
    "minimal": "Minimum",
    "average": "Average",
    "maximal": "Maximum",
    "charts": "Charts",
    "granularity": "Granularity",
    "person": "person",
    "persons": "persons",
    "utilization": "utilization",
    "dateRange": "Date range",
    "dateRangeStart": "Start Date",
    "dateRangeEnd": "End Date",
    "days": "Days",
    "weeks": "Weeks",
    "weekStartday": "First day of the week",
    "month": "Month",
    "months": "Months",
    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    "date": "Date",
    "cw": "CW",

    // generic app specific
    "AppName": "smartWorkspace Web App",
    "rooms": "Rooms",
    "areas": "areas",
    "meetings": "Meetings",
    "locations": "Locations",
    "meetingroom": "Meeting room",
    "meetingrooms": "Meeting rooms",
    "projectSpace": "Project space",
    "projectSpaces": "Project spaces",
    "flexdesks": "Flexdesks",
    "flexdesk": "Flexdesk",
    "reservations": "Reservations",
    "projector": "Projector/Display",
    "floors": "Floors",
    "floormap": "Floor map",
    "roominformation": "Room Information",
    "statistics": "Statistics",
    "zone": "Zone",
    "zoneDetails": "Zone Details",
    "admin": "Admin",
    "home": "Home",

    // loadings
    "loading": "Loading...",
    "loadingX": "Loading {{x}}...",
		"loadingAreas": "Loading areas...",
		"loadingFloormap": "Loading floor map...",
    "loadingStatistics": "Loading statistics for {{item}}...",
    // page specific
    "peopleCount": "people count",
    "pCount": "PCount", // is this "people count"
    "tabStatusOverview": "Status & Overview",
    "tabRatingFeedback": "Rating & Feedback",
    // "does it work": "Please remove me from ROot.tsx",
    "headlineCompanyHasOffices": "Your company has {{count}} Offices. Choose one to see details.",
    "headlineAdmin": "Administration",
    "contentAdmin": 'Tab with "Rooms"\n\nTiles with "Add Room"\n\nSensor devices can be added to room\nSignage is configured in room',
    "detailsMeetingOverviewToday": "Meeting overview for today",
    "detailsUpcommingMeetingsToday": "Upcomming meetings today",
    "headlineFeedbackHistory": "Overall Feedback History",
    "feedbackNone": "no feedback yet",
    "statisticsBookedDurationMinutes": "Booked duration (minutes)",
    "statisticsTotalDuration": "Total duration (minutes)",
    "statisticsChartMinMaxPersProUnit": "Minimal and maximal number {{linebreak}}of persons per {{unit}}",
    "statisticsChartAvgBookedDuration": "Average ratio of actual {{linebreak}}to booked meeting duration",
    "statisticsChartNumberMeetingsAttendees": "Number of meetings per number of {{linebreak}}attendees segmented by room zones",
    "statisticsMeetingsOn": "Statistic about meetings on",
    "statisticsNumberBookings": "Number of bookings",
    "statisticsBookedDuration": "Booked duration",
    "statisticsTimeUtilization": "Time utilization",
    "statisticsNumberMeeting": "Number of meetings",
    "statisticsTotalNumberMeeting": "Total number of meetings",
    "statisticsAvgNumberPersonsMeeting": "Average number of persons per meeting",
    "statisticsChartAvgPersonsMeeting": "Average People / Meeting",
    "statisticsChartPersonsMeeting": "People Range / Meeting",
    "statisticsChartNumberMeeting": "Number of Meetings",
    "statisticsChartNumberAttendees": "Number of attendees",
    "statisticsChartMotionRange": "Motion range",
    "statisticsChartMotionRangeDay": "Motion range per day",
    "floormapClickDetails": "Click on a room or desk to see details",
    "roomcommonAvailable": "This room is currently available ",
    "roomcommonAvailableUntil": "This room is available until",
    "roomcommonInUseBy": "This room is currently in use by {{whom}}",
    "commonUpcomingMeetingsToday": "Upcoming meetings today",
    "commonNextMeeting": "Next Meeting",
    "commonNoMoreMeetingsToday": "No more meetings today",
    "floorStatsMinUtil": "Minutes of Utilization",
    "floorStatsAboutUtil": "Statistic about utilization",
    "floorStatsAvgUtilAreas": "Average utilization of areas",
    "floorStatsAvgAreaUtil": "Average Area Utilization",
    "breadcrumbMaxSize":"Breadcrumb with maxDisplayedItems set to 4",
    "todayMeetingLineTooltip":"Today meetings from 6AM to 10PM.",
    "todayMeetingLineText":"Availability from 6 AM to 10 PM",
}
