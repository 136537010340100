import { Mapper } from "../../services/Mapper";
import {Area, AreaAvailabilityStatus, AreaState, AreaStatisticData, OverallFeedback} from "./models"
import {Area as AreaDTO, AreaTypeEnum as AreaTypeEnumDTO, AreaFunctionEnum, AreaStatusDTO, Meeting as MeetingDTO, AreaFeedbackDTO, HierarchialAreaStatusDTO, ParentAreaDTO, TupleOfLongAndMeeting, AreaAvailabilityStatus as AvailabilityDTO, StatisticsEntryArea} from "../../services/ApiClient"
import { AreaTypeEnum, ListItem } from "../listItem/model";
import { DefaultPalette } from "office-ui-fabric-react";
import { Meeting } from "../meeting/models";
import moment from "moment";
import { currentRouteComponentSelector } from "../../components/app/Root/selectors";
import { filterToggle } from "../../components/App.scss";
import { DIRECTION_HORIZONTAL } from "hammerjs";

export class ListItemMapper extends Mapper<ListItem> {
	static toDomain(dto: AreaStatusDTO): ListItem {
		const now = moment();
		//console.log("Current moment:")
		//console.log(now);
		//console.log(dto.todayMeetings);
		const currentMeeting = dto.todayMeetings?.find(meeting => meeting?.start < now && meeting?.end > now);
		//console.log(`Does a current meeting exist?: ${currentMeeting ? "yes": "no"}`);

		const ret: ListItem = {
			identifier: {
				id: dto.id,
				uri: dto.uri,
				upn: dto.upn
			},
			type: AreaTypeMapper.toDomain(dto.type, dto.areaFunction),
			brightness: dto.brightness,
			humidity: dto.humidity,
			isCurrentlyBooked: dto.meetingTitle ? true : false,
			isCurrentlyOccupied: dto.presence,
			meetingAttendance: currentMeeting?.realPersonAttendeeCount,
			totalMeetingRooms: currentMeeting?.meetingHasAreas?.length,
			temperature: dto.temperature,
			title: dto.title,
			
		}
		return ret;
	}

	static hieriarchyToDomain(dto: HierarchialAreaStatusDTO): ListItem {
		const ret: ListItem = {
			identifier: {
				id: dto.id,
				uri: dto.uri,
				upn: dto.upn
			},
			title: dto.title,
			type: AreaTypeMapper.toDomain(dto.type, dto.areaFunction),
			temperature: dto.temperature,   // degree celsius
			humidity: dto.humidity,      // percentage
			brightness: dto.brightness,    // percentage
			totalMeetingRooms: dto.aggregatedMeetingRoomsTotal,
			availableMeetingRooms: dto.aggregatedMeetingRoomsAvailable,
			totalFlexdesks: dto.aggregatedFlexdesksTotal,
			availableFlexdesks: dto.aggregatedFlexdesksAvailable,
			totalProjectSpaces: dto.aggregatedProjectspacesTotal,
			availableProjectSpaces: dto.aggregatedProjectSpacesAvailable,
			isCurrentlyBooked: dto.meetingTitle ? true : false,
			isCurrentlyOccupied: dto.presence,
			totalReservatedCapacity: dto.capacity,
			availableReservatedCapacity: dto.aggregatedReservationsTotal,
			
		}

		return ret;
	}

	static parentToDomain(dto: ParentAreaDTO): ListItem {
		const ret:ListItem = {
			identifier: {
				id: dto.id,
				uri: dto.uri,
				upn: dto. upn
			},
			//upn: dto.upn,
			title: dto.title,
			type: AreaTypeMapper.toDomain(dto.type, dto.areaFunction),
		}

		return ret;
	}
}

export class AreaStateMapper extends Mapper<AreaState> {
	static toDomain(dto: AreaStatusDTO): AreaState {
		//console.log("getting meetings")
		//console.log(dto);
		//const meetings = dto.todayMeetings?.map(meeting => MeetingMapper.toDomain(meeting, dto.uri));
		//console.log("mapping rest");
		return {
			...ListItemMapper.toDomain(dto),
			floorId: dto.parentFloor?.id,
			locationId: dto.parentLocation?.id,
			canceled: dto.cancelled?.toDate(),
			capacity: dto.capacity,
			connectableWithAreaId: dto.connectableWithAreaId,
			end: dto.end?.toDate(),
			lastMotion: dto.lastMotion?.toDate(),
			organizerEmail: dto.organizerEmail,
			start: dto.start?.toDate(),
			meetingTitle: dto.meetingTitle,
			peopleCount: dto.personCount,
			availability: AreaAvailabilityMapper.toDomain(dto.state),
			stateId: dto.state.valueOf(),
			parentIds: undefined
		}
	}

	static hieriarchyToDomain(dto: HierarchialAreaStatusDTO): AreaState {
		//const meetings = dto.todayMeetings?.map(meeting => MeetingMapper.toDomain(meeting, dto.uri));
		//console.log(`Area ${dto.id}`, dto.start, typeof(dto.start));
		return {
			...ListItemMapper.hieriarchyToDomain(dto),
			locationId: dto.locationId,
			peopleCount: dto.personCount,
			floorId: dto.floorId,
			parentIds: dto.parentIds,
			start: dto.start?.toDate(),
			end:dto.end?.toDate(),
			availability: AreaAvailabilityMapper.toDomain(dto.state),
			meetingTitle: dto.meetingTitle,
			organizerEmail: dto.organizerEmail,

		}

	}
}

export class AreaAvailabilityMapper extends Mapper<AreaAvailabilityStatus> {
	public static toDomain(dto: AvailabilityDTO) {
		let convertedAvailability: AreaAvailabilityStatus;
		switch(dto) {
			case AvailabilityDTO.Available:
				convertedAvailability = AreaAvailabilityStatus.Available
				break;
			case AvailabilityDTO.NoDataAvailable:
				convertedAvailability = AreaAvailabilityStatus.NoDataAvailable
				break;
			case AvailabilityDTO.Occupied:
				convertedAvailability = AreaAvailabilityStatus.Occupied;
				break;
			case AvailabilityDTO.Pending:
				convertedAvailability = AreaAvailabilityStatus.Pending
				break;
		}

		return convertedAvailability;
	}
}

export class MeetingMapper extends Mapper<Meeting> {
	public static toDomain(tuple:  TupleOfLongAndMeeting): Meeting {
		//console.log("mapping this meeting")
		//console.log(dto)
		//console.log(typeof dto.end)
		const {item2: dto, item1: id} = tuple;
		return {
			areaId: id,
			end: dto.end?.toDate(),
			start: dto.start?.toDate(),
			icalUid: dto.iCalUId,
			title: dto.title,
			organizerEmail: dto.organizerEmail,
			botConversationStarted: dto.botConversationStarted?.toDate(),
			canceled: dto.cancelled?.toDate(),
		}
	}
}

export class AreaFeedbackMapper extends Mapper<OverallFeedback> {
	public static toDomain(dto: AreaFeedbackDTO): OverallFeedback {
		return {
			areaUri: dto.areaId.toString(),
			month: dto.month,
			rating: dto.rating,
			year: dto.year
		}
	}
}

export class AreaStatisticMapper extends Mapper<AreaStatisticData> {
	public static toDomain(dto: StatisticsEntryArea[], areaUri: string): AreaStatisticData[] {
		if (dto.length > 0) {
			const minMaxPCount = dto.reduce((prev, current) => current.maxPersonCount < prev.maxPersonCount ? current : prev).maxPersonCount;
			const maxMaxPCount = dto.reduce((prev, current) => current.maxPersonCount > prev.maxPersonCount ? current : prev).maxPersonCount;
			return dto.map(statistic => {
				return {
					areaId: statistic.areaId,
					areaUri: areaUri,
					avgPCount: statistic.avgPersonCount,
					date: statistic.createdAt?.toDate(),
					maxLastMotion: statistic.lastMotion?.toDate(),
					minLastMotion: statistic.lastMotion?.toDate(),
					//maxLastMotion: statistic.maxLastMotion?.toDate(),
					//minLastMotion: statistic.minLastMotion?.toDate(),
					maxPcount: statistic.maxPersonCount,
					minPcount: statistic.minPersonCount,
					//sumMotionDuration: statistic.sumMotionDuration,
					sumMotionDuration: 0,
					minMaxPCount: minMaxPCount,
					maxMaxPCount: maxMaxPCount,
				}
			});
		}

		return [];
	}
}

export class AreaMapper extends Mapper<Area> {
	static toDomain(dto: AreaDTO): Area {
		return {
			identifier: {
				id: dto.id,
				uri: dto.uri,
				upn: dto.upn
			},
			floorId: dto.parentAreaId,
			type: AreaTypeMapper.toDomain(dto.type, dto.areaFunction),
			capacity: dto.capacity,
			connectableWithArea: dto.connectableWithArea ? AreaMapper.toDomain(dto.connectableWithArea): undefined,
			connectableWithAreaId: dto.connectableWithArea?.id,
			hasBeamer: dto.areaHasEquipments?.some(eq => eq.areaEquipment?.category?.toLocaleLowerCase() === "beamer") ? 1 : 0,
			hasProjector: dto.areaHasEquipments?.some(eq => eq.areaEquipment?.category?.toLocaleLowerCase() === "beamer") ? 1 : 0,
			title: dto.title,
		}
	}

}

export class AreaTypeMapper extends Mapper<AreaTypeEnum> {
	static toDomain(dto: AreaTypeEnumDTO, functionDTO: AreaFunctionEnum): AreaTypeEnum {
		let convertedType: AreaTypeEnum;
		switch (dto) {
			case AreaTypeEnumDTO.Building:
				convertedType = AreaTypeEnum.Building;
				break;
			case AreaTypeEnumDTO.Desk:
				convertedType = AreaTypeEnum.Desk;
				break;
			case AreaTypeEnumDTO.DeskCluster:
				convertedType = AreaTypeEnum.DeskCluster;
				break;
			case AreaTypeEnumDTO.Room:
				switch (functionDTO) {
					case AreaFunctionEnum.MeetingRoom || AreaFunctionEnum.ConferenceRoom:
						convertedType = AreaTypeEnum.MeetingRoom;
						break;
					case AreaFunctionEnum.ProjectSpace:
						convertedType = AreaTypeEnum.ProjectSpace;
						break;
					default:
						convertedType = AreaTypeEnum.Other
						break;
				}
				break;

			case AreaTypeEnumDTO.RoomZone:
				convertedType = AreaTypeEnum.RoomZone;
				break;
			case AreaTypeEnumDTO.Level:
				convertedType = AreaTypeEnum.Level;
				break;
			case AreaTypeEnumDTO.Location:
				convertedType = AreaTypeEnum.Location;
				break;
			default:
				convertedType = AreaTypeEnum.Other;
				break;
		}
		return convertedType;

	}
}