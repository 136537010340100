import { Area, AreaIdentifier, AreaState, AreaStatisticData, OverallFeedback, ZoneCapacityData } from "../../data/areas/models";
import { Floor, FloorMap } from "../../data/floors/models";
import { LocationModel } from "../../data/locations/models";
import { AnyAppAction } from "../../store/actions";
import { orderBy, range, minBy, maxBy } from"lodash-es";
import {FLOORS_ROUTE, FLOOR_DETAIL_ROUTE, LOCATIONS_ROUTE, NavigateToFloorDetailsAction, NavigateToFloorsAction, NavigateToRoomDetailsAction, ROOM_DETAIL_ROUTE} from "../../components/navigation/actions"
 import {
    //  FETCH_FLOORS, FETCH_FLOORS_SUCCESS, FETCH_FLOORS_ERROR, FetchFloorsSuccessAction, FetchFloorSuccessAction,
     /*FETCH_FLOOR, FETCH_FLOOR_SUCCESS,*/ FETCH_FLOORMAP, FETCH_FLOORMAP_ERROR, FETCH_FLOORMAP_SUCCESS, FetchFloormapSuccessAction, FETCH_FLOOR_STATISTIC, FETCH_AREA_STATISTIC, FETCH_AREA_STATISTIC_ERROR, FETCH_AREA_STATISTIC_SUCCESS, FetchAreaStatisticSuccessAction, FetchFloormapErrorAction, FetchFloormapAction} from "../../store/actions/floorActions";
import {
    FETCH_AREAS, FETCH_AREAS_SUCCESS, FETCH_AREAS_ERROR, FetchAreasSuccessAction,
    FETCH_FLEXDESKS, FETCH_FLEXDESKS_SUCCESS, FETCH_FLEXDESKS_ERROR, FetchFlexDesksSuccessAction,
    FETCH_ROOM, FETCH_ROOM_SUCCESS, FETCH_ROOM_ERROR, FetchRoomSuccessAction,
    FETCH_ROOM_FEEDBACK_ERROR, FETCH_ROOM_FEEDBACK_SUCCESS, FetchRoomFeedbackSuccessAction, FetchRoomFeedbackErrorAction, FETCH_ROOM_FEEDBACK, FilterAction, SET_FILTER, FetchRoomErrorAction, FetchFlexDesksErrorAction, SET_FILTER_ADD, AddFilterAction, SET_FILTER_DELETE, DeleteFilterAction
} from "../../store/actions/areaActions/areaActions";
import {
    //FETCH_ROOM_STATISTIC, FETCH_ROOM_STATISTIC_SUCCESS, FETCH_ROOM_STATISTIC_ERROR, FetchRoomStatisticSuccessAction, FetchRoomStatisticErrorAction,
    // FETCH_ROOM_STATISTIC_CAPACITY, FETCH_ROOM_STATISTIC_CAPACITY_SUCCESS, FETCH_ROOM_STATISTIC_CAPACITY_ERROR, FetchRoomCapacityStatisticSuccessAction, FetchRoomCapacityStatisticErrorAction,
    FETCH_ROOM_STATISTIC_ZONE, FETCH_ROOM_STATISTIC_ZONE_SUCCESS, FETCH_ROOM_STATISTIC_ZONE_ERROR, FetchRoomZonesStatisticErrorAction, FetchRoomZonesStatisticSuccessAction, 
} from "../../store/actions/areaActions/statisticActions";
import navigationReducer, { StateWithNavigation } from "../navigation/reducers";
import { combineReducers, Reducer, ReducersMapObject } from "redux";
//import { AnyInitializeAction, INITIALIZE, INITIALIZE_ERROR, INITIALIZE_SUCCESS, CONFIG_SUCCESS, CONFIG, CONFIG_ERROR } from "./actions";
import { AnyInitializeAction, INITIALIZE, INITIALIZE_ERROR, INITIALIZE_SUCCESS, LogonAction, LOGON_REQUEST, LOGON_ERROR, LOGON_SUCCESS, LOGON_INIT, LOGON_INIT_SUCCESS, InitLogonSuccessAction, LogonSuccessAction, LOGON_AUTHENTICATE, LOGON_AUTHENTICATE_SUCCESS, LOGON_AUTHENTICATE_ERROR, LOGON_AUTHORIZE, LOGON_AUTHORIZE_SUCCESS, LOGON_AUTHORIZE_ERROR } from "./actions";
import ErrorAction, { ClientError, DISMISS_ERROR } from "../../utils/ErrorAction";
import { ConfigModel } from "../../config/ConfigModel";
import { AreaTypeEnum } from "../../data/listItem/model";
import { AuthType } from "../../services/ApiClientAuth";
import { Moment } from "moment";
import { Meeting } from "../../data/meeting/models";
import { map } from "@progress/kendo-data-query/dist/npm/transducers";
import { appendOrReplace, appendOrReplaceArray, appendOrUpdate, areadIdEquals } from "../../utils/commonFunctions";
import { marginTop10 } from "../App.scss";
import { AreaFeedback } from "../../services/ApiClient";
import { FetchMeetingsSuccessAction, FETCH_MEETINGS, FETCH_MEETINGS_ERROR, FETCH_MEETINGS_SUCCESS } from "../../store/actions/meetingActions";

export type DataState = Readonly<{
    loading?: boolean;
    error?: any;
	areas: AreaState[];
	floorMaps: FloorMap[];
    //currentLocation?: AreaIdentifier;
    //currentFloor?: AreaIdentifier;
    //currentRoom?: AreaIdentifier;
	currentArea?: AreaIdentifier;
    loadingItems: boolean;
    filter: string[];
	meetings: Meeting[];
	loadingMeetings: boolean;
	zoneStats: ZoneCapacityData[];
	areaStats: AreaStatisticData[];
	feedback: OverallFeedback[];
}>;

const initialState: DataState = {
    loading: false,
    loadingItems: false,
    //locations: [],
    //rooms: [],
    //desks: [],
    //deskCluster: [],
    //floors: [],
    filter: [],
	areas: [],
	floorMaps: [],
	meetings: [],
	areaStats: [],
	zoneStats: [],
	 feedback: [],
	 loadingMeetings:false
};

export type StateWithData = Readonly<{
    data: DataState;
}>;

function dataReducer(state = initialState, action: AnyAppAction): DataState {
    switch (action.type) {
		case FLOORS_ROUTE:{
			const act = action as NavigateToFloorsAction;
			// console.log("Floor_route")
			// console.log(act)
			// console.log(state.areas.find(a => areadIdEquals(a.identifier, act.payload)))
			const area = state.areas.find(a => areadIdEquals(a.identifier, act.payload))
			return {
				...state,
				currentArea: area ? area.identifier: act.payload
				//currentFloor: undefined,
				//currentRoom: undefined
			}
		}
		case FLOOR_DETAIL_ROUTE:{
			const act = action as NavigateToFloorDetailsAction;
			// console.log(action)
			const floor = state.areas.find(a => areadIdEquals(a.identifier, act.payload))
			// console.log("Found this floor:")
			// console.log(floor)
			return {
				...state,
				//currentLocation: state.areas.find(a => a.identifier.id == floor.locationId)?.identifier,
				currentArea: floor ? floor.identifier : act.payload
				//currentRoom: undefined
			}
		}
		case ROOM_DETAIL_ROUTE:{
			const act = action as NavigateToRoomDetailsAction;
			const room = state.areas.find(a => areadIdEquals(a.identifier, act.payload))
			//const floor = state.areas.find(a => a.identifier.id == room.floorId)
			// console.log("room:", room);
			return {
				...state,
				//currentLocation: state.areas.find(a => a.identifier.id == room.locationId)?.identifier,
				//currentFloor: floor?.identifier,
				currentArea: room ? room.identifier: act.payload
			}
		}
        case FETCH_AREAS: {
			return {
				...state,
				loading: true,
				loadingItems: true
				//TODO: HOW TO DEAL WITH LOCATIONS/FLOORS IN BREADCRUMB?
			}
		}
 
        case FETCH_FLOORMAP_SUCCESS:
            var fetchAction = (action as FetchFloormapSuccessAction);
			const map: FloorMap = {areaId: fetchAction.areaId, map: fetchAction.floormap, loading: false, loadingFailed: false}
            return {
                ...state,
				floorMaps: appendOrReplace(state.floorMaps, map, (m1,m2) => areadIdEquals(m1.areaId, m2.areaId)),
            }
         case FETCH_AREA_STATISTIC_SUCCESS:
            var areaStatistic = (action as FetchAreaStatisticSuccessAction);

            return {
                ...state,
				areaStats: appendOrReplaceArray(state.areaStats, areaStatistic.areaStatistic, (s1, s2) => s1.areaId == s2.areaId),
            }
        case FETCH_FLOORMAP:
			const mapAction = (action as FetchFloormapAction);
			const idElement:FloorMap = {
				areaId: mapAction.floorId,
				map: undefined,
				loading: true,
				loadingFailed: false
			};
            return {
                ...state,
				floorMaps: appendOrUpdate(state.floorMaps, idElement, (m1,m2) => areadIdEquals(m1.areaId, mapAction.floorId), (m1,m2) => {
					return {
						...m1,
						loading: true
					}
				})
            }
        case FETCH_AREAS_SUCCESS:
			const successAction = (action as FetchAreasSuccessAction);
			let newAreas = appendOrReplaceArray(state.areas, successAction.areas, (el1, el2) => areadIdEquals(el1.identifier, el2.identifier));
            return {
                ...state,
                loadingItems: false,
				loading: false,
                areas: newAreas,
				//currentRoom: newAreas.find(a => areadIdEquals(a.identifier, state.currentRoom))?.identifier,
				//currentFloor: newAreas.find(a => areadIdEquals(a.identifier, state.currentFloor))?.identifier,
				//currentLocation: newAreas.find(a => areadIdEquals(a.identifier, state.currentLocation))?.identifier
            }
        case FETCH_ROOM_STATISTIC_ZONE_SUCCESS:
            var zoneStats = (action as FetchRoomZonesStatisticSuccessAction).zoneStats;
            return {
                ...state,
				zoneStats: zoneStats,
			}
        case FETCH_ROOM_FEEDBACK_SUCCESS:
            var feedbackAction = (action as FetchRoomFeedbackSuccessAction);
            return {
                ...state,
				feedback: feedbackAction.overallFeedback,
            }
		case FETCH_MEETINGS_SUCCESS:
			var succAction = (action as FetchMeetingsSuccessAction);
			return {
				...state,
				meetings: appendOrReplaceArray(state.meetings, succAction.meetings, (m1,m2) => m1.icalUid === m2.icalUid),
				loadingMeetings: false
			}
		case FETCH_MEETINGS_ERROR: {
			return {
				...state,
				loadingMeetings: false
			}
		}
		
		case FETCH_MEETINGS:
			return {
				...state,
				loadingMeetings: true
			}
        // case FETCH_LOCATIONS_ERROR:
        // case FETCH_FLOORS_ERROR:
        case FETCH_AREAS_ERROR:
        case FETCH_ROOM_ERROR:
            return {
                ...state,
                loading: false,
                error: (action as FetchRoomErrorAction)
            }
        case FETCH_FLOORMAP_ERROR:
			const errorAction = (action as FetchFloormapErrorAction)
			//create an element signaling an error ocurred while loading.
			let errorEl: FloorMap = state.floorMaps.find(map => areadIdEquals(map.areaId, errorAction.areaId));
			errorEl = errorEl || {
				areaId: errorAction.areaId,
				loadingFailed: true,
				loading: false,
				map:undefined
			}

			return {
                ...state,
                error: (action as FetchFloormapErrorAction),
				floorMaps: appendOrReplace(state.floorMaps, errorEl, (m1,m2) => areadIdEquals(m1.areaId, m2.areaId))
                //currentFloor: { ...state.currentFloor, loadingFloormap: false }
            }
        case FETCH_ROOM_STATISTIC_ZONE_ERROR:
            return {
                ...state,
                error: (action as FetchRoomZonesStatisticErrorAction),
            }
        case FETCH_ROOM_FEEDBACK_ERROR:
            return {
                ...state,
                error: (action as FetchRoomFeedbackErrorAction),
            }
        case DISMISS_ERROR:
            return {
                ...state,
                error: null
            }
        case SET_FILTER:
            var filter = (action as FilterAction).filter;
            return { 
                ...state,
                filter: filter
            };
		case SET_FILTER_ADD:
			var str = (action as AddFilterAction).filter;
			if(state.filter.indexOf(str) > -1) {
				return state;
			}
			return { 
				...state,
				filter: state.filter.concat([str])
			};
		case SET_FILTER_DELETE:
			var str = (action as DeleteFilterAction).filter;
			const pos = state.filter.indexOf(str)
			// console.log("Filter pos ", pos)
			if(pos === -1) {
				return state;
			}
			return { 
				...state,
				filter: state.filter.filter(f => f !== str)
			};
        default:
            return state;
    }
}


export type InitializationState = Readonly<{
    loading: boolean;
    error?: ClientError;
    config?: ConfigModel;
}>;

export type StateWithInitialization = Readonly<{
    initialization: InitializationState;
}>;

const initialInitializationState: InitializationState = {
    loading: false
};

function initializationReducer(state = initialInitializationState, action: AnyInitializeAction): InitializationState {
    switch (action.type) {
        case INITIALIZE:
            return {
                ...state,
                loading: true,
                error: null
            };
        case INITIALIZE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                // config: (action as InitializeAction).config,
            };
        case INITIALIZE_ERROR:
            return {
                ...state,
                loading: false,
                error: action as ErrorAction
            };
        default:
            return state;
    }
}

export type LoginState = Readonly<{
	authenticationInProgress: boolean;
	authorizationInProgress: boolean;
	authenticated: boolean;
	authorized: boolean
	initialized: boolean;
	authType: AuthType;

	authToken: string;
	tokenExpiry: Moment;
}>

export type StateWithLogon = Readonly<{
	loginState: LoginState;
}>;

const initialLoginState: LoginState = {
	authenticated: false,
	authenticationInProgress: false,
	authorizationInProgress: false,
	authorized: false,
	tokenExpiry: undefined,
	authToken: undefined,
	authType: undefined,
	initialized: false
}

const logonReducer = (state: LoginState = initialLoginState, action: LogonAction): LoginState => {
	switch (action.type) {
		case LOGON_INIT: {
			return {
				authenticated: false,
				authenticationInProgress: false,
				authorizationInProgress: false,
				authorized: false,
				tokenExpiry: undefined,
				authToken: undefined,
				authType: undefined,
				initialized: false
			}
		}
		case LOGON_INIT_SUCCESS: {
			const logonAction = (action as InitLogonSuccessAction)
			return {
				...state,
				authType: logonAction.authType,
				initialized: true
			}
		}
		case LOGON_AUTHENTICATE:
			return {
				...state,
				authenticationInProgress: true,
				authenticated: false
			}
		case LOGON_AUTHENTICATE_SUCCESS:
			return {
				...state,
				authenticated: true,
				authenticationInProgress: false
			}
		case LOGON_AUTHENTICATE_ERROR:
			return {
				...state,
				authenticated: false,
				authenticationInProgress: false
		}
		case LOGON_AUTHORIZE:
			return {
				...state,
				authorizationInProgress: true,
				authorized: false
			}
		case LOGON_AUTHORIZE_SUCCESS:
			const successAction = action as LogonSuccessAction
			return {
				...state,
				tokenExpiry: successAction.expiry,
				authToken: successAction.authToken,
				authorizationInProgress: false,
				authorized: true
			}
		case LOGON_AUTHORIZE_ERROR:
			return {
				...state,
				authorizationInProgress: false,
				authorized: false
		}
		default: return state;
	}
}

const updateAreasById = (current: AreaState[], loaded: AreaState[]) => {
	let updated: AreaState[] = loaded;
	updated = [...updated, ...current.filter(curr => !updated.some(up => up.identifier.id == curr.identifier.id))];
	return updated;	

}

export type AppState =
    StateWithData &
    StateWithInitialization &
	StateWithNavigation &
	StateWithLogon;

type AppReducers = {
    [P in keyof AppState]: Reducer<AppState[P]>;
} & ReducersMapObject;

const appReducers: AppReducers = {
    data: dataReducer,
    navigation: navigationReducer,
	initialization: initializationReducer,
	loginState: logonReducer
};

export default combineReducers<AppState>(appReducers);
