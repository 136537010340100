import { Action } from "redux";
import { AreaIdentifier } from "../../data/areas/models";
import { Meeting } from "../../data/meeting/models";
import ErrorAction from "../../utils/ErrorAction";

export const FETCH_MEETINGS = "meeting/FETCH_MEETINGS";

export interface FetchMeetingsAction extends Action {
    type: typeof FETCH_MEETINGS;
    areaId?: AreaIdentifier;
}

export function fetchAreaMeetings(id: AreaIdentifier): FetchMeetingsAction {
    return { type: FETCH_MEETINGS, areaId: id };
}

export const FETCH_MEETINGS_SUCCESS = `${FETCH_MEETINGS}_SUCCESS`;

export interface FetchMeetingsSuccessAction extends Action {
    type: typeof FETCH_MEETINGS_SUCCESS;
    meetings: Meeting[];
}

export function fetchMeetingsSuccess(meetings: Meeting[]): FetchMeetingsSuccessAction {
	return {type: FETCH_MEETINGS_SUCCESS, meetings: meetings}
}

export const FETCH_MEETINGS_ERROR = `${FETCH_MEETINGS}_ERROR`;

export interface FetchMeetingsErrorAction extends ErrorAction {
    type: typeof FETCH_MEETINGS_ERROR;
}

export function fetchMeetingsError(e: Error, ): FetchMeetingsErrorAction {
	return {type: FETCH_MEETINGS_ERROR, error: e, message: "Could not fetch Meetings."}
}