import * as React from "react";
import { LocationModel } from "../../data/locations/models";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { StateWithData } from "../app/reducers";
import { navigate, FLOORS_ROUTE, navigateToFloors } from "../navigation/actions";
import { autobind } from "@uifabric/utilities/lib";
import { OverviewHeader } from "../overviewComponents/header";
import { ProgressIndicator } from "office-ui-fabric-react/lib/ProgressIndicator";
import * as css from "../floors/floors.module.scss";
import i18n from '../../i18n';
import * as i18nnext from 'react-i18next';
import { ListGrid } from "../basicList/list";
import { AreaIdentifier, AreaState } from "../../data/areas/models";
import { currentLocationSelector, loadingItemsSelector, loadingSelector, locationsSelector } from "../../store/selectors";

class LocationOverview extends React.Component<StateProps & DispatchProps> {

    constructor(props) {
        super(props);
    }

    @autobind
    _onHandleClick(item: AreaIdentifier) {
        this.props.onOpenItem(navigateToFloors(item))
    }

    render() {
        return (
                <div>
                    <OverviewHeader title={i18n.t("headlineCompanyHasOffices",{ count: this.props.locations.length })}></OverviewHeader>
                    <ListGrid loading={this.props.loadingItems} onOpenItem={this._onHandleClick} items={this.props.locations} />
                </div>
        )
    }
}

type StateProps = Readonly<{
    locations: AreaState[];
    loading: boolean;
    loadingItems: boolean;    
}>;

type DispatchProps = Readonly<{
    onOpenItem: typeof navigate;
}>;


function mapStateToProps(state: StateWithData): StateProps {
    return {
        locations: locationsSelector(state),
        loading: loadingSelector(state),
        loadingItems: loadingItemsSelector(state)
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
    return bindActionCreators({
        onOpenItem: navigate
    }, dispatch);
}

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(LocationOverview);
