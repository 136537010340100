import * as React from "react";
import { Area, AreaState } from "../../data/areas/models";
import { StateWithData } from "../app/reducers";
import { connect, useSelector } from "react-redux";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { loader, pivot } from "../floors/floors.module.scss";
//import RoomStatistics from "./room/statistics";
import RoomDetails from "./room/details";
import { TodayMeetingsLine } from "./room/todayMeetingsLine";
import { OverallFeedback } from "./room/overallFeedback";
import { OverviewHeader } from "../overviewComponents/header";
import { css } from "@uifabric/utilities";
import * as appCss from '../App.scss';
import i18n from "../../i18n";
import { currentRoomSelector, loadingMeetingsSelector, loadingSelector, meetingSelector, meetingsSelector } from "../../store/selectors";
import { Meeting } from "../../data/meeting/models";

function mapStateToProps(state: StateWithData): StateProps {
    return {
        room: currentRoomSelector(state),
        loadingArea: loadingSelector(state),
		meetings: meetingsSelector(state),
		loadingMeeting: loadingMeetingsSelector(state)
    };
}

export const RoomDetailView: React.FC = (props) => {
	const room = useSelector(currentRoomSelector);
	const loadingArea = useSelector(loadingSelector);
	const meetings = useSelector(meetingSelector);
	const loadingMeeting = useSelector(loadingMeetingsSelector)

	return (
			<div>
				<div className="ms-Grid" style={{ marginBottom: 10 }}>
					<OverviewHeader item={room} />
					<div className="ms-Grid-row">
						{!loadingMeeting ??
							<div className="ms-Grid-col ms-md12 ms-hiddenMdDown" >
								<TodayMeetingsLine meetings={meetings} />
							</div> }
					</div>
				</div>
				<div className={pivot}>
					<Pivot>
						<PivotItem headerText={i18n.t("tabStatusOverview")}>
							<RoomDetails />
						</PivotItem>
						<PivotItem headerText={i18n.t("tabRatingFeedback")}>
							<OverallFeedback roomState={room} />
						</PivotItem>
					</Pivot>
				</div>
			</div>
	)
}

type StateProps = Readonly<{
    room?: AreaState;
    loadingArea: boolean;
	loadingMeeting: boolean;
	meetings: Meeting[];
}>;



// export default connect<StateProps>(mapStateToProps)(RoomDetailView);