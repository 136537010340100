import createHistory from "history/createHashHistory";
import { connectRoutes } from "redux-first-router";
import { HOME_ROUTE, ADMIN_ROUTE, DASHBOARD_ROUTE, LOCATIONS_ROUTE, FLOORS_ROUTE, FLOOR_DETAIL_ROUTE, ROOM_DETAIL_ROUTE, ROOT_ROUTE, FLOORMAP_ROUTE, LOGIN_ROUTE } from "./actions";
import { locationSelector } from "./selectors";

const routesMap = {
    [ROOT_ROUTE]: "/",
    [HOME_ROUTE]: "/home",
    [DASHBOARD_ROUTE]: "/dashboard",
    [LOCATIONS_ROUTE]: "/locations",
    [FLOORS_ROUTE]: "/locations/:uri",
    [FLOOR_DETAIL_ROUTE]: "/floors/:uri",
    [FLOORMAP_ROUTE]: "/floors/:uri/floormap",
    [ROOM_DETAIL_ROUTE]: "/rooms/:uri",
	[ADMIN_ROUTE]: "/admin/home",
	[LOGIN_ROUTE]: "/code=(.*)"
};

const options = {
    location: locationSelector,
    createHistory,
    initialDispatch: false
};

export default connectRoutes(routesMap, options);