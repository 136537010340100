import { createSelector } from "reselect";
import { StateWithNavigation } from "./reducers";

export const navigationSelector = (state: StateWithNavigation) => state.navigation;

export const locationSelector = createSelector(
    navigationSelector,
    ({ location }) => location
);

export const routeTypeSelector = createSelector(
    locationSelector, r => r.type
);

export const routesMapSelector = createSelector(
    locationSelector,
    ({ routesMap }) => routesMap
);
