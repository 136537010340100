import * as React from "react";
import { connect } from "react-redux";
import { createStructuredSelector, createSelector } from "reselect";
import Root, { AppProps } from "./Root";
import { currentRouteComponentSelector, errorSelector } from "./selectors";
//import { fetchLocations } from "../../../store/actions/locationActions";
import { bindActionCreators, Dispatch } from "redux";
import { dismissError } from "../../../utils/ErrorAction";
import { initLogon, requestLogon } from "../actions";
import { LoginState  } from "../reducers";
import { logonSelector } from "../../../store/selectors";
import { fetchAreas } from "../../../store/actions/areaActions/areaActions";


type StateProps = Readonly<{
	logonState: LoginState;
    routeComponent?: React.ComponentClass;
	error?: any;
}>;

type DispatchProps = Readonly<{
    handleFetchLocations: typeof fetchAreas,
	handleDismissError: typeof dismissError,
	loginRequest : typeof requestLogon,
}>;

export type ComponentProps = StateProps & DispatchProps;

const RootContainer: React.SFC<ComponentProps> = ({ logonState, routeComponent: RouteComponent, handleFetchLocations, handleDismissError, loginRequest, error }) => {
	return <Root
        fetchLocations={handleFetchLocations}
        dismissError={handleDismissError}
		error={error}
		requestLogin={loginRequest}
		logonState={logonState}
    >
        <RouteComponent />
    </Root>
};

// function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
//     return bindActionCreators({
//         fetchLocations1: fetchLocations,
//     }, dispatch);
// }

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        handleFetchLocations: () => dispatch(fetchAreas({})),
		handleDismissError: () => dispatch(dismissError()),
		loginRequest: () => dispatch(initLogon()),
    }
};

const mapStateToProps = createStructuredSelector({
	logonState: createSelector(logonSelector, (obj) => obj),
    routeComponent: currentRouteComponentSelector,
	error: errorSelector,
});

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(RootContainer);
