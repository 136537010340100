import {
    Area, AreaIdentifier, AreaState, AreaStatisticData, OverallFeedback, ZoneCapacityData,
} from "./models";
import { ApiClient, AreaStatusDTO, AreaTypeEnum, FindAreaDTO } from "../../services/ApiClient";
import { AreaStateMapper, AreaStatisticMapper, AreaFeedbackMapper } from "./areaMapper";
import moment from "moment";
import { ApiClientOptions } from "../../services/ApiClientBase";

const client = new ApiClient();

const getArrayOrUndefined = <T, D, V extends string[]>(dtoArr: T[], callback: (dto: T, ...args: V) => D, ...args: V): D[] | undefined => {
    if (dtoArr && Array.isArray(dtoArr)) {
        const mapped = dtoArr.map(dto => callback(dto, ...args));
        return mapped;
    }

    return undefined;
}

export function getAreas(floorId?: AreaIdentifier): Promise<AreaState[]> {
    const id: string = floorId.id?.toString() || floorId.uri || floorId.upn;

    if (!!id) {
        return client.areas_GetChildrenStatus(id.toString())
            .then(res => getAreaStateArrayOrUndefined(res));
    }
    else {
        return client.areas_All()
            .then(res => getAreaStateArrayOrUndefined(res));
    }
}

export function getNestedAreas(anchorId?: AreaIdentifier): Promise<AreaState[]> {
    const id: string = anchorId.id?.toString() || anchorId.uri || anchorId.upn;
	return client.areas_GetNestedStatusFlat(id, 3)
		.then(res => getArrayOrUndefined(res, AreaStateMapper.hieriarchyToDomain))

}

export function getFlexdesks(floorId?: AreaIdentifier): Promise<AreaState[]> {
    const id: string = floorId.id?.toString() || floorId.uri || floorId.upn;
	if (!id) {
        return client.areas_ByType(AreaTypeEnum.Desk, id)
            .then(res => getAreaStateArrayOrUndefined(res));
    }
    else {
        const filter:FindAreaDTO  = { areaType: "Desk" } as FindAreaDTO;
 
        return client.areas_FindArea(filter, 50)
            .then(res => getAreaStateArrayOrUndefined(res));
    }
}

export function getArea(areaId?: AreaIdentifier): Promise<AreaState> {
    const id: string = areaId.id?.toString() || areaId.uri || areaId.upn;

    return client.areas_GetStatus(id)
        .then(res => AreaStateMapper.toDomain(res));
}

const getAreaStateArrayOrUndefined = (dto: AreaStatusDTO[]): AreaState[] | undefined => {
    return getArrayOrUndefined(dto, AreaStateMapper.toDomain);
}

/*export function getAreaMeetings(areaId: string): Promise<Area[]> {
    return Api.get("/areas/" + areaId + "/meetings")
        .then(res => {
            let entities: Area[] = res.data;
            if (entities != null && Array.isArray(entities)) {
                return entities;
            } else {
                return null;
            }
        });
}*/

export function getAreaStatistic(areaId: AreaIdentifier, date1?: Date, date2?: Date): Promise<AreaStatisticData[]> {
    const areaUri = areaId.uri;
	if (date1 && date2) {
        return client.areas_GetStatistics(areaUri, moment(date1), moment(date2))
            .then(res => AreaStatisticMapper.toDomain(res, areaUri));
    }
    else {
        return client.areas_GetStatistics(areaUri, null, null)
            .then(res => AreaStatisticMapper.toDomain(res, areaUri));
    }
}

/**
 * Placeholder, returns empty object!
 * @param AreaURI Uri of the parentArea
 */
export function getZoneStatistic(AreaURI: string): Promise<ZoneCapacityData> {
    return client.areas_GetStatistics(AreaURI, null, null)
        .then(res => ({} as ZoneCapacityData));
}

export function getAreaFeedback(areaUri: string): Promise<OverallFeedback[]> {
    return client.feedback_GetOverallFeedback(areaUri)
        .then(res => getArrayOrUndefined(res, AreaFeedbackMapper.toDomain));
}
