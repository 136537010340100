import * as React from "react";
import i18n from '../../i18n';

export class Admin extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <h2>{i18n.t("headlineAdmin")}</h2>                
                {i18n.t("contentAdmin")}
            </div>
        )
    }
}