import isString from "lodash-es/isString";
import { Action, redirect as redirectActionCreator,  NOT_FOUND} from "redux-first-router";
import { AreaIdentifier } from "../../data/areas/models";
const actionPrefix = "route/";

export const NOT_FOUND_ROUTE = NOT_FOUND;
export const ROOT_ROUTE = actionPrefix + "ROOT";
export const HOME_ROUTE = actionPrefix + "HOME";
export const ADMIN_ROUTE = actionPrefix + "ADMIN";
export const DASHBOARD_ROUTE = actionPrefix + "DASHBOARD";
export const LOCATIONS_ROUTE = actionPrefix + "LOCATIONS";
export const FLOORS_ROUTE = actionPrefix + "FLOORS";
export const FLOOR_DETAIL_ROUTE = actionPrefix + "FLOOR_DETAILS";
export const FLOORMAP_ROUTE = actionPrefix + "FLOORMAP";
export const ROOM_DETAIL_ROUTE = actionPrefix + "ROOM_DETAILS";
export const LOGIN_ROUTE = actionPrefix + "LOGIN";

export type AnyRoute = typeof ROOT_ROUTE | typeof HOME_ROUTE | typeof ADMIN_ROUTE
    | typeof DASHBOARD_ROUTE | typeof LOCATIONS_ROUTE | typeof FLOORS_ROUTE
    | typeof FLOOR_DETAIL_ROUTE | typeof FLOORMAP_ROUTE | typeof ROOM_DETAIL_ROUTE;

export interface NavigateAction<TRoute extends AnyRoute, TParams extends object> extends Action {
    type: TRoute;
    payload: TParams;
}

export function navigate<TRoute extends AnyRoute, TParams extends object>(
    route: TRoute | NavigateAction<TRoute, TParams>,
    params?: TParams,
    redirect?: boolean
): NavigateAction<TRoute, TParams> {

    const action = isString(route)
        ? { type: route, payload: params }
        : route;
        
    if (redirect) {
        return redirectActionCreator(action) as NavigateAction<TRoute, TParams>;
    }
    return action;
}

export type NavigateToFloorsAction = NavigateAction<typeof FLOORS_ROUTE, AreaIdentifier>;

export type NavigateToFloorDetailsAction = NavigateAction<typeof FLOOR_DETAIL_ROUTE, AreaIdentifier>;

export type NavigateToRoomDetailsAction = NavigateAction<typeof ROOM_DETAIL_ROUTE, AreaIdentifier>;

export type LoginRedirectAction = NavigateAction<typeof LOGIN_ROUTE, undefined>;

export const navigateToFloors = (id: AreaIdentifier) => navigate(FLOORS_ROUTE, id);

export const navigateToFloorDetails = (id: AreaIdentifier) => navigate(FLOOR_DETAIL_ROUTE, id);

export const navigateToFloormap = (id: AreaIdentifier) => navigate(FLOORMAP_ROUTE, id);

export const navigateToRoomDetails = (id: AreaIdentifier) => navigate(ROOM_DETAIL_ROUTE, id);

export const navigateToLocations = () => {
	return navigate(LOCATIONS_ROUTE);	
} 

export const loginRedirect = () => navigate(LOGIN_ROUTE)
