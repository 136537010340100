import * as React from "react";
import classNames from "classnames";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { AreaIdentifier, AreaState } from "../data/areas/models";
import { Meeting } from "../data/meeting/models";
import * as css from "../components/App.scss";
import { CONFIG } from "../../config/settings"

import { OverflowSet, IOverflowSetItemProps, IButtonStyles, IconButton } from "office-ui-fabric-react";
import { AreaTypeEnum } from "../data/listItem/model";
import i18n from "../i18n";

const serviceAccountName: string = CONFIG.adHocConfig.serviceAccountName;
const adHocMeetingTitle = CONFIG.adHocConfig.adHocMeetingTitle;

export function areadIdEquals(id1: AreaIdentifier, id2: AreaIdentifier): boolean {
	if(!id1 || !id2) {
		return false;
	}

	return (id1.id&&id2.id&&id1.id === id2.id) || (id1.uri&&id2.uri&&id1.uri.toLocaleLowerCase() === id2.uri.toLocaleLowerCase()) || (id1.upn&&id2.upn&&id1.upn === id2.upn) ;
}

export const appendOrUpdate = <T,>(base: T[], el: T, eq: (el1: T, el2: T)=>boolean, update:(el1:T, el2:T)=>T ):T[] => {
	let inside = false;
	const updated = base.map(current => {
		if (!inside && eq(current, el)) {
			inside = true;
			return update(current, el);
		}
		return current;
	})

	if(!inside) {
		updated.push(el);
	}
	return updated;
}

export const appendOrReplace = <T,>(base: T[], el: T, eq: (el1: T, el2: T)=>boolean ):T[] => {
	return appendOrUpdate(base, el, eq, (el1, el2) => el2);
}

export const appendOrReplaceArray = <T,>(base: T[], loaded: T[], eq: (el1: T, el2: T)=>boolean ):T[] => {
	const updated = base.map(current => {
		const index = loaded.findIndex(el => eq(current, el));
		if (index>-1) {
			return loaded[index];
		}
		return current;
	})

	let added:T[] = []

	loaded.forEach(el => {
		if (!updated.some(up => eq(up, el))) {
			added.push(el);
		}
	})
	
	return [...updated, ...added];
}

export function pad(num: number, size: number, leadingValue: string = "0") { var s = num + ""; while (s.length < size) s = leadingValue + s; return s; }

export function renderValueTile(iconName: string, value: string, tileClass: string, color?: string, panelItem?: boolean): JSX.Element {
    return (
        <div key={color ? iconName + color : iconName} className={classNames(tileClass, color)}>
            <div className={css.displayInlineFlex}>
                <Icon className={classNames("ms-Icon", css.listComponentIcon, css.paddingRight5)} iconName={iconName} />
                <span className={css.listComponentText} >{value}</span>
            </div>
        </div>
    )
}

export function renderMeetingTile(area: AreaState, meetings: Meeting[], style?: string, padding?: number): JSX.Element {
    let areaStartTomorrow = area.start ? new Date(area.start) : null;
    area.start ? areaStartTomorrow.setDate(areaStartTomorrow.getDate() + 1) : null;
    var nextMeetingIndex = meetings
        .sort((a: Meeting, b: Meeting) => {
            return (new Date(a.start)).getTime() - (new Date(b.start)).getTime();
        })
        .findIndex((value, index) => { return new Date(value.start) > new Date() });
    var nextMeeting = nextMeetingIndex >= 0 ? meetings[nextMeetingIndex] : null;
    var todayStart = new Date();
    todayStart.setHours(6, 0, 0, 0);
    var todayEnd = new Date();
    //todayEnd.setDate(todayStart.getDate() + 1);
    todayEnd.setHours(22, 0, 0, 0);
    if (!!area && !!area.meetingTitle && area.meetingTitle == serviceAccountName) {
        area.meetingTitle = adHocMeetingTitle;
    }


    return (
        area.type !== AreaTypeEnum.Desk &&
        <div>
            {
                !!area.start && !!area.end ?
                    <div>
                        {areaStartTomorrow.toLocaleTimeString() == new Date(area.end).toLocaleTimeString() || (area.start < todayStart && area.end > todayEnd) ?
                            <div>
                                <div className={css.colorThemePrimary}>{i18n.t("allDay")}</div>
                                <div>{area.meetingTitle}</div>
                            </div>
                            :
                            <div>
                                <div className={classNames(css.colorThemePrimary, css.panelHeadline)}>{i18n.t("currentMeeting")}:</div>
                                <div className={classNames(css.colorThemePrimary, css.panelHeadline)}>{new Date(area.start).toLocaleTimeString()} {new Date(area.end).toLocaleTimeString()}</div>
                                <div className={css.panelHeadline}>{area.meetingTitle}</div>
                            </div>
                        }
                    </div>
                    : nextMeeting ?
                        <div className={classNames("ms-fontWeight-semibold", css.colorGreen, css.panelHeadline, css.smallFontSize)} style={{ paddingBottom: padding }}>
                            <div>{i18n.t("roomcommonAvailableUntil")} {(new Date(nextMeeting.start)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                        </div>
                        : area.peopleCount > 0 ?
                            <div className={classNames("ms-fontWeight-semibold", css.colorRed, css.panelHeadline, css.smallFontSize)}>
                                <div>{i18n.t("roomcommonInUseBy", { whom: area.peopleCount + " " + (area.peopleCount > 1 ? i18n.t("persons") : i18n.t("person")) })}.</div>
                            </div>
                            :
                            <div className={classNames("ms-fontWeight-semibold", css.colorGreen, css.panelHeadline, css.smallFontSize)} style={{ paddingBottom: padding }}>
                                <div>{i18n.t("roomcommonAvailable")}</div>
                            </div>
            }
        </div>
    )

}

export function renderValues(area: AreaState, style?: string, panelItem?: boolean) {
    let tileClass: string;
    style ? tileClass = style : undefined;
    return (
        <>
            <div className={classNames("ms-Grid")}>
                <div className="ms-Grid-row">
                    {(area?.peopleCount > 0 || area?.type === AreaTypeEnum.ProjectSpace || area?.type === AreaTypeEnum.MeetingRoom) &&
                        <div className="ms-Grid-col ms-sm4">
                            {!!area.peopleCount != null && renderValueTile("People", Math.round(area.peopleCount).toString(), tileClass, undefined, panelItem)}
                        </div>
                    }

                    {!!area.hasProjector &&
                        <div className="ms-Grid-col ms-sm4">
                            {!!area.hasProjector && renderValueTile("Devices3", Math.round(area.hasProjector).toString(), tileClass, undefined, panelItem)}
                        </div>
					}
                    {!!area.temperature &&
                        <div className="ms-Grid-col ms-sm4">
                            {!!area.temperature && renderValueTile("Frigid", Math.round(area.temperature) + "°C", tileClass, undefined, panelItem)}
                        </div>
                    }
                </div>
                <div className={classNames("ms-Grid-row", css.bgColorNeutralLighter)}>
                    {!!area.humidity &&
                        <div className={classNames("ms-Grid-col ms-sm4", css.noPaddingRight)}>
                            {!!area.humidity && renderValueTile("Drop", Math.round(area.humidity) + "%", tileClass, undefined, panelItem)}
                        </div>
                    }
                    {!!area.brightness &&
                        <div className={classNames("ms-Grid-col ms-sm5", css.noPaddingRight)}>
                            {!!area.brightness && renderValueTile("Sunny", area.brightness + "L", tileClass, undefined, panelItem)}
                        </div>
                    }
                </div>
            </div>


            {/* <div className={css.displayFlex}> */}
            {/* {!!area.capacity && renderValueTile("People", "" + Math.round(area.capacity), tileClass, undefined, panelItem)} */}
            {/* {area.peopleCount != null && renderValueTile("People", Math.round(area.peopleCount).toString(), tileClass, undefined, panelItem)}
                {!!area.hasProjector && renderValueTile("Devices3", Math.round(area.hasProjector).toString(), tileClass, undefined, panelItem)}
                {!!area.temperature && renderValueTile("Frigid", Math.round(area.temperature) + "°C", tileClass, undefined, panelItem)}
            </div>
            <div className={css.displayFlex}>
                {!!area.humidity && renderValueTile("Drop", Math.round(area.humidity) + " %", tileClass, undefined, panelItem)}
                {!!area.brightness && renderValueTile("Sunny", area.brightness + " L", tileClass, undefined, panelItem)}
            </div> */}
        </>
    )
}


export function renderUpcomingMeetings(meetings: Meeting[], panel?: Boolean) {
    const upcomingMeetings = meetings.filter(m => new Date(m.start) > new Date());
    let panelStyle = panel ? css.panelHeadline : null;
    return (
        <div className={classNames("ms-Grid-col ms-sm12", css.bgColorNeutralLighter, css.marginTopBottom8)}>
            <h3 style={{ marginBottom: "5px", marginTop: 5 }}>{i18n.t("commonUpcomingMeetingsToday")}</h3>
            {upcomingMeetings.length > 0 ?
                <div>
                    <div className={classNames("ms-Grid-col ms-sm12", css.marginTop10)}>
                        <h3>{i18n.t("commonNextMeeting")}</h3>
                    </div>
                    {upcomingMeetings.map((element, i) =>
                        <>
                            {i > 0 ? <hr style={{ border: "0.5px solid grey" }}></hr> : null}
                            <h3 className={classNames(`ms-Grid-col ms-sm12`, css.colorThemePrimary, panelStyle)}>
                                {new Date(element.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(element.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </h3>
                            <div className={classNames("ms-Grid-col ms-sm12", css.bgColorNeutralLighter, css.paddingTop)}>
                                <div className={classNames("ms-Grid-row ms-sm12", css.marginLeftRight0)}>
                                    <div className={classNames(css.displayInlineFlex)}>
                                        <Icon className={classNames("ms-Icon", css.listComponentIcon)} iconName={"Contact"} />
                                        <div className={css.floorTileIconText}>
                                            <span className={classNames(css.listComponentText, css.paddingLeft)} >{element.title}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNames("ms-Grid-row ms-sm12", css.marginLeftRight0, css.paddingBottom5)}>
                                    <div className={css.displayInlineFlex}>
                                        <Icon className={classNames("ms-Icon", css.listComponentIcon)} iconName={"Mail"} />
                                        <div className={css.floorTileIconText}>
                                            <span className={classNames(css.listComponentText, css.paddingLeft)} >{element.organizerEmail}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    }
                </div>
                :
                <span className={classNames(css.listComponentText, panelStyle)}>{i18n.t("commonNoMoreMeetingsToday")}</span>
            }
        </div >
    )
}
export function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};
