import { all, put, takeLatest, call } from "redux-saga/effects";
import { fetchAreas, fetchRoom } from "../../store/actions/areaActions/areaActions";
import locationsSaga from "../../store/sagas/locationSaga";
import { LOCATIONS_ROUTE, FLOORS_ROUTE, FLOOR_DETAIL_ROUTE, NavigateToFloorsAction, NavigateToFloorDetailsAction, NavigateToRoomDetailsAction, ROOM_DETAIL_ROUTE, ROOT_ROUTE, FLOORMAP_ROUTE } from "../navigation/actions";
import { INITIALIZE, InitializeAction, initializeSuccess, LogonAction, LOGON_REQUEST, logonSuccess, logonError } from "./actions";
import floorsSaga from "../../store/sagas/floorSaga";
import areasSaga from "../../store/sagas/areaSaga";
import {handleFetchAreas} from "../../store/sagas/areaSaga";

import routes from "../navigation/routes";
import { authenticationSaga } from "../../store/sagas/authenticationSaga";
import { fetchAreaMeetings } from "../../store/actions/meetingActions";
import meetingSaga from "../../store/sagas/meetingSaga";
import { fetchFloormap } from "../../store/actions/floorActions";

function* initialize({ onFinished }: InitializeAction) {
	var config = undefined;
	
    yield put(initializeSuccess(config));
    routes.initialDispatch();
    if (onFinished) {
        onFinished();
    }
}


function* handleLocations() {
    yield put(fetchAreas({}));
}

function* handleFloors(act: NavigateToFloorsAction) {
    yield all([put(fetchAreas(act.payload))])

}

function* handleFloorDetails(act: NavigateToFloorDetailsAction) {
    yield all([
		put(fetchAreas(act.payload)),
		put(fetchAreaMeetings(act.payload)),
		put(fetchFloormap(act.payload))
	]);
}

function* handleRoomDetails(act: NavigateToRoomDetailsAction) {
    yield all([
		put(fetchAreas(act.payload)), 
		put(fetchAreaMeetings(act.payload))
	]);
}

function* routesSaga() {
    yield all([
        takeLatest(ROOT_ROUTE, handleLocations),
        takeLatest(LOCATIONS_ROUTE, handleLocations),
        takeLatest(FLOORS_ROUTE, handleFloors),
        takeLatest(FLOOR_DETAIL_ROUTE, handleFloorDetails),
        // takeLatest(FLOORMAP_ROUTE, handleFloorDetails),
        takeLatest(ROOM_DETAIL_ROUTE, handleRoomDetails),
    ]);
}

export default function* appSaga(): IterableIterator<any> {
    yield all([
		authenticationSaga(),
        locationsSaga(),
        floorsSaga(),
        areasSaga(),
        routesSaga(),
		meetingSaga(),
		takeLatest(INITIALIZE, initialize),
    ]);
}