import { Action } from "redux";
import ErrorAction from "../../utils/ErrorAction";
import { ConfigModel } from "../../config/ConfigModel";
import { AuthType } from "../../services/ApiClientAuth";
import {Moment} from "moment"
// initialization

type PayloadAction<T> = Action & T;

export const INITIALIZE = "app/INITIALIZE";

export interface InitializeAction extends Action {
    // type: typeof INITIALIZE;
    referrerUrl?: string;
    routePath?: string;
    config?: ConfigModel;
    onFinished?: () => void;
}

export const initialize = (routePath?: string, referrerUrl?: string, onFinished?: () => void): InitializeAction =>
    ({ type: INITIALIZE, onFinished, routePath, referrerUrl });

export const INITIALIZE_SUCCESS = `${INITIALIZE}_SUCCESS`;
export const initializeSuccess = (config: ConfigModel): InitializeAction =>
    ({ type: INITIALIZE_SUCCESS, config });

export const INITIALIZE_ERROR = `${INITIALIZE}_ERROR`;
export const initializeError = (error: Error): ErrorAction =>
    ({ type: INITIALIZE_ERROR, error, message: "Error while initialize app" });

export type AnyInitializeAction = InitializeAction | ErrorAction | Action;

export const LOGON = "LOGON";
export type LogonAction = ErrorAction | Action;

export const LOGON_INIT = `${LOGON}_INIT`;
export const initLogon = () : LogonAction => 
	({type: LOGON_INIT});

export type InitLogonSuccessAction = PayloadAction<{authType:AuthType}>;
export const LOGON_INIT_SUCCESS = `${LOGON_INIT}_SUCCESS`;
export const initLogonSuccess = (authType: AuthType) : InitLogonSuccessAction => 
	({type: LOGON_INIT_SUCCESS, authType: authType});

export const LOGON_AUTHENTICATE = `${LOGON}_AUTHENTICATE`
export const requestAuthentication = () : Action => 
	({type: LOGON_AUTHENTICATE});

export const LOGON_AUTHENTICATE_SUCCESS = `${LOGON_AUTHENTICATE}_SUCCESS`
export const authenticationSuccess = () : Action => 
	({type: LOGON_AUTHENTICATE_SUCCESS});

export const LOGON_AUTHENTICATE_ERROR = `${LOGON_AUTHENTICATE}_ERROR`
export const authenticationError = () : Action => 
	({type: LOGON_AUTHENTICATE_ERROR});

export const LOGON_AUTHENTICATE_REDIRECT = `${LOGON_AUTHENTICATE}_REDIRECT`
export const requestAuthenticationRedirect = () : Action => 
	({type: LOGON_AUTHENTICATE_REDIRECT});

export const LOGON_AUTHORIZE = `${LOGON}_AUTHORIZE`
export const requestAuthorization = () : Action => 
	({type: LOGON_AUTHORIZE});

export const LOGON_AUTHORIZE_SUCCESS = `${LOGON_AUTHORIZE}_SUCCESS`
export const authorizationSuccess = (authToken: string, expiry: Moment): LogonSuccessAction => 
	({type: LOGON_AUTHORIZE_SUCCESS, authToken: authToken, expiry: expiry});

export const LOGON_AUTHORIZE_ERROR = `${LOGON_AUTHORIZE}_ERROR`
export const authorizationError = () : Action => 
	({type: LOGON_AUTHORIZE_ERROR});

export const LOGON_AUTHORIZE_REDIRECT = `${LOGON_AUTHORIZE}_REDIRECT`
export const authorizationRequestRedirect = () : Action => 
	({type: LOGON_AUTHORIZE_REDIRECT});

export const LOGON_REQUEST = `${LOGON}_REQUEST`
export const requestLogon = () : LogonAction => 
	({type: LOGON_REQUEST});

export type LogonSuccessAction = PayloadAction<{authToken: string, expiry: Moment}>; 
export const LOGON_SUCCESS = `${LOGON}_SUCCESS`;
export const logonSuccess = (authToken: string, expiry: Moment): LogonSuccessAction => 
	({type: LOGON_SUCCESS, authToken: authToken, expiry: expiry});

export const LOGON_ERROR = `${LOGON}_ERROR`;
export const logonError = (error: Error): ErrorAction => 
	({type: LOGON_ERROR, error, message: "Error while logging in"});

export type AnyLogonAction = LogonAction | InitLogonSuccessAction | LogonSuccessAction