import * as React from "react";
import { Area, AreaFilter, AreaIdentifier, AreaState } from "../../data/areas/models";
import { autobind } from "@uifabric/utilities/lib";
import { Floor, FloorMap } from "../../data/floors/models";
import { navigate, navigateToRoomDetails } from "../navigation/actions";
import { StateWithData } from "../app/reducers";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import * as css from "../floors/floors.module.scss";
import * as cssGlobal from '../App.scss';
import { OverviewHeader } from "../overviewComponents/header";
import { ActionButton } from 'office-ui-fabric-react/lib/Button';
import * as common from "../../utils/commonFunctions";
import { fetchFloormap, fetchAreaStatistic, FETCH_FLOORS } from "../../store/actions/floorActions";
import { filteredRoomSelector, roomsSelector, filterSelector, currentFloorSelector, flexdeskSelector, loadingSelector, loadingItemsSelector, floorMapsSelector, floorMapSelector, filterOnlyAvailableSelector, filterHasProjectorSelector, filterNoMeetingroomsSelector, filterNoProjectSpacesSelector } from "../../store/selectors";
import { Toggle, Checkbox } from "office-ui-fabric-react";
import { setFilter, fetchFlexDesks, fetchAreas, deleteFilter, addFilter } from "../../store/actions/areaActions/areaActions";
import { ListGrid } from "../basicList/list";
import i18n from '../../i18n';
import { AreaTypeEnum } from "../../data/listItem/model";
import { getAreas, getArea, getAreaStatistic, getAreaFeedback, getFlexdesks, getZoneStatistic } from "../../data/areas/areas.api";
import FloormapWrapper from "../floors/floormapWrapper";
import {AreaFilterControl} from "./areaFilter"

export const AreaOverview: React.FC<{}> = (props) => {
	const floor = useSelector(currentFloorSelector);
	const rooms = useSelector(filteredRoomSelector);
	const loading = useSelector(loadingSelector);
	const floormap = useSelector(floorMapSelector);

	const [showPanel, setShowPanel] = React.useState(false);
	const [showFilters, setShowFilters] = React.useState(false);
	const [panelItem, setPanelItem] = React.useState<AreaState>(undefined);
	const [selectedKey, setSelectedKey] = React.useState(new URL(window.location.href).hash.includes("floormap") ? "floorMap" : "rooms");
	
	const dispatch = useDispatch();

    const _onHandleRoomClick = React.useCallback((item: AreaIdentifier) => {
        dispatch(navigateToRoomDetails(item));
		setSelectedKey("floorMap")
    }, [dispatch]);

    
    const _onHandleDeskClick = React.useCallback((item: AreaState) => {
        setShowPanel(true);
		setPanelItem(item);
		setSelectedKey("floorMap");
    },[]);

    const _setShowPanel = React.useCallback((showPanel: boolean): (() => void) => {
        return (): void => {
			setShowPanel(showPanel);
        };
    }, []);

    const screenLoading = React.useCallback(() => {
        if (!common.isMobileDevice()) {
            dispatch(fetchFloormap(floor.identifier));
        }
    }, [dispatch, floor]);


    const onPivotClick = React.useCallback((item: PivotItem): void => {
		if(item.props.itemKey != "roomFilter") {
			setSelectedKey(item.props.itemKey);

		}
		if (item.props.itemKey == "floorMap") {
            dispatch(fetchFloormap(floor.identifier));
        }
        else if (item.props.itemKey == "roomFilter") {
            setShowFilters((panel) => !panel)
        }

    }, [dispatch, floor]);

        return (
            loading && rooms?.length <=1 ?
                <div className={css.loader}>
                    <ProgressIndicator description={i18n.t("loadingAreas")} className="ms-textAlignCenter" />
                </div> :
                <div onLoad={screenLoading}>
                    <div className={`ms-Grid ${css.marginTopBottom}`}>
                        <OverviewHeader item={floor} />
                    </div>
                    <div className={css.pivot} id={"pivotHeader"}>
                        <Pivot onLinkClick={onPivotClick} selectedKey={selectedKey} >
                            <PivotItem headerText={i18n.t("rooms")} itemKey="rooms" itemCount={loading ? 0 : rooms.length}>
                                {showFilters && <AreaFilterControl/>}
                                <ListGrid loading={loading} onOpenItem={_onHandleRoomClick} items={rooms} />
                            </PivotItem>
                            <PivotItem itemIcon='FilterSettings' itemKey="roomFilter" >

                            </PivotItem>
                            <PivotItem headerText={i18n.t("floormap")} itemKey="floorMap">
								{showFilters && <AreaFilterControl/>}
								{!floormap?.map && floormap?.loading ?
                                    <div className={css.loader}>
                                        <ProgressIndicator description={i18n.t("loadingFloormap")} className="ms-textAlignCenter" />
                                    </div> 
									:
									<FloormapWrapper/>
                                }
                            </PivotItem>
                        </Pivot>
                    </div>
                    <Panel isOpen={showPanel} isLightDismiss={true} onDismiss={_setShowPanel(false)} headerText={panelItem && panelItem.title}  type={PanelType.customNear} className={css.panelSize}>
                        {panelItem && <div>{!!panelItem.description && panelItem.description}</div>}
                        {panelItem &&
                            <span className="ms-Grid">
                                {!!panelItem.meetingStatus && <h2 className="ms-Grid-row" style={{ marginBottom: "20px" }}>{panelItem.meetingStatus}</h2>}
                                {common.renderValues(panelItem, "ms-Grid-row", true)}
                            </span>
                        }
                        {panelItem &&
                            <div>
                                {(panelItem.type == AreaTypeEnum.MeetingRoom || panelItem.type == AreaTypeEnum.ProjectSpace) &&
                                    <div>
                                        <div>
                                            <ActionButton
                                                className={cssGlobal.overviewComponent}
                                                iconProps={{ iconName: 'Info' }}
                                                href={"#/rooms/" + panelItem.identifier.uri}
                                            >
                                                {i18n.t("moreDetails")}
                                            </ActionButton>
                                        </div>
                                        <div>
                                            {/* {!!meetings && common.renderUpcomingMeetings(panelItem, true)} */}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </Panel>
                </div>
        )
}