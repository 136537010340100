import * as React from "react";
import { ListItem, AreaTypeEnum } from "../../data/listItem/model"
import { Icon } from "office-ui-fabric-react";
import { AreaState, Area } from "../../data/areas/models";
import { renderRoomInfo } from "../list/ListFieldItem";
import * as css from "./basicList.module.scss";
import { MeetingIcon, ProjectSpaceIcon, FlexIcon, ReservationIcon } from "../Icons";
import i18n from "../../i18n";
import { selectMeetingByAreaId } from "../../store/selectors";
import {useSelector}  from "react-redux"

export type ListFieldItemProps = {
    item: ListItem | AreaState;
};

export const TileRow: React.FC<ListFieldItemProps> = ({item}) => {
	const meetings = useSelector(state => selectMeetingByAreaId(state, item.identifier.id))

	return (
		<>
			<div className={css.listGridRowContainer}>
				 {!!meetings && (item.type === AreaTypeEnum.MeetingRoom || item.type === AreaTypeEnum.ProjectSpace ) ?
					<div className={css.listGridRoomContainer}>
						{renderRoomInfo(item as AreaState, meetings)}
					</div>
					:
					!!item.totalMeetingRooms &&
					<div className={css.listGridRow}>
						<div>
							<MeetingIcon className={css.listGridIcon} />
							<div className={css.listGridRowText}>
								<span>
									<span className={css.numavailable}>{item.availableMeetingRooms}</span> | {item.totalMeetingRooms} 
									<span className={css.listGridSpanText}>&nbsp;{i18n.t("meetingrooms")}</span>
								</span>
							</div>
						</div>
					</div>
				}
				{(item?.totalProjectSpaces > 0 || item?.type === AreaTypeEnum.Location || item?.type === AreaTypeEnum.Level) && item?.type !== AreaTypeEnum.ProjectSpace &&
					<div className={css.listGridRow}>
						<div>
							<ProjectSpaceIcon className={css.listGridIcon} />
							<div className={css.listGridRowText}>
								<span>
									<span className={css.numavailable}>{item.availableProjectSpaces}</span> | {item.totalProjectSpaces} 
									<span className={css.listGridSpanText}>&nbsp;{i18n.t("projectSpaces")}</span>
								</span>
							</div>
						</div>
					</div>
				}
				{(item?.totalFlexdesks > 0 || item?.type === AreaTypeEnum.Location || item?.type === AreaTypeEnum.Level) &&
					<div className={css.listGridRow}>
						<div>
							<FlexIcon className={css.listGridIcon} />
							<div className={css.listGridRowText}>
								<span>
									<span className={css.numavailable}>{item.availableFlexdesks}</span> | {item.totalFlexdesks}
									<span className={css.listGridSpanText}>&nbsp;{i18n.t("flexdesks")}</span>
								</span>
							</div>
						</div>
					</div>
				}
				{!!item.totalReservatedCapacity && item?.type === AreaTypeEnum.Location &&
					<div className={css.listGridRow}>
						<div>
							<ReservationIcon className={css.listGridIcon} />
							<div className={css.listGridRowText}>
								<span>
									<span className={css.numavailable}>{item.availableReservatedCapacity}</span> | {item.totalReservatedCapacity}
									<span className={css.listGridSpanText}>&nbsp;{i18n.t("reservations")}</span>
								</span>
							</div>
						</div>
					</div>
				}
			</div>
		</>
	)
}

// export class TileRow extends React.Component<ListFieldItemProps> {
//     constructor(props) {
//         super(props);
//     }

//     render() {
//         const { item } = this.props;
//         return (
//             <>
//                 <div className={css.listGridRowContainer}>
//                      {!!(item as AreaState).todayMeetings ?
//                         <div className={css.listGridRoomContainer}>
//                             {renderRoomInfo(item as AreaState)}
//                         </div>
//                         :
//                         !!item.totalMeetingRooms &&
//                         <div className={css.listGridRow}>
//                             <div>
//                                 <MeetingIcon className={css.listGridIcon} />
//                                 <div className={css.listGridRowText}>
//                                     <span>
//                                         <span className={css.numavailable}>{item.availableMeetingRooms}</span> | {item.totalMeetingRooms} 
//                                         <span className={css.listGridSpanText}>&nbsp;{i18n.t("meetingrooms")}</span>
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     }
//                     {!!item.totalProjectSpaces &&
//                         <div className={css.listGridRow}>
//                             <div>
//                                 <ProjectSpaceIcon className={css.listGridIcon} />
//                                 <div className={css.listGridRowText}>
//                                     <span>
//                                         <span className={css.numavailable}>{item.availableProjectSpaces}</span> | {item.totalProjectSpaces} 
//                                         <span className={css.listGridSpanText}>&nbsp;{i18n.t("projectSpaces")}</span>
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     }
//                     {!!item.totalFlexdesks &&
//                         <div className={css.listGridRow}>
//                             <div>
//                                 <FlexIcon className={css.listGridIcon} />
//                                 <div className={css.listGridRowText}>
//                                     <span>
//                                         <span className={css.numavailable}>{item.availableFlexdesks}</span> | {item.totalFlexdesks}
//                                         <span className={css.listGridSpanText}>&nbsp;{i18n.t("flexdesks")}</span>
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     }
//                     {!!item.totalCoronaCapacity && (""+item.type).toLowerCase() == "location" &&
//                         <div className={css.listGridRow}>
//                             <div>
//                                 <CoronaIcon className={css.listGridIcon} />
//                                 <div className={css.listGridRowText}>
//                                     <span>
//                                         <span className={css.numavailable}>{item.availableCoronaCapacity}</span> | {item.totalCoronaCapacity}
//                                         <span className={css.listGridSpanText}>&nbsp;{i18n.t("reservations")}</span>
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     }
//                 </div>
//             </>
//         )
//     }
//}
