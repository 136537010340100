import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import {
    fetchAreasSuccess, fetchAreasError, FETCH_AREAS, FetchAreasAction,
    FetchRoomAction, fetchRoomSuccess, fetchRoomError, FETCH_ROOM,
    fetchRoomFeedback, fetchRoomFeedbackSuccess, fetchRoomFeedbackError, FETCH_ROOM_FEEDBACK, fetchFlexDesksSuccess, fetchFlexDesksError, FETCH_FLEXDESKS
} from "../actions/areaActions/areaActions";
import {
    FETCH_ROOM_STATISTIC, fetchRoomZonesStatisticSuccess, fetchRoomZonesStatisticError, FETCH_ROOM_STATISTIC_ZONE, fetchRoomZoneStatistic, FETCH_ROOM_STATISTIC_RANGE
} from "../actions/areaActions/statisticActions";
import { getAreas, getArea, getAreaStatistic, getAreaFeedback, getFlexdesks, getZoneStatistic, getNestedAreas } from "../../data/areas/areas.api";
import { fetchAreaStatistic, fetchAreaStatisticError, fetchAreaStatisticSuccess, fetchFloormap } from "../actions/floorActions";
import { AreaState } from "../../data/areas/models";
import { ensureAuthenticationSaga } from "./authenticationSaga";
import { ROOT_ROUTE } from "../../components/navigation/actions";

export function* handleFetchAreas({ anchorIdentifier }: FetchAreasAction) {
    try {
		yield ensureAuthenticationSaga();
        const areas = yield call(getNestedAreas, anchorIdentifier);
        yield put(fetchAreasSuccess(areas));
    } catch (e) {
		console.log(e)
        yield put(fetchAreasError(e));
    }
}

function* handleFetchDesks({ anchorIdentifier }: FetchAreasAction) {
    try {
		yield ensureAuthenticationSaga();
        const areas = yield call(getFlexdesks, anchorIdentifier);

        yield put(fetchFlexDesksSuccess(areas));
    } catch (e) {
        yield put(fetchFlexDesksError(e));
    }
}

function* handleFetchRoom({ roomId }: FetchRoomAction) {
    try {
		yield ensureAuthenticationSaga();
        const area: AreaState = yield call(getArea, roomId);

        //yield put(fetchFloor(area.floorId, true));
        //yield put(fetchAreaStatistic(roomId));
        //yield put(fetchRoomZoneStatistic(roomId));
        yield put(fetchRoomFeedback(roomId));

        yield put(fetchRoomSuccess(area));
    } catch (e) {
        yield put(fetchRoomError(e));
    }
}

function* handleFetchRoomStatistic({ roomId }: FetchRoomAction) {
    try {
		yield ensureAuthenticationSaga();
        const stats = yield call(getAreaStatistic, roomId);

        yield put(fetchAreaStatisticSuccess(stats, roomId));
    } catch (e) {
        yield put(fetchAreaStatisticError(e));
    }
}

function* handleFetchRoomRangeStatistic({ roomId, date1, date2 }: FetchRoomAction) {
    try {
		yield ensureAuthenticationSaga();
        const stats = yield call(getAreaStatistic, roomId, date1, date2);

        yield put(fetchAreaStatisticSuccess(stats, roomId));
    } catch (e) {
        yield put(fetchAreaStatisticError(e));
    }
}

function* handleFetchRoomZoneStatistic({ roomId }: FetchRoomAction) {
    try {
		yield ensureAuthenticationSaga();
        const zoneStats = yield call(getZoneStatistic, roomId.uri);

        yield put(fetchRoomZonesStatisticSuccess(zoneStats, roomId.uri));
    } catch (e) {
        yield put(fetchRoomZonesStatisticError(e));
    }
}

function* handleFetchRoomFeedback({ roomId }: FetchRoomAction) {
    try {
		yield ensureAuthenticationSaga();
        const stats = yield call(getAreaFeedback, roomId.uri);

        yield put(fetchRoomFeedbackSuccess(stats, roomId.uri));
    } catch (e) {
        yield put(fetchRoomFeedbackError(e));
    }
}

export default function* areasSaga(): IterableIterator<Effect> {
    yield all([
        takeLatest(FETCH_AREAS, handleFetchAreas),
        takeLatest(FETCH_FLEXDESKS, handleFetchDesks),
        takeLatest(FETCH_ROOM, handleFetchRoom),
        takeLatest(FETCH_ROOM_STATISTIC, handleFetchRoomStatistic),
        takeLatest(FETCH_ROOM_STATISTIC_RANGE, handleFetchRoomRangeStatistic),
        takeLatest(FETCH_ROOM_STATISTIC_ZONE, handleFetchRoomZoneStatistic),
        takeLatest(FETCH_ROOM_FEEDBACK, handleFetchRoomFeedback)
    ]);
}
